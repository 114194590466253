export default function airfieldCheck(props) {
  let PODvalid = true;
  let POAvalid = true;

  if (
    props.poaTimeZone === '' ||
    props.poaTimeZone === null ||
    props.poaTimeZone === undefined
  ) {
    POAvalid = false;
  }
  if (
    props.podTimeZone === '' ||
    props.podTimeZone === null ||
    props.podTimeZone === undefined
  ) {
    PODvalid = false;
  }

  return [PODvalid, POAvalid];
}
