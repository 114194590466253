import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import useAircraftLineup from '../Utils/Hooks/useAircraftLineup';
import MissionNotesUpdateTop from './MissionNotesUpdateTop';

export function Header({ data }) {
  const [bunos, setbunos] = useState([]);
  const [mod, setmod] = useState(
    data.location.state.missionDataLink?.modNumber
  );
  const [updateNumber, setupdateNumber] = useState(
    data.location.state.missionDataLink?.updateNumber
  );
  const [buno, setbuno] = useState(data.location.state.missionDataLink?.buno);
  const { data: aircraftLineup, isFetching } = useAircraftLineup();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ typeChange, newData, data }) => {
      await updateInfo({ typeChange, newData, data });
    },
    {
      onMutate: async ({ typeChange, newData, data }) => {
        //console.log(name, pprInfo, event.target.value);
        const oldData = data;
        await queryClient.cancelQueries(['missionDataPartial']);
        const previousPPRdata = queryClient.getQueryData([
          'missionDataPartial',
        ]);

        /*         if (name === 'pprRequired') {
          queryClient.setQueryData(
            ['PPRdata'],
            produce(previousPPRdata, (draft) => {
              const index = draft.findIndex(
                (PPR) => PPR.pprID === pprInfo.pprID
              );
              if (index !== -1) draft[index].pprRequired = event.target.value;
            })
          );
        } */

        return [previousPPRdata, typeChange, newData, oldData];
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) => {
        alert(
          'Error saving -- Reverting to old data.  Contact administrator if issues persist'
        );
        queryClient.setQueryData(['missionDataPartial'], previousValue[0]);
        setupdateNumber(
          previousValue[3].location.state.missionDataLink?.updateNumber
        );
        setbuno(previousValue[3].location.state.missionDataLink?.buno);
        setmod(previousValue[3].location.state.missionDataLink?.modNumber);
      },
      // After success or failure, refetch the todos query
      onSuccess: () => {
        queryClient.invalidateQueries(['missionDataPartial']);
      },
    }
  );

  const cs = data.location.state.missionDataLink.cnv.split('-');
  const mods = [0, 1, 2, 3, 4, 5, 6, 7];
  const headerStyle = {
    textAlign: 'center',
  };

  useEffect(() => {
    if (!isFetching) {
      let bunos = aircraftLineup.filter(
        (aircraft) =>
          aircraft.type === data.location.state.missionDataLink.typeAircraft
      );
      setbunos(bunos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aircraftLineup]);

  return (
    <>
      <h1 style={headerStyle}>CNV {cs[0]}</h1>
      <h3 style={headerStyle}>
        Modification {mod}, Update {updateNumber}
      </h3>
      <div style={{ textAlign: 'center' }}>
        <Grid
          container
          direction='row'
          spacing={2}
          alignItems='center'
          justify='center'>
          <Grid item>
            <FormControl variant='outlined'>
              <InputLabel id='bunoChange'>BuNo</InputLabel>
              <Select
                labelId='bunoChange'
                label='BuNo'
                id='aircraftType'
                value={buno}
                onChange={(e) => {
                  e.preventDefault();
                  const typeChange = 'buno';
                  setbuno(e.target.value);
                  mutation.mutate({
                    typeChange,
                    newData: e.target.value,
                    data,
                  });
                }}>
                {bunos.map((side) => {
                  return (
                    <MenuItem value={side.buno} key={'bunos' + side.buno}>
                      {side.buno}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant='outlined'>
              <InputLabel id='modChange'>Mod</InputLabel>
              <Select
                labelId='modChange'
                label='Mod'
                id='modChangeSelect'
                value={mod}
                onChange={(e) => {
                  e.preventDefault();
                  const typeChange = 'modNumber';
                  setmod(e.target.value);
                  mutation.mutate({
                    typeChange,
                    newData: e.target.value,
                    data,
                  });
                }}>
                {mods.map((mod) => {
                  return (
                    <MenuItem value={mod} key={'mod' + mod}>
                      {mod}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl variant='outlined'>
              <InputLabel id='updateChange'>Update</InputLabel>
              <Select
                labelId='updateChange'
                label='Update'
                id='updateChangeSelect'
                value={updateNumber}
                onChange={(e) => {
                  e.preventDefault();
                  const typeChange = 'updateNumber';
                  setupdateNumber(e.target.value);
                  mutation.mutate({
                    typeChange,
                    newData: e.target.value,
                    data,
                  });
                }}>
                {mods.map((mod) => {
                  return (
                    <MenuItem value={mod} key={'mod' + mod}>
                      {mod}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {/*           {localStorage.getItem('typeUser') === 'admin' ||
          localStorage.getItem('typeUser') === 'dh' ? (
            <>test</>
          ) : null} */}
        </Grid>
      </div>
      <MissionNotesUpdateTop data={data.location.state.missionDataLink} />
    </>
  );
}

const updateInfo = async ({ typeChange, newData, data }) => {
  //send new buno to db with id
  const id = data.location.state.missionDataLink.missionID;
  const oldCNV = data.location.state.missionDataLink.cnv.split('-');
  const newCNV = oldCNV[0] + '-' + oldCNV[1] + '-' + newData;
  const resp = await fetch('/db/UMPDzxcvklhjlkhjh.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify({
      typeChange: typeChange,
      newData: newData,
      missionID: id,
      newCNV: newCNV,
    }),
  });
  if (!resp.ok) {
    throw new Error('Network response was not ok');
  }
  return resp.text();
};
