import { Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

export default function AirliftWrapper({ airlift, children }) {
  return (
    <React.Fragment key={'LTT'}>
      <LightTooltip
        key={'lightTooltip'}
        placement='left'
        title={
          <React.Fragment>
            <Typography
              variant='h5'
              color='initial'
              style={{ display: 'inline-block' }}>
              {airlift.POD}-{airlift.POA}
              {airlift.organization !== '' ? <br /> : ''}
              {airlift.organization !== '' ? `${airlift.organization}` : ''}
              {<br />}
              {airlift.PAX} / {airlift.cargoWeight}
              {airlift.HAZ === '1' ? ' HAZ' : ''}
              {airlift.DV === '1' ? <br /> : ''}
              {airlift.DV === '1' ? 'DV' : ''}
              {<br />}
              {airlift.dateScheduled}
            </Typography>
          </React.Fragment>
        }>
        {children}
      </LightTooltip>
    </React.Fragment>
  );
}
