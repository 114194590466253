import React, { useState } from 'react';
import RouteDetails from './RouteDetails';
import RouteTiles from './RouteTiles';

export default function RouteColumn({ aircraftType }) {
  const [selectedRoute, setselectedRoute] = useState('');

  return (
    <>
      <RouteDetails selectedRoute={selectedRoute} />
      <RouteTiles
        aircraftType={aircraftType}
        selectedRoute={selectedRoute}
        setselectedRoute={setselectedRoute}
      />
    </>
  );
}
