import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import produce from 'immer';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

const { DateTime } = require('luxon');

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    backgroundColor: 'white',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const PPRStatus = ({ setpprInfo, pprInfo, valid, data }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ event, name, pprInfo, data }) => {
      await updatePPRstatus({ event, name, pprInfo, data });
    },
    {
      onMutate: async ({ event, name, pprInfo, data }) => {
        //console.log(name, pprInfo, event.target.value);
        await queryClient.cancelQueries(['PPRdata']);
        const previousPPRdata = queryClient.getQueryData(['PPRdata']);

        if (name === 'pprRequired') {
          queryClient.setQueryData(
            ['PPRdata'],
            produce(previousPPRdata, (draft) => {
              const index = draft.findIndex(
                (PPR) => PPR.pprID === pprInfo.pprID
              );
              if (index !== -1) draft[index].pprRequired = event.target.value;
            })
          );
        }

        if (name === 'pprStatus') {
          queryClient.setQueryData(
            ['PPRdata'],
            produce(previousPPRdata, (draft) => {
              const index = draft.findIndex(
                (PPR) => PPR.pprID === pprInfo.pprID
              );
              if (index !== -1) draft[index].pprStatus = event.target.value;
            })
          );
        }

        return [previousPPRdata, name, event];
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) => {
        alert('Error saving');
        queryClient.setQueryData(['PPRdata'], previousValue[0]);
        if (previousValue[1] === 'pprRequired') {
          setpprInfo({
            ...pprInfo,
            pprRequired: !previousValue[2].target.value,
          });
        }
        if (previousValue[1] === 'pprStatus') {
          setpprInfo({
            ...pprInfo,
          });
        }
      },
      // After success or failure, refetch the todos query
      onSuccess: () => {
        //queryClient.fetchQuery(['PPRdata']);
      },
    }
  );

  return (
    <>
      <FormControl component='fieldset' className={classes.formControl}>
        <FormLabel component='legend'>PPR Status</FormLabel>
        <RadioGroup
          style={{ flexDirection: 'row' }}
          aria-label='ppr status'
          name='pprStatus'
          value={pprInfo.pprStatus || 'none'}
          onChange={(event) => {
            setpprInfo({
              ...pprInfo,
              pprStatus: event.target.value,
              pprChangedBy:
                event.target.value +
                ';' +
                localStorage.getItem('userName') +
                ';' +
                DateTime.utc().toISO(),
            });
            //updatePPRstatus(e, e.target.name);
            const name = event.target.name;
            mutation.mutate({ event, name, pprInfo, data });
          }}>
          <FormControlLabel value='none' control={<Radio />} label='None' />
          <FormControlLabel
            value='pendingApproval'
            control={<Radio />}
            label='Requested'
          />
          <FormControlLabel
            disabled={!valid}
            value='approved'
            control={<Radio color='primary' />}
            label='Completed'
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

const updatePPRstatus = async ({ event, name, pprInfo, data }) => {
  //This function called whenever pprStatus changes or pprRequired changes
  const nameTemp = localStorage.getItem('userName');
  const dateStamp = DateTime.utc().toISO();

  let pprTypeChange = '';
  let pprRequiredNew = '';
  let pprStatusNew = pprInfo.pprStatus;
  if (name === 'pprStatus') {
    pprTypeChange = name;
    pprRequiredNew = pprInfo.pprRequired;
    pprStatusNew = event.target.value;
  }
  if (name === 'pprRequired') {
    pprTypeChange = name;
    pprRequiredNew = event.target.checked;
    pprStatusNew = pprInfo.pprStatus;
  } else {
  }

  const pprHistoryNew = {
    status: pprStatusNew,
    date: dateStamp,
    name: nameTemp,
    beforeAirfield: data.beforeAirfield,
    nextAirfield: data.nextAirfield,
    arrivalDateTime: data.arrivalDateTime,
    departureDateTime: data.departureDateTime,
    airfieldTimeZone: data.airfieldTimeZone,
    typeAircraft: data.typeAircraft,
  };

  const history = pprInfo.pprHistory;
  history.unshift(pprHistoryNew);

  const dataUpdate = {
    pprChange: pprTypeChange,
    pprID: pprInfo.pprID,
    pprStatus: pprStatusNew,
    pprRequired: pprRequiredNew,
    pprICAO: pprInfo.pprICAO,
    name: nameTemp,
    date: dateStamp,
    pprHistory: JSON.stringify(history),
  };

  const resp = await fetch('/db/updatePPRstatus.schedule.db.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(dataUpdate),
  });
  if (!resp.ok) {
    throw new Error('Network response was not ok');
  }
  return resp.json();
};
