import React from 'react';
import { Link } from 'react-router-dom';


export default function MyFallbackComponent({ componentStack, error }) {
  console.log('(Myfallback comp) Error: ', error.toString());
  console.log('(Myfallback comp) Stacktrace:: ', componentStack);
  return (
    <>
      <h3 style={{ textAlign: 'center', color: 'red', marginTop: '15vh' }}>
        {error.toString()}
      </h3>
      <Link
        onClick={(e) => {
          localStorage.clear();
        }}
        to={{
          pathname: `/signin`,
        }}>
        <h4 style={{ textAlign: 'center', color: 'blue', marginTop: '15vh' }}>
          Back to Sign-in page
        </h4>
      </Link>
    </>
  );
}
