export function itineraryTimeUpdate(type, missionInWork) {
  switch (type) {
    case 'first':
      for (let i = 0; i < missionInWork.length; i++) {
        //for first entry update takeoff time local, land time zulu, land time local
        //for follow on update takeoff time zulu based on turn then takeoff local, land zulu, land local
      }

      break;

    default:
      break;
  }
  return missionInWork;
}
