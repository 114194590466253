import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//TODO add browser router

ReactDOM.render(<App />, document.getElementById('schedule'));

/* ReactDOM.createRoot(document.getElementById('schedule')).render(
  <SchedulePage />
);
 */
