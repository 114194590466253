import { Chip, Tooltip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import produce from 'immer';
import React from 'react';
import '../App.css';
import MapModal from '../Components/Modal/GoogleMapModalWithButton';
import AirliftWrapper from '../Utils/AirliftWrapper';
import { turnTimeCheck } from '../Utils/turnTimeCheck';
import { JALISRow } from './JALISrow';
const { DateTime, Duration } = require('luxon');

export const ItineraryTableRow = ({
  airliftsWithHover,
  crewDay,
  HAZonLeg,
  index,
  JALIS_output,
  legTime,
  mission_legs,
  mission,
  poaTimeZone,
  podTimeZone,
  route_in_work,
  setairliftsWithHover,
}) => {
  function changeTime(timeInput, zoneInput) {
    const timeZulu = DateTime.fromISO(timeInput, { zone: 'UTC' });
    const timeLocal = timeZulu.setZone(zoneInput);
    return timeLocal;
  }

  let JALIS = false;
  if (
    JALIS_output &&
    Object.keys(JALIS_output).length !== 0 &&
    JALIS_output.constructor === Object
  ) {
    JALIS = true;
  }

  //The following checks for discrepancies between the local land time calcaulated and what is recorded in the fullMissionDataObject
  let landTimeError = false;
  let lTlocal = changeTime(
    mission.landTimes.split(' ')[index],
    poaTimeZone.databaseTimezone
  )
    .toISO()
    .split('+')[0];
  let lTlocalFromFull = mission.fullMissionDataObject[index].landTime.slice(
    0,
    23
  );
  if (lTlocal !== lTlocalFromFull) {
    landTimeError = true;
  }

  const earlyTakeoff = () => {
    //Checks to see if local takeoff time is before 0900 which is abnormal and may affect crew day
    let early = false;
    if (
      Number(
        changeTime(
          mission.takeoffTimes.split(' ')[index],
          podTimeZone.databaseTimezone
        ).toFormat('HHmm')
      ) < Number('0900')
    ) {
      early = true;
    }
    return early;
  };

  return (
    <>
      <TableRow key={index + 'rowTR'}>
        {HAZonLeg ? (
          <LightTooltip
            title={
              <React.Fragment>
                <Typography
                  variant='h5'
                  color='initial'
                  style={{ display: 'inline-block' }}>
                  HAZMAT on this leg.
                </Typography>
              </React.Fragment>
            }>
            <TableCell style={{ backgroundColor: 'red' }}>
              {index + 1}
            </TableCell>
          </LightTooltip>
        ) : (
          <TableCell>{index + 1}</TableCell>
        )}

        <TableCell align='center'>
          {DateTime.fromISO(mission.takeoffTimes.split(' ')[index]).toFormat(
            'dd LLL'
          )}
        </TableCell>
        <TableCell align='center'>{route_in_work.pod}</TableCell>
        {earlyTakeoff() ? (
          <LightTooltip
            title={
              <React.Fragment>
                <Typography
                  variant='h5'
                  color='initial'
                  style={{ display: 'inline-block' }}>
                  CAUTION! Takeoff time appears to be before standard 0900 local
                  takeoff.
                </Typography>
              </React.Fragment>
            }>
            <TableCell align='center' style={{ textDecoration: 'underline' }}>
              {changeTime(
                mission.takeoffTimes.split(' ')[index],
                podTimeZone.databaseTimezone
              ).toFormat('HHmm')}
              L (
              {changeTime(
                mission.takeoffTimes.split(' ')[index],
                podTimeZone.databaseTimezone
              ).offset > 0
                ? '+'
                : ''}
              {changeTime(
                mission.takeoffTimes.split(' ')[index],
                podTimeZone.databaseTimezone
              ).offset / 60}
              )
            </TableCell>
          </LightTooltip>
        ) : (
          <TableCell align='center'>
            {changeTime(
              mission.takeoffTimes.split(' ')[index],
              podTimeZone.databaseTimezone
            ).toFormat('HHmm')}
            L (
            {changeTime(
              mission.takeoffTimes.split(' ')[index],
              podTimeZone.databaseTimezone
            ).offset > 0
              ? '+'
              : ''}
            {changeTime(
              mission.takeoffTimes.split(' ')[index],
              podTimeZone.databaseTimezone
            ).offset / 60}
            )
          </TableCell>
        )}
        <TableCell align='center'>
          {DateTime.fromISO(mission.fullMissionDataObject[index].toTimeZulu, {
            zone: 'UTC',
          }).toFormat('HHmm')}{' '}
          Z
        </TableCell>
        <TableCell align='center'>{route_in_work.poa}</TableCell>
        <Hidden lgDown>
          <TableCell align='center'>
            {DateTime.fromISO(mission.landTimes.split(' ')[index]).toFormat(
              'dd LLL'
            )}
          </TableCell>
        </Hidden>
        <TableCell
          align='center'
          style={{
            backgroundColor: landTimeError === true ? 'pink' : '',
          }}>
          {changeTime(
            mission.landTimes.split(' ')[index],
            poaTimeZone.databaseTimezone
          ).toFormat('HHmm')}
          L (
          {changeTime(
            mission.landTimes.split(' ')[index],
            poaTimeZone.databaseTimezone
          ).offset > 0
            ? '+'
            : ''}
          {changeTime(
            mission.landTimes.split(' ')[index],
            poaTimeZone.databaseTimezone
          ).offset / 60}
          )
        </TableCell>
        <TableCell align='center'>
          <LightTooltip
            title={
              <React.Fragment>
                <Typography
                  variant='h5'
                  color='initial'
                  style={{ display: 'inline-block' }}>
                  {DateTime.fromISO(
                    mission.fullMissionDataObject[index].landTimeZulu,
                    { zone: 'UTC' }
                  )
                    .diff(
                      DateTime.fromISO(
                        mission.fullMissionDataObject[index].toTimeZulu,
                        { zone: 'UTC' }
                      ),
                      'hours'
                    )
                    .toFormat('hh+mm')}
                </Typography>
              </React.Fragment>
            }>
            <div>
              {DateTime.fromISO(
                mission.fullMissionDataObject[index].landTimeZulu,
                { zone: 'UTC' }
              ).toFormat('HHmm')}{' '}
              Z
            </div>
          </LightTooltip>
        </TableCell>
        <TableCell align='center'>
          {legTime.toFormat('hh+mm')} leg time
        </TableCell>
        <TableCell
          align='center'
          style={{
            backgroundColor:
              crewDay > Duration.fromObject({ minutes: 900 })
                ? 'red'
                : crewDay > Duration.fromObject({ minutes: 780 })
                ? 'yellow'
                : '',
          }}>
          {crewDay.toFormat('hh+mm')}
        </TableCell>
        <TableCell
          align='center'
          style={{
            backgroundColor:
              turnTimeCheck(
                mission.typeAircraft,
                mission.turnTime.split(' ')[index],
                mission?.fullMissionDataObject[index]?.RON,
                mission?.fullMissionDataObject[index]?.crewChange
              ) === false
                ? 'darkorange'
                : '',
          }}>
          {mission.turnTime !== undefined
            ? mission.turnTime.split(' ')[index] / 60
            : ''}
        </TableCell>
        <TableCell>
          {mission?.fullMissionDataObject[index]?.RON
            ? mission?.fullMissionDataObject[index]?.crewChange
              ? 'RON/CC'
              : 'RON'
            : mission?.fullMissionDataObject[index]?.crewChange
            ? 'CC'
            : ''}
        </TableCell>
        <Hidden lgDown>
          <TableCell align='center'>{mission_legs[index]}</TableCell>
        </Hidden>
        <Hidden mdDown>
          <TableCell align='center'>
            <Button
              variant='outlined'
              color='primary'
              href={route_in_work.mapLink}
              target='_blank'
              rel='noopener noreferrer'>
              Skyvector Link
            </Button>
          </TableCell>
        </Hidden>
        <TableCell align='center'>
          <MapModal
            routeData={route_in_work}
            key={index + 'mm'}
            buttonTitle={'Google Map'}
          />
        </TableCell>
        <Hidden mdDown>
          <TableCell align='center'>
            {airliftsWithHover[index] &&
              airliftsWithHover[index].length !== 0 &&
              airliftsWithHover[index].map((lift, ind) => {
                return (
                  <React.Fragment key={ind + 'wrapperFrag'}>
                    <AirliftWrapper airlift={lift}>
                      <Chip
                        color='primary'
                        style={{
                          margin: '2px',
                          fontSize: '1rem',
                          backgroundColor:
                            lift.highlighted === true
                              ? 'pink'
                              : lift.HAZ === '1'
                              ? 'red'
                              : lift.DV === '1'
                              ? 'yellow'
                              : '',
                          color:
                            lift.HAZ === '1'
                              ? 'black'
                              : lift.DV === '1'
                              ? 'black'
                              : '',
                        }}
                        label={lift.airliftName}
                        onMouseOver={(e) => {
                          e.preventDefault();
                          setairliftsWithHover(
                            produce(airliftsWithHover, (draft) => {
                              draft.forEach((elem1, ind1) => {
                                elem1.forEach((elem2, ind2) => {
                                  if (draft[ind1][ind2]?.id === lift.id) {
                                    draft[ind1][ind2].highlighted = true;
                                  }
                                });
                              });
                            })
                          );
                        }}
                        onMouseOut={(e) => {
                          e.preventDefault();
                          setairliftsWithHover(
                            produce(airliftsWithHover, (draft) => {
                              draft.forEach((elem1, ind1) => {
                                elem1.forEach((elem2, ind2) => {
                                  if (draft[ind1][ind2]?.id === lift.id) {
                                    draft[ind1][ind2].highlighted = false;
                                  }
                                });
                              });
                            })
                          );
                        }}
                      />
                    </AirliftWrapper>
                  </React.Fragment>
                );
              })}
          </TableCell>
        </Hidden>
      </TableRow>
      {JALIS === true ? (
        <JALISRow
          key={index + 'JALIS row'}
          index={index}
          JALIS={JALIS}
          JALIS_output={JALIS_output}
          route_in_work={route_in_work}
          mission={mission}
          podTimeZone={podTimeZone}
          poaTimeZone={poaTimeZone}
          legTime={legTime}
        />
      ) : null}
    </>
  );
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);
