import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';
import React, { useContext } from 'react';
import { MissionContext } from '../store';
const { DateTime } = require('luxon');

export function MissionLink(prop) {
  const { setMissionObj } = useContext(MissionContext);
  //Button for mission callsign and link to mission detail
  const fullname = prop.data.cnv.split('-');

  return (
    <Button
      variant='outlined'
      color='primary'
      style={{ backgroundColor: 'white' }}
      href=''
      onClick={() => {
        setMissionObj({ ...prop.data });
      }}>
      CNV {fullname[0]}, Mod {prop.data.modNumber}, Update:{' '}
      {prop.data.updateNumber}
    </Button>
  );
}

export function PrintDate(prop) {
  //const missionDate = prop.data.firstTOlocal;
  if (prop.data.firstTOlocal !== '' && prop.data.firstTOlocal !== null) {
    let firstTry = DateTime.fromISO(prop.data.firstTOlocal).toFormat(
      'dd LLL yyyy / hhmm'
    );
    //firstTry = firstTry.toLocaleString();
    return firstTry;
  } else {
    return 'no date';
  }
}

export function DaysTill(prop) {
  const startDate = DateTime.fromISO(
    DateTime.fromISO(prop.data.firstTOlocal).toISODate()
  );
  const todayDate = DateTime.fromISO(DateTime.local().toISODate());

  return startDate.diff(todayDate, 'days').toFormat('dd');
}

export function StatusButton(prop) {
  //Generic button
  const typeStatus = prop.statusType + 'status';

  if (
    prop.data[typeStatus] == null ||
    prop.data[typeStatus].split(';')[0] === '0'
  ) {
    const severity = 'warning';
    return (
      <div>
        <Alert severity={severity}>
          {prop.statusType !== 'FinalReview' ? prop.statusType : 'Final Review'}{' '}
          pending
        </Alert>
      </div>
    );
  }
  if (prop.data[typeStatus].split(';')[0] === '2') {
    const severity = 'success';
    return (
      <Alert severity={severity}>
        {prop.statusType !== 'FinalReview' ? prop.statusType : 'Final Review'}{' '}
        approved
      </Alert>
    );
  }
  if (prop.data[typeStatus].split(';')[0] === '3') {
    const severity = 'info';
    return (
      <Alert severity={severity}>
        {prop.statusType !== 'FinalReview' ? prop.statusType : 'Final Review'}{' '}
        requested
      </Alert>
    );
  } else {
    const severity = 'error';
    return (
      <Alert severity={severity}>{prop.statusType} awaiting approval</Alert>
    );
  }
}
