import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import he from 'he';
import React from 'react';

export const PPRNotes = ({ changed, handleChange, pprInfo }) => {
  const classes = useStyles();

  return (
    <>
      <Tooltip title='Other notes'>
        <TextField
          disabled={pprInfo.pprRequired ? false : true}
          style={
            changed === true
              ? { backgroundColor: 'red', width: '300px' }
              : { backgroundColor: '', width: '300px' }
          }
          id='notes'
          label='Other notes'
          className={classes.textField}
          value={he.unescape(he.decode(pprInfo.notes || ''))}
          onChange={handleChange('notes')}
          margin='normal'
          variant='outlined'
          multiline
          rowsMax='4'
        />
      </Tooltip>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    backgroundColor: 'white',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
