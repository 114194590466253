import { useQuery } from 'react-query';

const getWarning = async () => {
  const resp = await fetch('/db/loadWarnings.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
  });
  if (!resp.ok) {
    throw new Error('Network response was not ok');
  }
  return resp.json();
};

export default function useWarnings() {
  return useQuery(['warnings'], getWarning, {
    staleTime: 5 * 60 * 1000,
    placeholderData: [],
  });
}
