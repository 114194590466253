import { Button } from '@material-ui/core';
import produce from 'immer';
import React from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQueryClient } from 'react-query';

export default function ReleaseMission({ released, missionID }) {
  /* Release codes
code 0 : viewable but not clickable by squadrons
code 1: viewable and clickable
code 2: neither viewable nor clickable
*/

  const queryClient = useQueryClient();
  const alert = useAlert();
  const mutation = useMutation(
    async ({ missionID, releasedNew }) => {
      await uploadReleased({ missionID, releasedNew });
    },
    {
      onMutate: async ({ missionID, releasedNew }) => {
        await queryClient.cancelQueries(['missionDataPartial']);
        const previousData = queryClient.getQueryData(['missionDataPartial']);

        queryClient.setQueryData(
          ['missionDataPartial'],
          produce(previousData, (draft) => {
            const index = draft.findIndex(
              (mission) => mission.missionID === missionID
            );
            if (index !== -1) {
              draft[index].released = String(releasedNew);
            }
          })
        );

        return [previousData];
      },
      // On failure, roll back to the previous value
      onError: async (err, variables, previousValue) => {
        await queryClient.setQueryData(
          ['missionDataPartial'],
          previousValue[0]
        );
        alert.error('Problem saving note.  See Administrator.');
      },
      // After success or failure, refetch the todos query
      onSuccess: () => {
        alert.success('Note saved!');
        queryClient.invalidateQueries(['missionDataPartial']);
      },
    }
  );

  return (
    <Button
      color={
        released !== '0' ? (released === '2' ? 'secondary' : 'primary') : ''
      }
      variant='contained'
      onClick={async (e) => {
        //TODO set released new
        e.preventDefault();
        let releasedNew = released++;
        mutation.mutate({ missionID, releasedNew });
      }}>
      {released === '2'
        ? 'No, not viewable'
        : released === '1'
        ? 'Yes, viewable'
        : 'Viewable, not clickable'}
    </Button>
  );
}

async function uploadReleased({ missionID, releasedNew }) {
  const dataUpdate = {
    missionID: missionID,
    released: String(releasedNew),
  };

  const resp = await fetch('/db/updateReleaseStatus.db.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(dataUpdate),
  });

  if (!resp.ok) {
    throw new Error('Network response was not ok');
  } else {
    return resp.text();
  }
}
