export function handleErrors(response) {
  if (response.status === 401) {
    console.log('Issue at (handle errors)', response.url);
    throw Error(response.status);
  }
  if (!response.ok) {
    throw Error(response.status);
  }
  return response;
}

export function typeError(response) {
  alert('Error at database entry (type Error');
  console.log(response);
}
