const { DateTime } = require('luxon');

export function checkJALIS(inputJALIS) {
  let output = {};

  const message_split_lines = inputJALIS
    .split(/[\n]/)
    .map((s) => s.trim())
    .filter(String);

  //console.log(message_split_lines);

  let itin = false;
  let itin_arr = [];
  let itin_obj = {};
  /*  itin_arr=[{pod, poa},{}] */

  for (let i = 0; i < message_split_lines.length; i++) {
    //Subj line
    if (message_split_lines[i].match(/SUBJ\//)) {
      const thisRow = message_split_lines[i].split('/');
      output.AC_type = thisRow[2];
      output.callsign = thisRow[3];
      if (thisRow[4].includes('MOD')) {
        output.startDate = DateTime.fromFormat(thisRow[5].trim(), 'dd MMM yy');
      } else {
        output.startDate = DateTime.fromFormat(thisRow[4].trim(), 'dd MMM yy');
      }
    }

    //Capture Squadron, mission number, SECO
    if (message_split_lines[i].match(/RMKS\//)) {
      const thisRow = message_split_lines[i].split('/');
      output.squadron = thisRow[1].split(' ')[1];
      output.missionNumber = thisRow[2].split(' ')[2];
      output.seco = thisRow[3];
    }

    //First itinerary line
    if (message_split_lines[i].match(/\/ORIG/)) {
      for (let j = 0; j < 20; j++) {
        if (itin === false) {
          const thisRow = message_split_lines[i + j].split('/');
          if (j === 0) {
            itin_obj.pod = thisRow[3];
            let times = thisRow[5].split(' ');
            itin_obj.toTimeZulu = times[0].replace(/[(]|[)]/g, '');
            itin_obj.toTimeLocal = times[1].replace(/[(]|[)]/g, '');
            itin_obj.airlifts = thisRow[6].trim();
          } else if (message_split_lines[i + j].match(/TERM/)) {
            itin_obj.poa = thisRow[3];
            let timesLand = thisRow[2].split(' ');
            itin_obj.landTimeZulu = timesLand[0].replace(/[(]|[)]/g, '');
            itin_obj.landTimeLocal = timesLand[1].replace(/[(]|[)]/g, '');
            //itin_obj.airlifts = thisRow[6].trim();
            itin_arr.push(itin_obj);
            itin_obj = {};
          } else {
            itin_obj.poa = thisRow[3];
            let timesLand = thisRow[2].split(' ');
            itin_obj.landTimeZulu = timesLand[0].replace(/[(]|[)]/g, '');
            itin_obj.landTimeLocal = timesLand[1].replace(/[(]|[)]/g, '');
            itin_arr.push(itin_obj);
            itin_obj = {};
            itin_obj.pod = thisRow[3];
            let times = thisRow[5].split(' ');
            itin_obj.toTimeZulu = times[0].replace(/[(]|[)]/g, '');
            itin_obj.toTimeLocal = times[1].replace(/[(]|[)]/g, '');
            itin_obj.airlifts = thisRow[6].trim();
          }
        }
        if (message_split_lines[i + j].match(/TERM/)) {
          itin = true;
        }
      }
      output.itinerary = itin_arr;
    }

    //Parse airlifts
    let airlifts = false;
    let airlift_arr = [];
    let airlift_obj = {};
    if (message_split_lines[i].match(/LIFT SECTION/) && airlifts === false) {
      for (let j = 0; j < 100; j++) {
        if (airlifts === false) {
          if (message_split_lines[i + j].match(/:[A-Z]\//g)) {
            //Begining of airlift line
            let thisRow = message_split_lines[i + j].split('/');
            airlift_obj.pod = thisRow[3].substr(5);
            airlift_obj.poa = thisRow[4].substr(5);
            airlift_obj.code = thisRow[0].substr(1, 1);
            airlift_obj.pax = message_split_lines[i + j + 1]
              .split('/')[2]
              .split(':')[1]
              .trim();
            airlift_obj.cargo = message_split_lines[i + j + 2]
              .split('/')[0]
              .split(':')[1]
              .trim();
            airlift_obj.PUJC = message_split_lines[i + j + 2].split('/')[2];
            airlift_arr.push(airlift_obj);
            airlift_obj = {};
          }
        }
        if (
          message_split_lines[i + j].match(
            /TF 63 AIR LOGISTICS CONTACT INFORMATION/
          )
        ) {
          airlifts = true;
          break;
        }
      }
      output.airlifts = airlift_arr;
    }
  }
  //console.log(output);
  return output;
}
