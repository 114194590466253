import { Hidden, Typography, useMediaQuery } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import he from 'he';
import React, { useEffect, useState } from 'react';
import '../App.css';
import usePPRData from '../Utils/Hooks/usePPRdata';
import PPRFileChip from './PPRFileChip';
import './PPRTable.css';

export default function PPREntry({
  mission,
  data,
  airliftArray,
  indexpointer,
  routes,
  airports,
}) {
  //const [pprInfoAll, setpprInfoAll] = useContext(PPRContext);
  const [pprInfo, setpprInfo] = useState({
    pprID: data.pprID,
    pprNumber: '',
    pprServices: '',
    pprAgent: '',
    pprContact: '',
    parkingLocation: '',
    offloadType: 'none',
    fuel: 'yes',
    notes: '',
    pprChangedBy: '',
    pprHistory: [],
    pprICAO: data.airfieldICAO,
    pprStatus: '',
    pprRequired: data.last === true ? false : true,
  });
  const extraSmall = useMediaQuery('(max-width:500px)');
  const { data: pprInfoAll, status } = usePPRData();

  /*   useEffect(() => {
    if (pprInfoAll && pprInfoAll.length !== 0) {
      const filterPPR = pprInfoAll.find((element) => {
        return element.pprID === data.pprID;
      });
      if (filterPPR !== undefined) {
        setpprInfo((pprInfo) => {
          const history_array =
            filterPPR.pprHistory !== '' ? JSON.parse(filterPPR.pprHistory) : [];
          return {
            ...pprInfo,
            pprNumber: filterPPR.pprNumber,
            pprServices: filterPPR.pprServices,
            pprAgent: filterPPR.pprAgent,
            pprContact: filterPPR.pprContact,
            parkingLocation: filterPPR.parkingLocation,
            offloadType: filterPPR.offloadType,
            notes: filterPPR.notes,
            pprStatus: filterPPR.pprStatus,
            pprHistory: history_array,
            pprICAO: filterPPR.pprICAO,
            pprChangedBy: filterPPR.pprChangedBy,
            pprRequired: filterPPR.pprRequired === 'true' ? true : false,
          };
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pprInfoAll]);
 */
  useEffect(() => {
    //Check to see if there is existing PPR Entry in database, if so, copy into pprInfo
    if (pprInfoAll) {
      let filterPPR = pprInfoAll.find((element) => {
        return element.pprID === data.pprID;
      });

      //The following checks for old style PPR (without) ITS update code, so CNV 6512-01-1-ETAR-1 vice CNV 6512-01-1-2-ETAR-1
      if (filterPPR === undefined) {
        filterPPR = pprInfoAll.find((element) => {
          return element.pprID === data.pprIDold;
        });
      }

      if (filterPPR !== undefined) {
        setpprInfo((pprInfo) => {
          return {
            ...pprInfo,
            pprNumber: filterPPR.pprNumber,
            pprServices: filterPPR.pprServices,
            pprAgent: filterPPR.pprAgent,
            pprContact: filterPPR.pprContact,
            parkingLocation: filterPPR.parkingLocation,
            offloadType: filterPPR.offloadType,
            notes: filterPPR.notes,
            pprStatus: filterPPR.pprStatus,
            //pprHistory: history_array,
            pprICAO: filterPPR.pprICAO,
            pprChangedBy: filterPPR.pprChangedBy,
            pprRequired:
              filterPPR.pprRequired === 'true' || filterPPR.pprRequired === true
                ? true
                : false,
          };
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pprInfoAll]);

  const tableStyle = {
    marginTop: '10px',
    marginBottom: '2vh',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'lightyellow',
    outline: 'solid blue',
  };

  if (status === 'error') {
    return <span>Error in PPR Entry</span>;
  }
  if (status === 'loading') {
    return <span>Loading PPR Entry</span>;
  }

  if (extraSmall === true) {
    return <PPREntryVertical pprInfo={pprInfo} data={data} />;
  }

  return (
    <Table style={tableStyle}>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Airfield</TableCell>
          <TableCell align='center'>PPR Status</TableCell>
          <TableCell align='center'>PPR Number</TableCell>
          <Hidden smDown>
            <TableCell align='center'>Services confirmed</TableCell>
          </Hidden>
          <TableCell align='center'>Servicing Agent</TableCell>
          <TableCell align='center'>Servicing Agent Contact</TableCell>
          <TableCell align='center'>Other Notes</TableCell>
          <Hidden smDown>
            <TableCell align='center'>Files</TableCell>
          </Hidden>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align='center' style={{ fontWeight: 'bold' }}>
            {data.airfieldICAO}
          </TableCell>
          <TableCell align='center'>
            {pprInfo.pprRequired
              ? pprInfo.pprStatus === 'approved'
                ? 'Approved'
                : 'Pending'
              : 'Not Required'}
          </TableCell>
          <TableCell align='center'>
            {he.unescape(he.decode(pprInfo.pprNumber || ''))}
          </TableCell>
          <Hidden smDown>
            <TableCell align='center'>
              {he.unescape(he.decode(pprInfo.pprServices || ''))}
            </TableCell>
          </Hidden>
          <TableCell align='center'>
            {he.unescape(he.decode(pprInfo.pprAgent || ''))}
          </TableCell>
          <TableCell align='center'>
            {he.unescape(he.decode(pprInfo.pprContact || ''))}
          </TableCell>
          <TableCell align='center'>
            {he.unescape(he.decode(pprInfo.notes || ''))}
          </TableCell>
          <Hidden smDown>
            <TableCell align='center'>
              <PPRFileChip key={data.pprID} props={data.pprID} />
            </TableCell>
          </Hidden>
        </TableRow>
      </TableBody>
    </Table>
  );
}

const PPREntryVertical = ({ pprInfo, data }) => {
  const tableStyle = {
    marginTop: '10px',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'lightyellow',
    outlineStyle: 'auto',
  };
  const headStyle = {
    fontWeight: 'bold',
  };

  return (
    <Table style={tableStyle}>
      <TableBody>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            Airfield
          </TableCell>
          <TableCell align='center' style={{ fontWeight: 'bold' }}>
            <Typography variant='body1'> {data.airfieldICAO}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            PPR Status
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {pprInfo.pprRequired
                ? pprInfo.pprStatus === 'approved'
                  ? 'Approved'
                  : 'Pending'
                : 'Not Required'}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            PPR Number
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {he.unescape(he.decode(pprInfo.pprNumber || ''))}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            Services confirmed
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {he.unescape(he.decode(pprInfo.pprServices || ''))}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            Servicing Agent
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {he.unescape(he.decode(pprInfo.pprAgent || ''))}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            Servicing Agent Contact
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {he.unescape(he.decode(pprInfo.pprContact || ''))}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            Other Notes
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {he.unescape(he.decode(pprInfo.notes || ''))}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
