import { Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';

export default function AirliftDetails({ airliftSelected }) {
  if (airliftSelected === '') {
    return (
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
        //spacing='2'
        style={{
          backgroundColor: 'yellow',
          textAlign: 'center',
          padding: '2px',
        }}>
        <Grid item xs>
          <h5>Airlift Details</h5>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='flex-start'
      style={{
        backgroundColor: 'yellow',
        textAlign: 'center',
        padding: '2px',
      }}>
      <Hidden lgDown>
        <Grid container item justify='center' direction='row'>
          <Grid item xs={12}>
            <h3 style={{ textAlign: 'center' }}>
              Airlift Details
              {Object.keys(airliftSelected).length !== 0 &&
              airliftSelected.HAZ === '1' ? (
                <span style={{ color: 'red' }}> HAZMAT</span>
              ) : null}
              {Object.keys(airliftSelected).length !== 0 &&
              airliftSelected.DV === '1' ? (
                <span style={{ color: 'red' }}> DV</span>
              ) : null}
            </h3>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container item xs direction='row'>
        <Grid container item xs direction='column'>
          <Grid item>
            {Object.keys(airliftSelected).length !== 0 && (
              <>
                Route: {airliftSelected?.POD}-{airliftSelected?.POA}
              </>
            )}
          </Grid>
          <Grid item>
            {Object.keys(airliftSelected).length !== 0 && (
              <>Airlift Name: {airliftSelected?.airliftName}</>
            )}
          </Grid>
        </Grid>
        <Grid container item xs direction='column'>
          <Grid item>
            {Object.keys(airliftSelected).length !== 0 && (
              <>
                PAX: {airliftSelected?.PAX} / Cargo:{' '}
                {airliftSelected?.cargoWeight}
              </>
            )}
          </Grid>
          <Grid item>
            {Object.keys(airliftSelected).length !== 0 && (
              <>POC: {airliftSelected?.liftRequestor}</>
            )}
          </Grid>
          <Grid item>
            {Object.keys(airliftSelected).length !== 0 && (
              <>Date Scheduled: {airliftSelected?.dateScheduled}</>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
