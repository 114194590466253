import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';
import StatusChange from '../statuschange.js';
import StatusChangePA from '../statuschangePA.js';
import StatusChangePR from '../statuschangePR.js';
import { ContactsValidContext } from '../store';

export default function MissionStatus({ props, missionID }) {
  const [contactsValid] = useContext(ContactsValidContext);

  return (
    <>
      <Grid
        container
        spacing={3}
        direction='row'
        justify='center'
        alignItems='center'>
        <Grid item>
          <Box
            border={1}
            borderRadius='borderRadius'
            style={{ minWidth: '255px' }}>
            <StatusChange
              key={props.match.params.missionID + 'c'}
              statusType='APACS'
              authorizedUsers={{
                nostatus: ['admin', 'dh', 'fw'],
                review: ['admin', 'dh'],
                approve: ['admin', 'dh', 'fw'],
              }}
              notes={'Reviewed at ITS by DH'}
              missionID={missionID}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box
            border={1}
            borderRadius='borderRadius'
            style={{ minWidth: '255px' }}>
            <StatusChangePR
              key={props.match.params.missionID + 'co'}
              missionID={missionID}
              statusType='Contacts'
              authorizedUsers={{
                nostatus: ['admin', 'dh', 'fw'],
                requested: ['admin', 'dh', 'fw'],
                review: ['admin', 'dh', 'fw'],
                approve: ['admin', 'dh'],
              }}
              notes={'View only'}
              validated={contactsValid}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box
            border={1}
            borderRadius='borderRadius'
            style={{ minWidth: '255px' }}>
            <StatusChangePR
              key={props.match.params.missionID + 'p'}
              missionID={missionID}
              statusType='PPR'
              authorizedUsers={{
                nostatus: ['admin', 'dh', 'fw'],
                requested: ['admin', 'dh', 'fw'],
                review: ['admin', 'dh', 'fw'],
                approve: ['admin', 'dh'],
              }}
              categories={['pending', 'reviewed']}
              notes={'Set Status'}
              validated={true}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box
            border={1}
            borderRadius='borderRadius'
            style={{ minWidth: '255px' }}>
            <StatusChangePA
              key={props.match.params.missionID + 'f'}
              missionID={missionID}
              statusType='FinalReview'
              authorizedUsers={{
                nostatus: ['admin', 'dh'],
                review: ['admin', 'dh'],
                approve: ['admin', 'dh'],
              }}
              notes={'Set Status'}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
