import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import he from 'he';
import produce from 'immer';
import React from 'react';
import { useAlert } from 'react-alert';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useMutation, useQueryClient } from 'react-query';
import { OffLoadEquipment } from './OffloadEquipment';

export const PPRInfoRow = ({
  changed,
  handleChange,
  handleChangeTelephone,
  mission,
  pprInfo,
  setChanged,
  setpprInfo,
  setValid,
  phoneVerification,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async ({ event, pprInfo }) => {
      await sendPPRinfo({ event, pprInfo });
    },
    {
      onMutate: async ({ event, pprInfo }) => {
        await queryClient.cancelQueries(['PPRdata']);
        const previousPPRdata = queryClient.getQueryData(['PPRdata']);

        setChanged(false);

        if (previousPPRdata.find((elem) => elem.pprID === pprInfo.pprID)) {
          queryClient.setQueryData(
            ['PPRdata'],
            produce(previousPPRdata, (draft) => {
              const index = draft.findIndex(
                (PPR) => PPR.pprID === pprInfo.pprID
              );
              if (index !== -1) {
                draft[index].pprNumber = pprInfo.pprNumber;
                draft[index].pprServices = pprInfo.pprServices;
                draft[index].pprContact = pprInfo.pprContact;
                draft[index].pprAgent = pprInfo.pprAgent;
                draft[index].notes = pprInfo.notes;
              }
            })
          );
        } else {
          //If PPR does not yet exist in db we need to add to cache until next db refresh
          queryClient.setQueryData(['PPRdata'], (old) => [
            ...old,
            { ...pprInfo },
          ]);
        }

        return [previousPPRdata, pprInfo];
      },
      // On failure, roll back to the previous value
      onError: async (err, variables, previousValue) => {
        await queryClient.setQueryData(['PPRdata'], previousValue[0]);
        alert('Problem saving your data.');
        setChanged(true);
        setpprInfo({
          ...pprInfo,
          pprServices: previousValue[1].pprServices,
          pprAgent: previousValue[1].pprAgent,
          notes: previousValue[1].notes,
          pprContact: previousValue[1].pprContact,
          pprNumber: previousValue[1].pprNumber,
        });
      },
      // After success or failure, refetch the todos query
      onSuccess: () => {
        //queryClient.fetchQuery(['PPRdata']);
        alert.success('PPR info updated!');
        if (checkValid(pprInfo, mission)) {
          setValid(true);
        } else {
          setValid(false);
        }
      },
    }
  );

  return (
    <>
      <TableCell align='center'>
        <Button
          id='saveNotes'
          name='saveNotes'
          variant='contained'
          color='primary'
          onClick={(event) => {
            //checkPPR(e, pprInfo);
            mutation.mutate({ event, pprInfo });
          }}>
          Save PPR info
        </Button>
      </TableCell>
      <TableCell align='center'>
        <Tooltip title='In the case that a PPR number is not issued but all servicing is confirmed, enter  APPROVED'>
          <TextField
            disabled={pprInfo.pprRequired ? false : true}
            style={
              changed === true
                ? { backgroundColor: 'red' }
                : { backgroundColor: '' }
            }
            id='pprNumber'
            label='PPR Number'
            className={classes.textField}
            value={he.unescape(he.decode(pprInfo.pprNumber || ''))}
            onChange={handleChange('pprNumber')}
            margin='normal'
            variant='outlined'
            multiline
            rowsMax='4'
          />
        </Tooltip>
      </TableCell>
      <TableCell align='center'>
        <Tooltip title='Services noted should include offload equipment, fuel confirmed, location of parking, etc'>
          <TextField
            disabled={pprInfo.pprRequired ? false : true}
            style={
              changed === true
                ? { backgroundColor: 'red' }
                : { backgroundColor: '' }
            }
            id='pprServices'
            label='Services confirmed'
            className={classes.textField}
            value={he.decode(pprInfo.pprServices || '')}
            onChange={handleChange('pprServices')}
            margin='normal'
            variant='outlined'
            multiline
            rowsMax='4'
          />
        </Tooltip>
      </TableCell>
      <TableCell>
        <OffLoadEquipment
          mission={mission}
          changed={changed}
          handleChange={handleChange}
          pprInfo={pprInfo}
        />
      </TableCell>
      <TableCell>
        <Tooltip title='This should be name of servicing agent located at airfield, not World Fuel Servies or some other intermediary.  For MIL fields enter name of person approving PPR'>
          <TextField
            disabled={pprInfo.pprRequired ? false : true}
            style={
              changed === true
                ? { backgroundColor: 'red' }
                : { backgroundColor: '' }
            }
            id='pprAgent'
            label='Servicing agent'
            className={classes.textField}
            value={he.unescape(he.decode(pprInfo.pprAgent || ''))}
            onChange={handleChange('pprAgent')}
            margin='normal'
            variant='outlined'
            multiline
            rowsMax='4'
          />
        </Tooltip>
      </TableCell>
      <TableCell>
        <>
          <Tooltip title='Commercial phone for on the ground servicing agent.  For MIL fields put the Base Ops number.  No DSN'>
            <PhoneInput
              disabled={pprInfo.pprRequired ? false : true}
              id='pprContact'
              placeholder='Servicing agent contact (NO DSN!)'
              defaultCountry='IT'
              international
              withCountryCallingCode
              className={classes.textField}
              value={he.unescape(he.decode(pprInfo.pprContact || ''))}
              onChange={handleChangeTelephone('pprContact')}
              /*           countrySelectComponent={CountrySelect} */
            />
          </Tooltip>
        </>
      </TableCell>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    backgroundColor: 'white',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

async function sendPPRinfo({ event, pprInfo }) {
  //This only updates the ppr info, not status or history
  /*   const dataUpdate = {
    ...inputs,
  }; */
  const dataUpdate = {
    ...pprInfo,
  };
  const resp = await fetch('/db/updatePPRinfo.schedule.db.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(dataUpdate),
  });
  if (!resp.ok) {
    throw new Error('Network response was not ok');
  }
  return resp.json();
}

function checkValid(pprInfo, mission) {
  let output = false;
  if (
    pprInfo.pprNumber &&
    pprInfo.pprServices &&
    pprInfo.pprAgent &&
    pprInfo.pprContact &&
    pprInfo.offloadType !== 'none' &&
    mission.typeAircraft === 'c40routes'
  ) {
    output = true;
  } else if (
    pprInfo.pprNumber &&
    pprInfo.pprServices &&
    pprInfo.pprAgent &&
    pprInfo.pprContact &&
    pprInfo.offloadType !== 'none' &&
    mission.typeAircraft === 'c130routes'
  ) {
    output = true;
  } else if (
    pprInfo.pprNumber &&
    pprInfo.pprServices &&
    pprInfo.pprAgent &&
    pprInfo.pprContact &&
    mission.typeAircraft === 'c26routes'
  ) {
    output = true;
  }
  return output;
}
