import { Grid } from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import React, { useContext } from 'react';
import '../App.css';
import { MissionInWork } from '../MissionGeneratorStore';

const { DateTime, Duration } = require('luxon');

export const TakeOffLocal = ({ props }) => {
  const [missionInWork, setmissionInWork] = useContext(MissionInWork);

  const handleDateChange = (e) => {
    if (e.invalid === null) {
      setmissionInWork((prevState) => {
        const newState = [...prevState];

        //Set local takeoff of this row

        newState[props.rowNumber].toTime = e.toISO().split('.')[0];

        //Set zulu takeiff of this row
        newState[props.rowNumber].toTimeZulu = DateTime.fromISO(
          e.toISO().split('.')[0],
          {
            zone: newState[props.rowNumber].podTimeZone,
          }
        )
          .toUTC()
          .toFormat("yyyy'-'LL'-'dd'T'HH':'mm", {
            includeOffset: false,
          });

        //Set zulu land time of this row
        newState[props.rowNumber].landTimeZulu = DateTime.fromISO(
          newState[props.rowNumber].toTimeZulu,
          { zone: 'UTC' }
        )
          .plus(
            Duration.fromObject({
              minutes: newState[props.rowNumber].roundedEnrouteTime,
            })
          )
          .toUTC()
          .toISO({ includeOffset: false });

        //Set local land time of this row
        newState[props.rowNumber].landTime = DateTime.fromISO(
          newState[props.rowNumber].landTimeZulu,
          { zone: 'UTC' }
        )
          .setZone(newState[props.rowNumber].poaTimeZone)
          .toISO({ includeOffset: false });

        //Adjust times in the rows before
        for (let i = props.rowNumber - 1; i >= 0; i--) {
          //Set zulu land time row before
          newState[i].landTimeZulu = DateTime.fromISO(
            newState[i + 1].toTimeZulu,
            {
              zone: 'UTC',
            }
          )
            .minus(
              Duration.fromObject({
                minutes: newState[i].turnTime.minutes,
              })
            )
            .toUTC()
            .toISO({ includeOffset: false });

          //Set zulu takeoff time row before
          newState[i].toTimeZulu = DateTime.fromISO(newState[i].landTimeZulu, {
            zone: 'UTC',
          })
            .minus(
              Duration.fromObject({
                minutes: newState[i].roundedEnrouteTime,
              })
            )
            .toUTC()
            .toISO({ includeOffset: false });

          //Set local landtime in row before
          newState[i].landTime = DateTime.fromISO(newState[i].landTimeZulu, {
            zone: 'UTC',
          })
            .setZone(newState[i].poaTimeZone)
            .toISO({ includeOffset: false });

          //Set local takeoff time row before
          newState[i].toTime = DateTime.fromISO(newState[i].toTimeZulu, {
            zone: 'UTC',
          })
            .setZone(newState[i].podTimeZone)
            .toISO({ includeOffset: false });
        }

        for (let i = Number(props.rowNumber) + 1; i < newState.length; i++) {
          //Set new zulu takeoff time row after
          newState[i].toTimeZulu = DateTime.fromISO(
            newState[i - 1].landTimeZulu,
            {
              zone: 'UTC',
            }
          )
            .plus(
              Duration.fromObject({
                minutes: newState[i - 1].turnTime.minutes,
              })
            )
            .toUTC()
            .toISO({ includeOffset: false });

          //Set zulu land time row after
          newState[i].landTimeZulu = DateTime.fromISO(newState[i].toTimeZulu, {
            zone: 'UTC',
          })
            .plus(
              Duration.fromObject({
                minutes: newState[i].roundedEnrouteTime,
              })
            )
            .toUTC()
            .toISO({ includeOffset: false });

          //Set new local takeoff time row after
          newState[i].toTime = DateTime.fromISO(newState[i].toTimeZulu, {
            zone: 'UTC',
          })
            .setZone(newState[i].podTimeZone)
            .toISO({ includeOffset: false });

          //Set local land time row after
          newState[i].landTime = DateTime.fromISO(newState[i].landTimeZulu, {
            zone: 'UTC',
          })
            .setZone(newState[i].poaTimeZone)
            .toISO({ includeOffset: false });
        }

        return [...newState];
      });
    }
  };

  const earlyTakeoff = () => {
    //Checks to see if local takeoff time is before 0900 which is abnormal and may affect crew day
    let early = false;
    if (
      Number(
        DateTime.fromISO(
          missionInWork[props.rowNumber].toTime.split('.')[0]
        ).toFormat('HHmm')
      ) < Number('0900')
    ) {
      early = true;
    }
    return early;
  };

  return (
    <Grid container direction='row'>
      <Grid item>
        <KeyboardDateTimePicker
          id='podTimeL'
          label='Takeoff time local'
          ampm={false}
          format='MM/dd/yyyy HH:mm'
          InputLabelProps={{
            shrink: true,
          }}
          value={DateTime.fromISO(
            missionInWork[props.rowNumber].toTime.split('.')[0]
          )}
          onChange={(e) => {
            handleDateChange(e);
          }}
          style={{ backgroundColor: earlyTakeoff() ? 'red' : '' }}
        />
      </Grid>
    </Grid>
  );
};
