import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import '../App.css';
import { ItineraryTableData } from './ItineraryTableData';

export const ItineraryTable = (props) => {
  return (
    <>
      <Table
        size='small'
        style={{
          backgroundColor: 'azure',
          marginTop: '15px',
          width: '95%',
          marginLeft: 'auto',
          marginRight: 'auto',
          outline: '3px solid steelblue',
        }}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Leg</TableCell>
            <TableCell align='center'>Dep. Date</TableCell>
            <TableCell align='center'>POD</TableCell>
            <TableCell align='center'>Dep. Time Local</TableCell>
            <TableCell align='center'>Dep. Time Zulu</TableCell>
            <TableCell align='center'>POA</TableCell>
            <Hidden lgDown>
              <TableCell align='center'>Arr. Date</TableCell>
            </Hidden>
            <TableCell align='center'>Arr. Time Local</TableCell>
            <TableCell align='center'>Arr. Time Zulu</TableCell>
            <TableCell align='center'>Leg Time</TableCell>
            <TableCell align='center'>Crew Day</TableCell>
            <TableCell align='center'>On Deck Time</TableCell>
            <TableCell align='center'>RON/CC</TableCell>
            <Hidden lgDown>
              <TableCell align='center'>Route ID</TableCell>
            </Hidden>
            <Hidden mdDown>
              <TableCell align='center'>Skyvector Link</TableCell>
            </Hidden>
            <TableCell align='center'>Google Maps Link</TableCell>
            <Hidden mdDown>
              <TableCell align='center'>Airlifts</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          <ItineraryTableData {...props} />
        </TableBody>
      </Table>
    </>
  );
};
