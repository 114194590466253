import he from 'he';
import produce from 'immer';
import { default as React, Suspense } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from 'react-query';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { handleErrors } from '../Errors/handleErrors';
import MyFallbackComponent from '../Errors/MyFallbackComponent';
import StandardLoadingFallback from '../Loading/StandardLoadingFallback';
import './table.css';

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  row,
  updateMyData,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    if (initialValue !== value) {
      updateMyData(index, id, value, row.original.id);
    }
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (initialValue && initialValue.length < 20) {
    return (
      <input
        style={{ width: '95%' }}
        className='cellinput'
        value={he.decode(value || '')}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }

  return (
    <textarea
      style={{ width: '95%' }}
      className='cellinput'
      value={he.decode(value || '')}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

const NoEditCell = ({ value: initialValue }) => {
  return <p>{he.decode(initialValue || '')}</p>;
};

export default function TableWrapper({
  columnsArray,
  data,
  databaseName,
  numberOfRowsToDisplay,
  pagination,
  queryKey,
  rowsNotToEdit,
}) {
  const columns = React.useMemo(() => [...columnsArray]);

  const queryClient = useQueryClient();

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value, id) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);

    queryClient.setQueryData(queryKey, (old) => {
      return produce(old, (draft) => {
        const index = draft.findIndex((entry) => entry.id === id);
        if (index !== -1) draft[index][columnId] = value;
      });
    });
    //requires action,  databaseName, note, column, id

    const dataUpdate = {
      action: 'update',
      databaseName: databaseName,
      note: value,
      column: columnId,
      id: id,
      name: localStorage.getItem('userName'),
    };
    return fetch('/db/updateDB.php', {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify(dataUpdate),
    }).then(handleErrors);
  };

  //const [data, setData] = React.useState(airports);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  return (
    <Suspense fallback={<StandardLoadingFallback />}>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <Table
          columns={columns}
          data={data}
          numberOfRowsToDisplay={numberOfRowsToDisplay}
          skipPageReset={skipPageReset}
          pagination={pagination}
          updateMyData={updateMyData}
          databaseName={databaseName}
          queryKey={queryKey}
          rowsNotToEdit={rowsNotToEdit}
        />
      </ErrorBoundary>
    </Suspense>
  );
}

function Table({
  columns,
  data,
  numberOfRowsToDisplay,
  updateMyData,
  pagination,
  skipPageReset,
  databaseName,
  queryKey,
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    (props) => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      Cell: EditableCell,
      CellNoEdit: NoEditCell,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows.slice(0, numberOfRowsToDisplay);

  // Render the UI for your table
  return (
    <div style={{ textAlign: 'center', display: 'block', overflow: 'auto' }}>
      {pagination === true ? (
        <div className='pagination center' class='justify-items-center my-2'>
          <button
            class='
            bg-blue-500 hover:bg-blue-700 text-white  py-2 px-2 rounded
            '
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button
            class='
            bg-blue-500 hover:bg-blue-700 text-white  py-2 px-2 rounded
            '
            onClick={() => previousPage()}
            disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button
            class='
            bg-blue-500 hover:bg-blue-700 text-white  py-2 px-2 rounded
            '
            onClick={() => nextPage()}
            disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button
            class='
            bg-blue-500 hover:bg-blue-700 text-white  py-2 px-2 rounded
            '
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <span class='text-lg'>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span class='text-lg'>
            | Go to page:{' '}
            <input
              class='text-lg'
              type='number'
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            class='
          bg-blue-500 hover:bg-blue-700 text-white  py-2 px-2 rounded
          '
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <div class='justify-items-center py-2'>
        <span class='text-lg text-red-500'>
          Table not yet set up for mobile screens
        </span>
      </div>
      <div
        className='center'
        style={{ textAlign: 'center', display: 'block', overflow: 'auto' }}>
        <table {...getTableProps()} class=' border-black'>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    {...column.getHeaderProps()}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                      },
                    })}>
                    {column.render('Header')}
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {pagination === true
              ? page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        if (cell.column.edit === false) {
                          return (
                            <td
                              style={cell.column.style}
                              {...cell.getCellProps({
                                style: {
                                  minWidth: cell.column.minWidth,
                                  maxWidth: cell.column.maxWidth,
                                },
                              })}>
                              {cell.render('CellNoEdit')}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              style={cell.column.style}
                              {...cell.getCellProps({
                                style: {
                                  minWidth: cell.column.minWidth,
                                  maxWidth: cell.column.maxWidth,
                                },
                              })}>
                              {cell.render('Cell')}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })
              : firstPageRows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        console.log(cell);
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      style={{ width: '95%', backgroundColor: 'beige' }}
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const addRow = async (databaseName) => {
  const dataUpdate = {
    action: 'add',
    databaseName: databaseName,
  };
  const response = await fetch('/db/updateDB.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(dataUpdate),
  });
  if (response.ok !== true) {
    window.alert('Problem adding row');
  }
};
