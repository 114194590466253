import { KeyboardDateTimePicker } from '@material-ui/pickers';
import React, { useContext } from 'react';
import '../App.css';
import { MissionInWork } from '../MissionGeneratorStore';

const { DateTime, Duration } = require('luxon');

export const TakeOffZulu = ({ props }) => {
  const [missionInWork, setmissionInWork] = useContext(MissionInWork);

  const handleDateChange = (e) => {
    if (e.invalid === null) {
      setmissionInWork((prevState) => {
        const newState = [...prevState];

        //Set local takeoff of this row
        newState[props.rowNumber].toTimeZulu = e.toISO().split('.')[0];

        //Set zulu land time of this row
        newState[props.rowNumber].landTimeZulu = DateTime.fromISO(
          newState[props.rowNumber].toTimeZulu,
          { zone: 'UTC' }
        )
          .plus(
            Duration.fromObject({
              minutes: newState[props.rowNumber].roundedEnrouteTime,
            })
          )
          .toUTC()
          .toISO({ includeOffset: false });

        //Set zulu takeoff of this row
        newState[props.rowNumber].toTime = DateTime.fromISO(
          newState[props.rowNumber].toTimeZulu,
          {
            zone: 'UTC',
          }
        )
          .setZone(newState[props.rowNumber].podTimeZone)
          .toISO({ includeOffset: false });

        //Set local land time of this row
        newState[props.rowNumber].landTime = DateTime.fromISO(
          newState[props.rowNumber].landTimeZulu,
          { zone: 'UTC' }
        )
          .setZone(newState[props.rowNumber].poaTimeZone)
          .toISO({ includeOffset: false });

        //Adjust times in the rows before
        for (let i = props.rowNumber - 1; i >= 0; i--) {
          //Set zulu land time row before
          newState[i].landTimeZulu = DateTime.fromISO(
            newState[i + 1].toTimeZulu,
            {
              zone: 'UTC',
            }
          )
            .minus(
              Duration.fromObject({
                minutes: newState[i].turnTime.minutes,
              })
            )
            .toUTC()
            .toISO({ includeOffset: false });

          //Set zulu takeoff time row before
          newState[i].toTimeZulu = DateTime.fromISO(newState[i].landTimeZulu, {
            zone: 'UTC',
          })
            .minus(
              Duration.fromObject({
                minutes: newState[i].roundedEnrouteTime,
              })
            )
            .toUTC()
            .toISO({ includeOffset: false });

          //Set local landtime in row before
          newState[i].landTime = DateTime.fromISO(newState[i].landTimeZulu, {
            zone: 'UTC',
          })
            .setZone(newState[i].poaTimeZone)
            .toISO({ includeOffset: false });

          //Set local takeoff time row before
          newState[i].toTime = DateTime.fromISO(newState[i].toTimeZulu, {
            zone: 'UTC',
          })
            .setZone(newState[i].podTimeZone)
            .toISO({ includeOffset: false });
        }

        for (let i = Number(props.rowNumber) + 1; i < newState.length; i++) {
          //Set new zulu takeoff time row after
          newState[i].toTimeZulu = DateTime.fromISO(
            newState[i - 1].landTimeZulu,
            {
              zone: 'UTC',
            }
          )
            .plus(
              Duration.fromObject({
                minutes: newState[i - 1].turnTime.minutes,
              })
            )
            .toUTC()
            .toISO({ includeOffset: false });

          //Set zulu land time row after
          newState[i].landTimeZulu = DateTime.fromISO(newState[i].toTimeZulu, {
            zone: 'UTC',
          })
            .plus(
              Duration.fromObject({
                minutes: newState[i].roundedEnrouteTime,
              })
            )
            .toUTC()
            .toISO({ includeOffset: false });

          //Set new local takeoff time row after
          newState[i].toTime = DateTime.fromISO(newState[i].toTimeZulu, {
            zone: 'UTC',
          })
            .setZone(newState[i].podTimeZone)
            .toISO({ includeOffset: false });

          //Set local land time row after
          newState[i].landTime = DateTime.fromISO(newState[i].landTimeZulu, {
            zone: 'UTC',
          })
            .setZone(newState[i].poaTimeZone)
            .toISO({ includeOffset: false });
        }

        return [...newState];
      });
    }
  };

  return (
    <KeyboardDateTimePicker
      id='podTimeZ'
      label='Takeoff time zulu'
      ampm={false}
      format='MM/dd/yyyy HH:mm'
      InputLabelProps={{
        shrink: true,
      }}
      onLoad={(e) => {
        e.preventDefault();
        const val = e.target.value;
        setmissionInWork((prevState) => {
          const newState = [...prevState];
          newState[0].testTime = val;
        });
      }}
      value={DateTime.fromISO(
        missionInWork[props.rowNumber].toTimeZulu.split('.')[0]
      )}
      onChange={(e) => {
        handleDateChange(e);
      }}
    />
  );
};
