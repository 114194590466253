export const permissions = [
  { page: 'scheduleDelete', people: [] },
  { page: 'schedule_OfficeNotes', people: ['admin', 'dh'] },
  { page: 'schedule_DeleteButton', people: ['admin', 'dh', 'fw'] },
  { page: 'schedule_EnteredBy', people: ['admin', 'dh'] },
];

export function checkUser(userType, page) {
  //returns true if user is on list of type, false else
  let validated = false;
  let output = permissions.find((elem) => elem.page === page);
  if (output && output.people.includes(userType)) {
    validated = true;
  }

  return validated;
}
