import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';
import useRoutes from '../../Utils/Hooks/useRoutes';
import { MissionInWork } from '../MissionGeneratorStore';
import { addTimes } from './addTimes';
import { FirstColumn } from './FirstColumn';
import { LegColumn } from './LegColumn';

export function DisplayMissionModal({
  missionDataToImport,
  setmissionDataToImport,
  setOpenMissionModal,
  setheaderValues,
  headerValues,
}) {
  const [, setmissionInWork] = useContext(MissionInWork);
  const { data: routes } = useRoutes(missionDataToImport.type);
  function setExportData() {
    if (!missionDataToImport || missionDataToImport.length === 0) {
      alert('Nothing to import');
    } else {
      let copiedHeaderValues = { ...headerValues };
      copiedHeaderValues.buno = missionDataToImport.buno;
      copiedHeaderValues.type = missionDataToImport.aircraftType;
      setheaderValues(copiedHeaderValues);

      var newMissionArray = [];
      missionDataToImport.legs.forEach((leg) => {
        let routeData = {};
        routeData = routes.find((route) => route.id === leg.selected);

        if (routeData) {
          if (leg.RON === true) {
            routeData.RON = true;
          }
          newMissionArray.push({ ...routeData });
        } else {
          //Route not found in database
        }
      });

      var copynewMissionArray = addTimes(newMissionArray, missionDataToImport);
    }
    setmissionInWork([...copynewMissionArray]);
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        direction='row'
        justify='center'
        alignItems='flex-start'>
        <FirstColumn
          missionDataToImport={missionDataToImport}
          setOpenMissionModal={setOpenMissionModal}
          setExportData={setExportData}
        />
        <Grid
          item
          container
          direction='row'
          md={9}
          spacing={1}
          justify='center'
          style={{ overflowx: 'scroll' }}>
          {missionDataToImport.legs.map((leg, ind) => {
            return (
              <React.Fragment key={'frag' + ind}>
                <LegColumn
                  key={'LegCol' + ind}
                  leg={leg}
                  legNumber={ind}
                  //width={12 / missionDataToImport.legs.length}
                  width={3}
                  missionDataToImport={missionDataToImport}
                  setmissionDataToImport={setmissionDataToImport}
                />
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
