import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import useRoutes from '../../Utils/Hooks/useRoutes';
import { AirliftChipImport } from './AirliftChipImport';
import { RouteChipsImport } from './RouteChipsImport';

const { DateTime } = require('luxon');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: 'lightblue',
  },
}));

export const LegColumn = ({
  leg,
  legNumber,
  missionDataToImport,
  setmissionDataToImport,
  width,
}) => {
  const { data: routes } = useRoutes(missionDataToImport.type);
  const [possibleRoutes, setpossibleRoutes] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    //Search through route database and return matches

    if (routes && routes.length !== 0) {
      let pr = routes.filter(
        (elem) =>
          elem.pod === leg.pod &&
          elem.poa === leg.poa &&
          DateTime.fromSQL(elem.valdate) > DateTime.local().minus({ days: 90 })
      );

      if (pr && pr.length !== 0) {
        pr.sort((a, b) => b.id - a.id);
        pr[0].selected = true;
        let copy = { ...missionDataToImport };
        copy.legs[leg.legNumber].selected = pr[0].id;
        setmissionDataToImport(copy);
        setpossibleRoutes(pr);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes, leg]);

  const handleChipDelete = (name) => {
    let copy = { ...missionDataToImport };
    const index = copy.legs[legNumber].airlifts.findIndex(
      (al) => al.airliftName === name
    );
    if (index !== -1) copy.legs[legNumber].airlifts.splice(index, 1);

    setmissionDataToImport(copy);
  };

  return (
    <Grid
      item
      container
      direction='column'
      xs={width}
      spacing={1}
      alignItems='center'
      style={{ marginBottom: '1vh' }}>
      <Paper className={classes.paper}>
        <Grid item>
          <Typography variant='h5' color='initial'>
            {leg.pod} - {leg.poa}
            {leg.RON === true ? '/R' : null}
            {leg.CC === true ? '/C' : null}
          </Typography>
        </Grid>
        <Grid item>
          {possibleRoutes && possibleRoutes.length === 0 && (
            <Typography variant='h6' color='initial'>
              No routes found
            </Typography>
          )}
          {possibleRoutes &&
            possibleRoutes.map((elem, index) => {
              if (elem?.selected === true) {
                return (
                  <Grid item style={{ borderStyle: 'solid' }}>
                    <RouteChipsImport
                      key={'routeChipsImpSelected' + index}
                      route={elem}
                      setpossibleRoutes={setpossibleRoutes}
                      possibleRoutes={possibleRoutes}
                      missionDataToImport={missionDataToImport}
                      setmissionDataToImport={setmissionDataToImport}
                      leg={leg}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid item>
                    <RouteChipsImport
                      key={'routeChipsImp' + index}
                      route={elem}
                      setpossibleRoutes={setpossibleRoutes}
                      possibleRoutes={possibleRoutes}
                      missionDataToImport={missionDataToImport}
                      setmissionDataToImport={setmissionDataToImport}
                      leg={leg}
                    />
                  </Grid>
                );
              }
            })}
        </Grid>
        <Grid
          item
          container
          direction='column'
          spacing={1}
          style={{ marginTop: '2vh' }}>
          {leg.airlifts && leg.airlifts.length === 0 && (
            <Grid item>
              <Typography variant='h6' color='initial'>
                No airlifts on this leg
              </Typography>
            </Grid>
          )}
          {leg.airlifts &&
            leg.airlifts.map((elem, ind) => (
              <Grid item>
                <AirliftChipImport
                  key={ind + 'airliftChipsImport'}
                  props={elem}
                  name='test'
                  handleChipDelete={handleChipDelete}
                />
              </Grid>
            ))}
        </Grid>
      </Paper>
    </Grid>
  );
};
