import Button from '@material-ui/core/Button';
import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ErrorBoundary } from 'react-error-boundary';
import { useMutation, useQueryClient } from 'react-query';
import MyFallbackComponent from '../Errors/MyFallbackComponentRedirect';
import useMissionDataPartial from '../Utils/Hooks/useMissionDataPartial';
import { MissionInWork } from './MissionGeneratorStore';
import { setHistoryMissionGen } from './setHistoryMissionGen';
const { DateTime } = require('luxon');

const sendData = async ({
  fromLR,
  overwrite,
  overwriteID,
  fileAwayMod,
  fileAwayMissionID,
  missionInWork,
  headerValues,
  history,
}) => {
  function parseEmailSet() {
    let emailSet = new Set();
    missionInWork.forEach((leg) => {
      if (leg.airlifts !== undefined) {
        leg.airlifts.forEach((lift) => {
          emailSet.add(lift.requestorEmail.trim().toLowerCase());
          let email_array = lift?.emails.split(';');
          email_array.forEach((email) => {
            let email_skinny = email.trim().toLowerCase();
            if (email_skinny.length !== 0) {
              emailSet.add(email_skinny);
            }
          });
        });
      }
    });
    return Array.from(emailSet);
  }

  const dataToSave = {
    eventID:
      fromLR === undefined
        ? undefined
        : fromLR.eventID === null
        ? undefined
        : fromLR.eventID,
    overwrite: overwrite, //bool, If this is straight overwrite
    overwriteID: overwriteID, //Mission ID or ''
    fileAwayMod: fileAwayMod, //Will be true if this is a new mod on existing mission and need to move old mod to 'missionsOldMods'
    fileAwayMissionID: fileAwayMissionID, //MissionID to move to missionsOldMods
    missionInfo: missionInWork,
    typeAC: headerValues.type,
    callsign: headerValues.callsign,
    mod: headerValues.mod,
    updateNumber: headerValues.updateNumber,
    status: headerValues.stage,
    buno: headerValues.buno,
    emailString: parseEmailSet().join(';'),
    enteredBy:
      localStorage.getItem('userName') + ';' + DateTime.local().toISO(),
    cnv:
      headerValues.callsign +
      '-' +
      DateTime.fromISO(missionInWork[0]?.toTime).toFormat('LL') +
      '-' +
      headerValues.mod,
    history: history,
    name: localStorage.getItem('userName'),
  };

  const resp = await fetch('/db/SMGzxcvklhjlkhjh.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(dataToSave),
  });
  if (!resp.ok) {
    throw new Error(`HTTP error! status: ${resp.status}`);
  }
};

export default function SaveMissionButton({
  fromLR,
  headerValues,
  setcurrentExistingMission,
  setheaderValues,
  setOpen,
}) {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const [allRoutesValid, setallRoutesValid] = useState(true);
  const [saving, setSaving] = useState(false);
  const [missionInWork, setmissionInWork] = useContext(MissionInWork);
  const { data: missionSet } = useMissionDataPartial();
  const mutation = useMutation(
    async ({
      fromLR,
      overwrite,
      overwriteID,
      fileAwayMod,
      fileAwayMissionID,
      missionInWork,
      headerValues,
      history,
    }) => {
      await sendData({
        fromLR,
        overwrite,
        overwriteID,
        fileAwayMod,
        fileAwayMissionID,
        missionInWork,
        headerValues,
        history,
      });
    },
    {
      onMutate: async () => {
        setSaving(true);
        await queryClient.cancelQueries(['missionDataPartial']);
        const previousMissionSet = queryClient.getQueryData([
          'missionDataPartial',
        ]);
        const previousMissionInWork = missionInWork;
        const previousHeaderValues = headerValues;
        setcurrentExistingMission({});
        setmissionInWork([]);
        setheaderValues({
          ...headerValues,
          mod: 0,
          updateNumber: 0,
          buno: '',
          callsign: '',
          cnv: '-',
        });

        return [
          previousMissionSet,
          previousMissionInWork,
          previousHeaderValues,
        ];
      },
      onError: (err, variables, previousValue) => {
        setSaving(false);
        queryClient.setQueryData(['missionDataPartial'], previousValue[0]);
        setmissionInWork(previousValue[1]);
        setheaderValues(previousValue[2]);
        alert.error(
          'Mission not saved, try again or contact Administrator. Old data will be shown.'
        );
        console.log('onError', err);
      },
      onSuccess: (data) => {
        setSaving(false);
        alert.success('Mission saved! 🎉');
        queryClient.invalidateQueries(['missionDataOldMods']);
        queryClient.invalidateQueries(['missionDataPartial']);
        queryClient.invalidateQueries(['airliftSet']);
      },
    }
  );

  async function saveMission(missionSet, headerValues, missionInWork) {
    //Check if this is mod and return bool and missionID of mission to move to missionsOldMods
    const [fileAwayMod, fileAwayMissionID] = await checkNewMod(
      missionSet,
      headerValues,
      missionInWork
    );

    //Check if this is a straight overwrite and return bool and mission ID to overwrite

    const [overwrite, overwriteID] = await checkOverwrite(
      missionSet,
      headerValues,
      missionInWork
    );

    if (overwrite === true || fileAwayMod === true) {
      setOpen(true);
    }

    const history = await setHistoryMissionGen(headerValues, missionInWork);

    if (overwrite === true) {
      confirmAlert({
        title: 'Confirm overwrite',
        message:
          'A mission with this callsign and mod or update already exists in the database.  Do you want to overwrite this mission?',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>
              mutation.mutate({
                fromLR,
                overwrite,
                overwriteID,
                fileAwayMod,
                fileAwayMissionID,
                missionInWork,
                headerValues,
                history,
              }),
          },
          {
            label: 'No',
            onClick: () => alert.show('No mission saved'),
          },
        ],
      });
    } else {
      mutation.mutate({
        fromLR,
        overwrite,
        overwriteID,
        fileAwayMod,
        fileAwayMissionID,
        missionInWork,
        headerValues,
        history,
      });
    }
  }

  useEffect(() => {
    let valid1 = true;
    let outcome = true;
    missionInWork.forEach((element) => {
      if (
        DateTime.fromSQL(element?.valdate) <
        DateTime.local().minus({ days: 31 })
      ) {
        valid1 = false;
      } else {
        valid1 = true;
      }
      outcome = valid1 && outcome;
    });
    setallRoutesValid(outcome);
    return () => {
      setallRoutesValid(true);
    };
  }, [missionInWork]);

  return (
    <>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <Button
          color={mutation.isLoading === false ? 'primary' : 'secondary'}
          variant='contained'
          size='small'
          id='SaveMissionButton'
          disabled={
            saving === true ||
            allRoutesValid === false ||
            headerValues.buno === '' ||
            headerValues.callsign === '' ||
            headerValues.buno === null ||
            (headerValues.mod === null && headerValues.updateNumber === null) ||
            (headerValues.mod === '' && headerValues.updateNumber === '') ||
            (headerValues.mod === undefined &&
              headerValues.updateNumber === undefined)
          }
          onClick={async (e) => {
            e.preventDefault();
            if (headerValues.callsign === '') {
              alert.show('Enter a callsign');
            } else if (missionInWork.length === 0) {
              alert.show('Enter a route');
            } else {
              saveMission(missionSet, headerValues, missionInWork);
            }
          }}>
          {saving === false ? 'Save/Overwrite' : 'Saving...'}
        </Button>
      </ErrorBoundary>
    </>
  );
}

function checkNewMod(missionSet, headerValues, missionInWork) {
  //Checks to see if this is a new mod on existing mission and, if so, will return true so that we can push the old mod to 'missionsOldMods'
  let checkNewMod = false;
  let fileAwayMissionID = '';
  let mod = 0;
  headerValues.mod ? (mod = headerValues.mod) : (mod = 0);
  let update = 0;
  headerValues.updateNumber
    ? (update = headerValues.updateNumber)
    : (update = 0);
  switch (headerValues.stage) {
    case 'JALIS':
      if (
        missionSet.some(
          (elem) =>
            elem.cnv ===
            headerValues.callsign +
              '-' +
              DateTime.fromISO(missionInWork[0]?.toTime).toFormat('LL') +
              '-' +
              String(Number(mod) - 1)
        )
      ) {
        checkNewMod = true;
        let oldMission = missionSet.find((elem) => {
          return (
            elem.cnv ===
            headerValues.callsign +
              '-' +
              DateTime.fromISO(missionInWork[0]?.toTime).toFormat('LL') +
              '-' +
              String(Number(mod) - 1)
          );
        });
        if (oldMission && oldMission !== null && oldMission !== {}) {
          fileAwayMissionID = oldMission.missionID;
        }
      }
      break;

    case 'ITS':
      //Check to see if this is new ITS update
      if (
        missionSet.some((elem) => {
          return (
            elem.cnv ===
              headerValues.callsign +
                '-' +
                DateTime.fromISO(missionInWork[0]?.toTime).toFormat('LL') +
                '-' +
                String(Number(mod)) &&
            Number(elem.updateNumber) === Number(update) - 1
          );
        })
      ) {
        checkNewMod = true;
        let oldMission = missionSet.find((elem) => {
          return (
            elem.cnv ===
              headerValues.callsign +
                '-' +
                DateTime.fromISO(missionInWork[0]?.toTime).toFormat('LL') +
                '-' +
                String(Number(mod)) &&
            Number(elem.updateNumber) === Number(update) - 1
          );
        });
        if (oldMission && oldMission !== null && oldMission !== {}) {
          fileAwayMissionID = oldMission.missionID;
        }
      }
      break;

    default:
      break;
  }
  return [checkNewMod, fileAwayMissionID];
}

function checkOverwrite(missionSet, headerValues, missionInWork) {
  let overwrite = false;
  let overwriteID = '';
  let mod = 0;
  headerValues.mod ? (mod = headerValues.mod) : (mod = 0);
  let update = 0;
  headerValues.updateNumber
    ? (update = headerValues.updateNumber)
    : (update = 0);
  switch (headerValues.stage) {
    case 'JALIS':
      if (
        missionSet.some(
          (elem) =>
            elem.cnv ===
            headerValues.callsign +
              '-' +
              DateTime.fromISO(missionInWork[0]?.toTime).toFormat('LL') +
              '-' +
              mod
        )
      ) {
        let entryToDelete = missionSet.find(
          (elem) =>
            elem.cnv ===
            headerValues.callsign +
              '-' +
              DateTime.fromISO(missionInWork[0]?.toTime).toFormat('LL') +
              '-' +
              mod
        );

        overwrite = true;
        overwriteID = entryToDelete.missionID;
      }
      break;
    case 'ITS':
      //Check to see if this is new ITS overwrite
      if (
        missionSet.some((elem) => {
          return (
            elem.cnv ===
              headerValues.callsign +
                '-' +
                DateTime.fromISO(missionInWork[0]?.toTime).toFormat('LL') +
                '-' +
                String(Number(mod)) &&
            Number(elem.updateNumber) === Number(update)
          );
        })
      ) {
        overwrite = true;
        let entryToDelete = missionSet.find((elem) => {
          return (
            elem.cnv ===
              headerValues.callsign +
                '-' +
                DateTime.fromISO(missionInWork[0]?.toTime).toFormat('LL') +
                '-' +
                String(Number(mod)) &&
            Number(elem.updateNumber) === Number(update)
          );
        });

        overwrite = true;
        overwriteID = entryToDelete.missionID;
      }
      break;

    default:
      break;
  }
  return [overwrite, overwriteID];
}
