import { Button, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import he from 'he';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useMutation, useQueryClient } from 'react-query';
import '../App.css';
const { DateTime } = require('luxon');

//ContactCheck (functional) is main component.  Take airlift info and prints table via TableOutut (functional with hooks)

export default function ContactEntry({ airliftData }) {
  //const input = props.airliftData;
  const [, setValidEntry] = useState(false);
  const [PAX, setPAX] = useState(he.decode(airliftData.vPAX || ''));
  const [cargo, setCargo] = useState(he.decode(airliftData.vCargo || ''));
  const [POC, setPOC] = useState(
    he.unescape(he.decode(airliftData.vPOC || ''))
  );
  const [notes, setNotes] = useState(
    he.unescape(he.decode(airliftData.vNotes || ''))
  );
  const [phone, setPhone] = useState(
    he.unescape(he.decode(airliftData.vPhone || ''))
  );
  const [complete] = useState(
    airliftData.vComplete === false ||
      airliftData.vComplete === null ||
      airliftData.vComplete === '0' ||
      airliftData.vComplete === ''
      ? false
      : true
  );
  const [updated, setUpdated] = useState(false);
  const [changed, setchanged] = useState(false);
  const alertHook = useAlert();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ e, PAX, cargo, POC, notes, phone, complete, airliftData }) => {
      await sendValues({
        e,
        PAX,
        cargo,
        POC,
        notes,
        phone,
        complete,
        airliftData,
      });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['airliftSet']);
        setUpdated(true);

        const previousAirliftSet = queryClient.getQueryData(['airliftSet']);
        const prevPAX = PAX;
        const prevCargo = cargo;
        const prevPOC = POC;
        const prevNotes = notes;
        const prevPhone = phone;
        const prevComplete = complete;

        return [
          previousAirliftSet,
          prevPAX,
          prevCargo,
          prevPOC,
          prevNotes,
          prevPhone,
          prevComplete,
        ];
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(['airliftSet'], previousValue[0]);
        setUpdated(false);
        setPAX(previousValue[1]);
        setCargo(previousValue[2]);
        setPOC(previousValue[3]);
        setNotes(previousValue[4]);
        setPhone(previousValue[5]);
        alert(
          'Contact info not saved.  Previous data (unsaved) will be displayed'
        );
      },
      // After success or failure, refetch the todos query
      onSuccess: () => {
        queryClient.fetchQuery(['airliftSet']);
        setUpdated(true);
        setchanged(false);
        alertHook.success('Contact info Saved');
      },
    }
  );

  const tableStyle = {
    marginTop: '10px',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'lightyellow',
    outlineStyle: 'solid',
    outlineColor: 'black',
  };

  useEffect(() => {
    if (PAX !== '' && cargo !== '' && POC !== '' && phone !== '') {
      setValidEntry(true);
    } else {
      setValidEntry(false);
    }
  }, [PAX, cargo, POC, phone]);

  return (
    <Table style={tableStyle}>
      <TableHead>
        <TableRow>
          <TableCell align='center'>Airlift Name</TableCell>
          <TableCell align='center'>POD</TableCell>
          <TableCell align='center'>POA</TableCell>
          <TableCell align='center'>Contact Name</TableCell>
          <TableCell align='center'>Contact Phone</TableCell>
          <TableCell align='center'>PAX</TableCell>
          <TableCell align='center'>Cargo</TableCell>
          <TableCell align='center'>Notes</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align='center'>{airliftData.airliftName}</TableCell>
          <TableCell align='center'>{airliftData.POD}</TableCell>
          <TableCell align='center'>{airliftData.POA}</TableCell>
          <TableCell align='center'>
            {he.decode(airliftData.liftRequestor || '')}
          </TableCell>
          <TableCell align='center'>{airliftData.requestorPhone}</TableCell>
          <TableCell align='center'>{airliftData.PAX}</TableCell>
          <TableCell align='center'>{airliftData.cargoWeight}</TableCell>
          <TableCell align='center'>
            {he.decode(airliftData.notes || '')}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2} align='center'>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              value='Submit'
              size='small'
              onClick={(e) => {
                e.preventDefault();
                mutation.mutate({
                  e,
                  PAX,
                  cargo,
                  POC,
                  notes,
                  phone,
                  complete,
                  airliftData,
                });
              }}>
              Save
            </Button>
          </TableCell>
          <TableCell colSpan={1} align='center' style={{ maxWidth: '7vw' }}>
            {airliftData.vUpdatedBy
              ? `Updated By: ${airliftData.vUpdatedBy}`
              : ''}
            {airliftData.vUpdateTime
              ? ` at ${DateTime.fromISO(airliftData.vUpdateTime).toLocaleString(
                  DateTime.DATETIME_FULL
                )}`
              : ''}
          </TableCell>
          <TableCell align='center'>
            <TextField
              variant='outlined'
              id='POC'
              name='POC'
              label='Point of Contact'
              placeholder='Point of Contact'
              value={POC == null ? '' : he.decode(POC)}
              style={
                updated === true
                  ? { backgroundColor: 'lightgreen', width: '90%' }
                  : changed === true
                  ? { backgroundColor: 'red', width: '90%' }
                  : { backgroundColor: 'white', width: '90%' }
              }
              onChange={(e) => {
                e.preventDefault();
                setUpdated(false);
                setPOC(he.encode(e.target.value));
                setchanged(true);
              }}
            />
          </TableCell>
          <TableCell align='center'>
            <PhoneInput
              id='pprContact'
              placeholder='POC Phone (incl country code)(NO DSN!)'
              defaultCountry='IT'
              style={
                updated === true
                  ? { backgroundColor: 'lightgreen', maxWidth: '90%' }
                  : phone !== airliftData.vPhone
                  ? { backgroundColor: 'red', maxWidth: '90%' }
                  : { backgroundColor: '', maxWidth: '90%' }
              }
              international
              withCountryCallingCode
              value={phone == null ? '' : phone}
              onChange={(e) => {
                setUpdated(false);
                setPhone(e);
              }}
              /*           countrySelectComponent={CountrySelect} */
            />
          </TableCell>
          <TableCell align='center'>
            <TextField
              variant='outlined'
              type='number'
              label='PAX'
              placeholder='PAX count'
              value={PAX == null ? '' : PAX}
              style={
                updated === true
                  ? { backgroundColor: 'lightgreen', width: '90%' }
                  : { backgroundColor: 'white', width: '90%' }
              }
              onChange={(e) => {
                setPAX(e.target.value);
                e.target.value !== ''
                  ? (e.target.style.backgroundColor = 'red')
                  : (e.target.style.backgroundColor = '');
              }}
            />
          </TableCell>
          <TableCell align='center'>
            <TextField
              variant='outlined'
              type='number'
              placeholder='Cargo weight'
              value={cargo == null ? '' : cargo}
              style={
                updated === true
                  ? { backgroundColor: 'lightgreen', width: '90%' }
                  : { backgroundColor: 'white', width: '90%' }
              }
              onChange={(e) => {
                setCargo(e.target.value);
                e.target.value !== ''
                  ? (e.target.style.backgroundColor = 'red')
                  : (e.target.style.backgroundColor = '');
              }}
            />
          </TableCell>
          <TableCell align='center'>
            <textarea
              placeholder='Notes'
              value={notes == null ? '' : he.decode(notes)}
              style={
                updated === true
                  ? { backgroundColor: 'lightgreen', width: '90%' }
                  : { backgroundColor: '', width: '90%' }
              }
              onChange={(e) => {
                setNotes(he.encode(e.target.value || ''));
                e.target.value !== ''
                  ? (e.target.style.backgroundColor = 'red')
                  : (e.target.style.backgroundColor = '');
              }}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

async function sendValues({
  e,
  PAX,
  cargo,
  POC,
  notes,
  phone,
  complete,
  airliftData,
}) {
  const dataUpdate = {
    airliftID: airliftData.id,
    vPAX: he.encode(PAX.trim()),
    vCargo: he.encode(cargo.trim()),
    vPOC: he.encode(POC.trim()),
    vNotes: he.encode(notes.trim()),
    vPhone: he.encode(phone.trim()),
    vUpdateTime: DateTime.utc(),
    vComplete: complete,
    name: localStorage.getItem('userName'),
  };
  const data = await fetch('/db/updateContact.schedule.db.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(dataUpdate),
  });

  if (!data.ok) {
    throw Error(data.statusText);
  }
}
