import { useQuery } from 'react-query';

const getRoutes = async (aircraftType) => {
  const data = await fetch('/db/Rlhuiohlkj.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify({ typeAircraft: aircraftType, limit: false }),
  });

  if (!data.ok) {
    throw Error(data.statusText);
  } else {
    return data.json();
  }
};

export default function useRoutes(aircraftType) {
  return useQuery(['routes', aircraftType], () => getRoutes(aircraftType), {
    staleTime: 10 * 60 * 1000,
    refetchInterval: 11 * 60 * 1000,
    placeholderData: [],
  });
}
