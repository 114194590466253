import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';
import './App.css';
import { MissionInWork } from './MissionGeneratorStore';
import MissionRow from './MissionRow';
import MissionRowEmpty from './MissionRowEmpty';

export default function MissionBuilder({
  aircraftType,
  smartTiming,
  setsmartTiming,
}) {
  const [missionInWork] = useContext(MissionInWork);

  return (
    <div style={{ height: '90vh', overflowY: 'scroll' }}>
      <Grid container justify='center' spacing={1}>
        <Grid item xs={12}>
          <MissionRowEmpty
            aircraftType={aircraftType}
            edit={true}
            id={Number(0)}
            key={'firstEmpty0'}
            name='First Row'
            type='first'
          />
        </Grid>
        {missionInWork?.map((element, index) => {
          if (index !== missionInWork.length - 1) {
            return (
              <React.Fragment key={index}>
                <Grid item xs={12}>
                  <MissionRow
                    edit={false}
                    info={element}
                    key={`${element.id}false`}
                    name={element.name}
                    rowNumber={index}
                    aircraftType={aircraftType}
                    style={{
                      backgroundColor: 'white',
                    }}
                    smartTiming={smartTiming}
                    setsmartTiming={setsmartTiming}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MissionRowEmpty
                    edit={true}
                    id={Number(index + 1)}
                    info={element}
                    key={`${element.id}`}
                    name={element.name + 'empty'}
                    aircraftType={aircraftType}
                    type='other'
                    style={{
                      backgroundColor: 'white',
                    }}
                  />
                </Grid>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                <Grid item xs={12}>
                  <MissionRow
                    edit={false}
                    info={element}
                    key={`${element.id}false`}
                    name={element.name}
                    rowNumber={index}
                    aircraftType={aircraftType}
                    style={{
                      backgroundColor: 'white',
                    }}
                    smartTiming={smartTiming}
                    setsmartTiming={setsmartTiming}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MissionRowEmpty
                    edit={true}
                    id={Number(index + 1)}
                    info={element}
                    key={`${element.id}`}
                    name={element.name + 'empty'}
                    aircraftType={aircraftType}
                    type='last'
                    style={{
                      backgroundColor: 'white',
                    }}
                  />
                </Grid>
              </React.Fragment>
            );
          }
        })}
      </Grid>
    </div>
  );
}
