import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import he from 'he';
import React, { useContext } from 'react';
import '../App.css';
import { MissionContext } from '../store';

export default function MissionNotes(prop) {
  const { missionObj } = useContext(MissionContext);
  const paperStyle = {
    padding: '15px',
    backgroundColor: 'lightyellow',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    outline: 'solid lightblue',
  };
  const divStyle = {
    padding: '10px',
  };

  return (
    <div style={divStyle}>
      <Paper style={paperStyle}>
        <Typography variant='h6'>Mission Notes</Typography>
        {missionObj.missionNotes === null ||
        missionObj.missionNotes === '' ||
        missionObj.missionNotes === 'undefined' ? (
          <Typography variant='body1'>No notes</Typography>
        ) : (
          <Typography variant='body1'>
            {he.decode(missionObj.missionNotes)}
          </Typography>
        )}
      </Paper>
    </div>
  );
}
