import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import useAirlifts from '../../../../Utils/Hooks/useAirlifts';
const { DateTime } = require('luxon');

export const ExportPPR = ({ data, airliftArr, buno }) => {
  const { data: airliftInformation } = useAirlifts();

  /*   useEffect(() => {
    if (airliftData !== undefined) {
       [onLoad, thruLoad, offLoad] = decodeAirlifts(
        data.number,
        airliftArr
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airliftData]); */

  const generatePPRstring = () => {
    const [onLoad, thruLoad, offLoad] = decodeAirlifts(data.number, airliftArr);

    let onLoadObj = [];
    let thruLoadObj = [];
    let offLoadObj = [];
    onLoad.forEach((lift) => {
      const onLoadObjT = airliftInformation.find((element) => {
        return element.airliftName === lift;
      });
      onLoadObj.push(onLoadObjT);
    });
    offLoad.forEach((lift) => {
      const offLoadObjT = airliftInformation.find((element) => {
        return element.airliftName === lift;
      });
      offLoadObj.push(offLoadObjT);
    });
    thruLoad.forEach((lift) => {
      const thruLoadObjT = airliftInformation.find((element) => {
        return element.airliftName === lift;
      });
      thruLoadObj.push(thruLoadObjT);
    });

    let onLoadStr = '';
    let onLoadPAXcount = 0;
    let onLoadCargocount = 0;
    let offLoadStr = '';
    let offLoadPAXcount = 0;
    let offLoadCargocount = 0;
    //let thruLoadPAXcount = 0;
    //let thruLoadCargocount = 0;
    //let thruLoadStr = '';
    let onLoadHaz = false;
    let offLoadHaz = false;
    //let thruLoadHaz = false;

    //Return array of HAZ details depending on the airfield PPR requirements
    let onLoadHazDetails = [];
    let offLoadHazDetails = [];
    //let thruLoadHazDetails = [];
    let DV = false;
    if (onLoadObj.length !== 0) {
      let PAXindex = onLoadObj.findIndex((lift) => lift.PAX !== '0');
      let cargoindex = onLoadObj.findIndex((lift) => lift.cargoWeight !== '0');
      onLoadStr += `${PAXindex === -1 ? '' : 'PAX onload'} ${
        cargoindex === -1 ? '' : 'Cargo onload'
      }`;
      onLoadObj.forEach((lift) => {
        onLoadPAXcount += Number(lift.PAX);
        onLoadCargocount += Number(lift.cargoWeight);
        if (lift.HAZ === '1') {
          onLoadHaz = true;
        }
        if (lift.DV === '1') {
          DV = true;
        }
      });
      debugger;
      onLoadHazDetails = parseHAZ(onLoadObj, data.airfieldICAO);
    }
    if (offLoadObj.length !== 0) {
      let PAXindex = offLoadObj.findIndex((lift) => lift.PAX !== '0');
      let cargoindex = offLoadObj.findIndex((lift) => lift.cargoWeight !== '0');
      offLoadStr += `${PAXindex === -1 ? '' : 'PAX offload'} ${
        cargoindex === -1 ? '' : 'Cargo offload'
      }`;
      offLoadObj.forEach((lift) => {
        offLoadPAXcount += Number(lift.PAX);
        offLoadCargocount += Number(lift.cargoWeight);
        if (lift.HAZ === '1') {
          offLoadHaz = true;
        }
        if (lift.DV === '1') {
          DV = true;
        }
      });
      offLoadHazDetails = parseHAZ(offLoadObj, data.airfieldICAO);
    }
    if (thruLoadObj.length !== 0) {
      /*     let PAXindex = thruLoadObj.findIndex((lift) => lift.PAX !== '0');
      let cargoindex = thruLoadObj.findIndex(
        (lift) => lift.cargoWeight !== '0'
      ); */
      /* thruLoadStr += `${PAXindex === -1 ? '' : 'PAX offload'} ${
        cargoindex === -1 ? '' : 'Cargo offload'
      }`;
      thruLoadObj.forEach((lift) => {
        thruLoadPAXcount += Number(lift.PAX);
        thruLoadCargocount += Number(lift.cargoWeight);
        if (lift.HAZ === '1') {
          thruLoadHaz = true;
        }
        if (lift.DV === '1') {
          DV = true;
        }
      });
      thruLoadHazDetails = parseHAZ(thruLoadObj, data.airfieldICAO); */
    }

    console.log(
      onLoadPAXcount,
      onLoadCargocount,
      offLoadPAXcount,
      offLoadCargocount,
      onLoadObj,
      thruLoadObj,
      offLoadObj
    );
    const requestorInfo = `FLIGHT WRITER / +39-335-774-4952 / M-NA-CTF-63AIRLOGOPS-PPR-GD@EU.NAVY.MIL`;

    let typeAC = '';
    let squadronContact,
      crew,
      squadron = '';

    switch (data.typeAircraft) {
      case 'c130routes':
        typeAC = 'C-130';
        squadronContact = 'DUTY PO / +39-335-743-5372 meddetjet@gmail.com';
        squadron = 'C-130 Detachment Sigonella';
        crew = '5';
        break;
      case 'c40routes':
        typeAC = 'C-40';
        squadronContact = 'TBD';
        squadron = 'C-40 Detachment Sigonella';
        crew = '5';
        break;
      case 'c26routes':
        typeAC = 'C-26';
        squadronContact = 'TBD';
        squadron = 'C-26, NAS Sigonella or NAS Naples';
        crew = '3';
        break;
      default:
        break;
    }

    let export_string = '';

    //TODO use the following to set up format for different field
    /*     switch (data.airfieldICAO) {
      case 'LGSA':
        export_string += `CNV ${data.pprID.split('-')[0]}\n${DateTime.fromISO(
          data.arrivalDateTime
        ).toFormat('ddLLLyyyy / HHmm')}Z\n${DateTime.fromISO(
          data.departureDateTime
        ).toFormat('ddLLLyyyy / HHmm')}Z\n${data.beforeAirfield}\n${
          data.nextAirfield
        }\n${typeAC}\n${
          data?.buno
        }\n${requestorInfo}\n${squadronContact}\n${squadron}\n${offLoadStr} ${onLoadStr}, Fuel\n`;
        break;
      default:
        break;
    } */
    export_string += `CNV ${data.pprID.split('-')[0]}\n${DateTime.fromISO(
      data.arrivalDateTime
    ).toFormat('ddLLLyyyy / HHmm')}Z\n${DateTime.fromISO(
      data.departureDateTime
    ).toFormat('ddLLLyyyy / HHmm')}Z\n${data.beforeAirfield}\n${
      data.nextAirfield
    }\n${typeAC}\n${buno}\n${requestorInfo}\n${squadronContact}\n${squadron}\n${offLoadStr} ${onLoadStr}, Fuel\n${crew}\n${offLoadPAXcount}\nNA\n${offLoadCargocount} ${
      offLoadHaz === true ? 'HAZMAT' : ''
    }\n${
      offLoadObj.length !== 0 ? 'OFFLOAD' : 'None'
    }\nNA\nNA\n${crew}\n${onLoadPAXcount}\nNA\n${onLoadCargocount} ${
      onLoadHaz === true ? 'HAZMAT' : ''
    }\n${onLoadObj.length !== 0 ? 'ONLOAD' : 'None'}\n${
      DV === true ? 'YES/CODE TBD/POSITION TBD' : 'NO'
    }\n${onLoadHaz === true ? `ONLOAD:${onLoadHazDetails}` : ''}${
      offLoadHaz === true ? `OFFLOAD:${offLoadHazDetails} ` : ''
    }\nNone`;

    navigator.clipboard.writeText(export_string);
    console.log(export_string);
  };

  return (
    <>
      <Tooltip title='PPR data will be pasted to clipboard.  Paste data to appropriate spot on PPR form.  Only for LGSA at the moment.'>
        <Button
          variant='contained'
          color='primary'
          onClick={(e) => {
            e.preventDefault();
            generatePPRstring();
          }}>
          Export PPR data
        </Button>
      </Tooltip>
    </>
  );
};

function decodeAirlifts(index, airliftArray) {
  /* [[a,b], [b, c],[c] ]  => Leg 1, Leg 2, Leg 3
Decode the airlifts on each leg :
thruLoad, onLoad, offLoad
*/

  const thruLoad = [];
  const onLoad = [];
  const offLoad = [];
  const length_airlift = airliftArray.length - 1;
  if (index === length_airlift) {
    //Last PPR, last airfield on itinerary and all airlifts assumed to offLoad
    airliftArray[index].forEach((lift_on_leg) => {
      offLoad.push(lift_on_leg);
    });
  }
  //All other stops
  else {
    airliftArray[index].forEach((lift_on_leg) => {
      const lift_match = airliftArray[index + 1].find((lift_on_next) => {
        return lift_on_next === lift_on_leg;
      });
      if (lift_match === undefined) {
        offLoad.push(lift_on_leg);
      }
    });
    //Onload:
    airliftArray[index + 1].forEach((lift_on_next_leg) => {
      const lift_match = airliftArray[index].find((lift_on_leg) => {
        return lift_on_leg === lift_on_next_leg;
      });
      if (lift_match === undefined) {
        onLoad.push(lift_on_next_leg);
      } else {
        thruLoad.push(lift_on_next_leg);
      }
    });
  }

  return [onLoad, thruLoad, offLoad];
}

function decodeHAZ(haz) {
  let allHaz_array = [];
  let allHaz_set = new Set();
  let haz_obj = { UN: '', name: '', class: '', new: '', weight: '' };
  const haz_array = haz.split(';');
  haz_array.pop();
  const num_items = haz_array.length / 5;
  for (let index = 0; index < num_items; index++) {
    haz_obj.UN = haz_array[index * 5] || 'Unknown';
    haz_obj.name = haz_array[index * 5 + 1] || 'Unknown';
    haz_obj.class = haz_array[index * 5 + 2] || 'Unknown';
    haz_obj.new = haz_array[index * 5 + 3] || 'Unknown';
    haz_obj.weight = haz_array[index * 5 + 4] || 'Unknown';
    allHaz_array.push(haz_obj);
    allHaz_set.add(haz_obj);
    haz_obj = {};
  }
  return allHaz_array;
}

/* function decodeHAZset(haz) {
  let allHaz_set = new Set();
  let haz_obj = { UN: '', name: '', class: '', new: '', weight: '' };
  const haz_array = haz.split(';');
  haz_array.pop();
  const num_items = haz_array.length / 5;
  for (let index = 0; index < num_items; index++) {
    haz_obj.UN = haz_array[index * 5] || 'Unknown';
    haz_obj.name = haz_array[index * 5 + 1] || 'Unknown';
    haz_obj.class = haz_array[index * 5 + 2] || 'Unknown';
    haz_obj.new = haz_array[index * 5 + 3] || 'Unknown';
    haz_obj.weight = haz_array[index * 5 + 4] || 'Unknown';
    allHaz_set.add(haz_obj);
    haz_obj = {};
  }
  return allHaz_set;
} */

function parseHAZ(load_obj, ICAO) {
  let haz_str = '';
  let haz_obj = '';

  switch (ICAO) {
    /*     case 'LGSA':
      break; */

    default:
      load_obj.forEach((lift) => {
        if (lift.HAZ === '1') {
          haz_obj = decodeHAZ(lift.HAZdescription);
          haz_obj.forEach((hazRow) => {
            haz_str += `${hazRow.class}/${hazRow.new} `;
          });
        }
      });

      break;
  }

  return haz_str;
}

/* 
if (onLoadObj.length === 0) {
  onLoad_string = 'No airlifts onloaded at this location';
} else {
  onLoad_string = 'Airlifts to be onloaded at this location';
  onLoadObj.forEach((lift) => {
    onLoad_string += `%0D%0A 
Passengers: ${lift.PAX} / Total cargo weight (lbs): ${lift.cargoWeight}%0D%0A 
Hazardous Cargo: ${lift.HAZ === '0' ? 'None' : 'YES'}%0D%0A`;
    if (lift.HAZ === '1') {
      HAZ_string = decodeHAZ(lift.HAZdescription);
      onLoad_string += `UN Code / Description / Class / Weight (lbs)%0D%0A ${HAZ_string}`;
    }
  });
}

let offLoad_string = '';
if (offLoadObj.length === 0) {
  offLoad_string = 'No airlifts offloaded at this location';
} else {
  offLoad_string = 'Airlifts to be offloaded at this location';
  offLoadObj.forEach((lift) => {
    offLoad_string += `%0D%0A 
Passengers: ${lift.PAX} / Total cargo weight (lbs): ${lift.cargoWeight}%0D%0A 
Hazardous Cargo: ${lift.HAZ === '0' ? 'None' : 'YES'}%0D%0A`;
    if (lift.HAZ === '1') {
      HAZ_string = decodeHAZ(lift.HAZdescription);
      offLoad_string += `UN Code / Description / Class / Weight (lbs)%0D%0A ${HAZ_string}`;
    }
  });
}

let thruLoad_string = '';
if (thruLoadObj.length === 0) {
  thruLoad_string = 'No airlifts onboard at this location';
} else {
  thruLoad_string =
    'Airlifts onboard (neither on nor offload) at this location';
  thruLoadObj.forEach((lift) => {
    thruLoad_string += `%0D%0A 
Passengers: ${lift.PAX} / Total cargo weight (lbs): ${lift.cargoWeight}%0D%0A 
Hazardous Cargo: ${lift.HAZ === '0' ? 'None' : 'YES'}%0D%0A`;
    if (lift.HAZ === '1') {
      HAZ_string = decodeHAZ(lift.HAZdescription);
      thruLoad_string += `UN Code / Description / Class / Weight (lbs)%0D%0A ${HAZ_string}`;
    }
  });
}
 */
