import LuxonUtils from '@date-io/luxon';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import ErrorBoundary from 'react-error-boundary';
import MyFallbackComponent from '../MyFallbackComponent.jsx';
import { AirliftFilter } from '../store';
import AirliftColumn from './AirliftColumn';
import './App.css';
import './ImportFromLRcollapse';
import ImportFromLRcollapse from './ImportFromLRcollapse';
import MissionBuilder from './MissionBuilder';
import { MissionInWork } from './MissionGeneratorStore';
import MissionHeader from './MissionHeader.jsx';
import RouteColumn from './RouteColumn';

export default function MissionGeneratorPage(props) {
  const [aircraftType, setaircraftType] = useState('c130routes');
  const [missionInWork, setmissionInWork] = useState([]);
  const [smartTiming, setsmartTiming] = useState(true);
  const [showCollapse, setshowCollapse] = useState(
    props.location.state !== undefined ? true : false
  );

  const [airliftTextFilter, setairliftTextFilter] = useState('');
  const options = {
    // you can also just use 'bottom center'
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE,
  };

  //context verified MissionInWork

  return (
    <DndProvider backend={Backend}>
      <AlertProvider template={AlertTemplate} {...options}>
        <AirliftFilter.Provider
          value={[airliftTextFilter, setairliftTextFilter]}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <MissionInWork.Provider value={[missionInWork, setmissionInWork]}>
              <Grid container>
                {props.location.state && (
                  <ImportFromLRcollapse
                    props={props?.location?.state}
                    showCollapse={showCollapse}
                  />
                )}
              </Grid>
              <Grid container direction='row' spacing={1}>
                <Grid item sm={3}>
                  <ErrorBoundary FallbackComponent={MyFallbackComponent}>
                    <RouteColumn aircraftType={aircraftType} />
                  </ErrorBoundary>
                  <ErrorBoundary FallbackComponent={MyFallbackComponent}>
                    <AirliftColumn props={props} />
                  </ErrorBoundary>
                </Grid>
                <Grid item sm={9}>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
                        <MissionHeader
                          setshowCollapse={setshowCollapse}
                          showCollapse={showCollapse}
                          fromLR={props.location.state}
                          aircraftType={aircraftType}
                          setaircraftType={setaircraftType}
                          smartTiming={smartTiming}
                          setsmartTiming={setsmartTiming}
                        />
                      </ErrorBoundary>
                    </Grid>
                    <Grid item>
                      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
                        <MissionBuilder
                          aircraftType={aircraftType}
                          smartTiming={smartTiming}
                          setsmartTiming={setsmartTiming}
                        />
                      </ErrorBoundary>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MissionInWork.Provider>
          </MuiPickersUtilsProvider>
        </AirliftFilter.Provider>
      </AlertProvider>
    </DndProvider>
  );
}
