export default function AddCCemails(ICAO_array, plane, emails) {
  let emailsToAdd = '';
  if (emails && emails.length !== 0) {
    switch (plane) {
      case 'C-40':
        emails.forEach((elem) => {
          if (elem.aircraft === plane) {
            emailsToAdd += elem.email + ';';
          }
        });
        break;
      case 'C-130':
        emails.forEach((elem) => {
          if (elem.aircraft === plane) {
            emailsToAdd += elem.email + ';';
          }
        });
        break;
      case 'C-26':
        emails.forEach((elem) => {
          if (elem.aircraft === plane) {
            emailsToAdd += elem.email + ';';
          }
        });
        break;
      default:
        emailsToAdd += '';
        break;
    }
  }

  return emailsToAdd;
}
