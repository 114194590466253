import Grid from '@material-ui/core/Grid';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import '../App.css';
import { handleErrors } from '../Errors/handleErrors';
import GougeReports from '../GougeReports';
import MyFallbackComponent from '../MyFallbackComponent.jsx';
import { ContactsValidContext, DownloadFoldersFiles } from '../store';
import useMissionDetail from '../Utils/Hooks/useMissionDetail';
import ContactPage from './ContactPage';
import Itinerary from './Itinerary';
import { LNOnotes, LNOupdate } from './LNONotesUpdate';
import { Header } from './MissionDetailHeader';
import MissionStatus from './MissionStatus';
import OfficeNotes from './OfficeNotesUpdate';
import PPRPage from './PPRPage';
import './StatusBar';
import { Warnings } from './Warnings';

//TO DO, setMsnObj if page reloaded

const GougeFallback = () => (
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
    <h3>Loading Gouge reports...</h3>
  </div>
);

const ItineraryFallback = () => (
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
    <h3>Loading Itinerary...</h3>
  </div>
);
const PPRFallback = () => (
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
    <h3>Loading PPR...</h3>
  </div>
);
const MissionDetailPageFallback = () => (
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
    <h3>Loading Mission detail...</h3>
  </div>
);

export default function MissionDetailPage(props) {
  const [contactsValid, setContactsValid] = useState(false);

  return (
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      <Suspense fallback={<MissionDetailPageFallback />}>
        <ContactsValidContext.Provider
          value={[contactsValid, setContactsValid]}>
          <MissionDetail
            props={props}
            missionID={props.location.state.missionDataLink.missionID}
          />
        </ContactsValidContext.Provider>
      </Suspense>
    </ErrorBoundary>
  );
}

function MissionDetail({ props, missionID }) {
  const { status, data, error } = useMissionDetail(
    props.location.state.missionDataLink.routeID,
    props.location.state.missionDataLink.typeAircraft
  );
  const [, setFiles] = useContext(DownloadFoldersFiles);

  useEffect(() => {
    async function pullFolders() {
      return fetch('/db/pullPPRFolders.efp.php', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((data) => setFiles(data));
    }
    pullFolders();
  }, [setFiles]);

  return (
    <>
      <Grid
        container
        direction='row'
        alignItems='center'
        justify='center'
        spacing={1}
        style={{ margin: '1vh' }}>
        <Grid item md={3}>
          <ErrorBoundary FallbackComponent={MyFallbackComponent}>
            <Header data={props} />
          </ErrorBoundary>
        </Grid>
        <Grid item md={9}>
          <ErrorBoundary FallbackComponent={MyFallbackComponent}>
            <MissionStatus props={props} missionID={missionID} />
          </ErrorBoundary>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        alignItems='center'
        justify='center'
        spacing={1}
        style={{ margin: '1vh' }}>
        <Grid item md={12}>
          <ErrorBoundary FallbackComponent={MyFallbackComponent}>
            <Warnings
              legs={props.location.state.missionDataLink.fullMissionDataObject}
              data={props.location.state.missionDataLink}
            />
          </ErrorBoundary>
        </Grid>
      </Grid>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        {status === 'success' ? (
          status === 'error' ? (
            <span>Error: {error.message}</span>
          ) : (
            <Itinerary
              mission={props.location.state.missionDataLink}
              routes={data[0]}
              airports={data[1]}
            />
          )
        ) : (
          <ItineraryFallback />
        )}
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        {status === 'success' ? (
          status === 'error' ? (
            <span>Error: {error.message}</span>
          ) : (
            <PPRPage
              data={props.location.state.missionDataLink}
              routes={data[0]}
              airports={data[1]}
            />
          )
        ) : (
          <PPRFallback />
        )}
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <ContactPage data={props.location.state.missionDataLink} />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <Notes props={props} />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        {status === 'success' ? (
          status === 'error' ? (
            <span>Error: {error.message}</span>
          ) : (
            <GougeReports reports={data[2]} />
          )
        ) : (
          <GougeFallback />
        )}
      </ErrorBoundary>
    </>
  );
}

function Notes({ props }) {
  return (
    <>
      <OfficeNotes data={props.location.state.missionDataLink} />
      {localStorage.getItem('typeUser') !== 'admin' &&
      localStorage.getItem('typeUser') !== 'dh' ? (
        <LNOnotes data={props.location.state.missionDataLink} />
      ) : (
        ''
      )}

      {localStorage.getItem('typeUser') === 'admin' ||
      localStorage.getItem('typeUser') === 'dh' ? (
        <LNOupdate data={props.location.state.missionDataLink} />
      ) : (
        ''
      )}
    </>
  );
}
