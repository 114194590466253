import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import React, { useContext } from 'react';
import { MissionInWork } from './MissionGeneratorStore';
import { updateSmartTimes } from './updateSmartTimes';

export default function RON({ data }) {
  const [missionInWork, setmissionInWork] = useContext(MissionInWork);

  function setLandTime() {
    if (
      data.smartTiming &&
      data.smartTiming === true &&
      missionInWork[data.rowNumber]?.RON === true
    ) {
      //set next takeoffTime

      switch (data?.aircraftType) {
        case 'c40routes':
          if (Number(data.rowNumber) + 1 !== missionInWork.length) {
            let newState = [...missionInWork];
            const updatedState = updateSmartTimes(newState, data.rowNumber, 15);

            setmissionInWork(updatedState);
          }

          break;
        case 'c130routes':
          if (Number(data.rowNumber) + 1 !== missionInWork.length) {
            let newState = [...missionInWork];
            const updatedState = updateSmartTimes(newState, data.rowNumber, 17);

            setmissionInWork(updatedState);
          }
          break;
        case 'c26routes':
          if (Number(data.rowNumber) + 1 !== missionInWork.length) {
            let newState = [...missionInWork];
            const updatedState = updateSmartTimes(newState, data.rowNumber, 15);

            setmissionInWork(updatedState);
          }
          break;
        default:
          break;
      }
    }
  }

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              missionInWork[data.rowNumber].RON !== undefined
                ? missionInWork[data.rowNumber].RON
                : false
            }
            onChange={() => {
              let newState = [...missionInWork];
              newState[data.rowNumber].RON = !newState[data.rowNumber].RON;
              setmissionInWork(newState);
              setLandTime();
            }}
          />
        }
        label='RON?'
      />
    </FormGroup>
  );
}
