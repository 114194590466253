import React, { useContext, useState } from 'react';
import { MissionInWork } from './MissionGeneratorStore';
import TextField from '@material-ui/core/TextField';
import convertTime from './convertTime';
import Tooltip from '@material-ui/core/Tooltip';
const { DateTime, Duration } = require('luxon');

export default function TurnTime({ props }) {
  const [missionArray, setmissionArray] = useContext(MissionInWork);
  const [error, seterror] = useState(false);
  //turnTime: { minutes: 60, text: '1+00' },
  return (
    <Tooltip title='Minutes or XX+XX or XX.X or XX:XX' arrow>
      <TextField
        id='outlined-basic'
        type='text'
        label='Turn Time'
        style={{ backgroundColor: error === true ? 'red' : '' }}
        //TODO NEED TO SET THIS TO TIME_STRING
        value={missionArray[props.rowNumber]?.turnTime?.text}
        onChange={e => {
          e.preventDefault();
          const [minutes, time_string, error_update] = convertTime(
            e.target.value
          );
          seterror(error_update);
          setmissionArray(prevState => {
            const newState = [...prevState];
            newState[props.rowNumber].turnTime.minutes = minutes;
            newState[props.rowNumber].turnTime.text = time_string;

            for (let i = props.rowNumber; i < newState.length - 1; i++) {
              if (Number(props.rowNumber) !== Number(newState.length) - 1) {
                newState[i + 1].toTimeZulu = DateTime.fromISO(
                  newState[i].landTime,
                  {
                    zone: newState[i].poaTimeZone
                  }
                )
                  .plus(
                    Duration.fromObject({
                      minutes: newState[i].turnTime.minutes
                    })
                  )
                  .toUTC()
                  .toISO({ includeOffset: false });
                newState[i + 1].toTime = DateTime.fromISO(
                  newState[i].landTime,
                  {
                    zone: newState[i].poaTimeZone
                  }
                )
                  .plus(
                    Duration.fromObject({
                      minutes: newState[i].turnTime.minutes
                    })
                  )
                  .setZone(newState[i + 1].podTimeZone)
                  .toISO({ includeOffset: false });

                newState[i + 1].landTimeZulu = DateTime.fromISO(
                  newState[i + 1].toTime,
                  {
                    zone: newState[i + 1].podTimeZone
                  }
                )
                  .plus(
                    Duration.fromObject({
                      minutes: newState[i + 1].roundedEnrouteTime
                    })
                  )
                  .toUTC()
                  .toISO({ includeOffset: false });

                newState[i + 1].landTime = DateTime.fromISO(
                  newState[i + 1].toTime,
                  {
                    zone: newState[i + 1].podTimeZone
                  }
                )
                  .plus(
                    Duration.fromObject({
                      minutes: newState[i + 1].roundedEnrouteTime
                    })
                  )
                  .setZone(newState[i + 1].poaTimeZone)
                  .toISO({ includeOffset: false });
              }
            }
            return [...newState];
          });
        }}
      />
    </Tooltip>
  );
}
