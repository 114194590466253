//import {turf} from './turf.js'
import GoogleMapReact from 'google-map-react';
import React from 'react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export function SimpleMap(props) {
  const mission_data = props.data;
  const center = {
    lat: 51.5,
    lng: 0.12,
  };
  const zoom = 4;

  async function setRouteOfFlight(map, maps, mission_data) {
    /*     const [new_center, new_route, new_route_last] = await createRouteObj(
      mission_data
    ); */
    const [new_route, new_route_last] = await createRouteObj(mission_data);

    let routeOfFlight = new maps.Polyline({
      path: new_route,
      strokeColor: 'red',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    routeOfFlight.setMap(map);
    var bounds = new maps.LatLngBounds();
    bounds.extend({ lat: new_route[0].lat, lng: new_route[0].lng });
    bounds.extend({ lat: new_route_last.lat, lng: new_route_last.lng });
    map.fitBounds(bounds);
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '600px', width: 'auto' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBkMZtDR2DI8O8jsVmgklzR5T6DGNTzrD0' }}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) =>
          setRouteOfFlight(map, maps, mission_data)
        }>
        <AnyReactComponent />
      </GoogleMapReact>
    </div>
  );
}

async function createRouteObj(_mission_data) {
  let google_route = [];
  let latlonobj1 = {};
  let turfTest = [];
  let turfTest1 = [];

  let PODairfield = _mission_data.pod;
  //let url_airfield = '/db/Alkhasdfhasd.db?s=' + PODairfield;

  let pod_data = await fetch('/db/Alkhasdfhasd.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify({ airfield: PODairfield }),
  });

  let pod_info = await pod_data.json();

  latlonobj1.lat = Number(pod_info[0].latitude);
  latlonobj1.lng = Number(pod_info[0].longitude);
  google_route.push(latlonobj1);

  latlonobj1.lat = Number(pod_info[0].latitude);
  latlonobj1.lng = Number(pod_info[0].longitude);
  google_route.push(latlonobj1);
  turfTest1.push(Number(pod_info[0].latitude));
  turfTest1.push(Number(pod_info[0].longitude));
  turfTest.push(turfTest1);
  turfTest1 = [];

  let lat_array = _mission_data.lat.split(' ');
  let lon_array = _mission_data.lon.split(' ');
  for (let i = 0; i < lat_array.length; i++) {
    let latlonobj = {};
    let lat_decdeg = convertLatLonJep(lat_array[i]);
    let lon_decdeg = convertLatLonJep(lon_array[i]);
    latlonobj.lat = lat_decdeg;
    latlonobj.lng = lon_decdeg;
    google_route.push(latlonobj);

    turfTest1.push(Number(lat_decdeg));
    turfTest1.push(Number(lon_decdeg));
    turfTest.push(turfTest1);
    turfTest1 = [];
  }

  let google_route_last = google_route[google_route.length - 1];

  //var center = centerOfMass(linestring);

  return [google_route, google_route_last];
  //return [center.geometry.coordinates, google_route, google_route_last];
}

function convertLatLonJep(coord) {
  //This take Jeppesen lat lon format (Deg min.min) and converts to Dec.Deg
  let sign = Number(1);
  let result = '';
  if (coord.charAt(0) === 'S' || coord.charAt(0) === 'W') {
    sign = Number(-1);
  }
  if (coord.charAt(0) === 'N' || coord.charAt(0) === 'S') {
    let latdeg = coord.charAt(1);
    latdeg += coord.charAt(2);
    latdeg = Number(latdeg);
    let latmin = coord.charAt(3);
    latmin += coord.charAt(4);
    latmin = Number(latmin);
    latmin = latmin / 60;
    let latdotmin = Number(coord.charAt(5));
    latdotmin = latdotmin / 600;

    //Convert to dec deg
    result = latdeg + latmin + latdotmin;
  }
  if (coord.charAt(0) === 'W' || coord.charAt(0) === 'E') {
    let londeg = coord.charAt(1);
    londeg += coord.charAt(2);
    londeg += coord.charAt(3);
    londeg = Number(londeg);
    let lonmin = coord.charAt(4);
    lonmin += coord.charAt(5);
    lonmin = Number(lonmin);
    let londotmin = Number(coord.charAt(6));

    //Convert to dec deg
    result = londeg + lonmin / 60 + londotmin / 600;
  }
  result = result * sign;
  return result;
}
