import { useQuery } from 'react-query';

const getSplashTable = async () => {
  const resp = await fetch('/db/pullSplashTable.db.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify({ action: 'pull' }),
  });
  if (!resp.ok) {
    throw new Error('Network response was not ok');
  }
  return resp.json();
};

export default function useSplashTable() {
  return useQuery(['splashTable'], getSplashTable, {
    staleTime: Infinity,
    placeholderData: [],
  });
}
