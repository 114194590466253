import convertTime from './convertTime';
const { DateTime, Duration } = require('luxon');

export function updateSmartTimes(newState, rowNumber, minimumTurn) {
  let earliestLaunchTime = DateTime.fromISO(newState[rowNumber].landTimeZulu, {
    zone: 'UTC',
  })
    .plus({ hours: minimumTurn })
    .setZone(newState[rowNumber].poaTimeZone);
  let nextDayStandardLaunch = DateTime.fromISO(newState[rowNumber].landTime)
    .setZone(newState[rowNumber].poaTimeZone)
    .plus({ days: 1 })
    .set({ hour: 9, minute: 0 });

  let nextLaunchShouldBe = '';

  if (nextDayStandardLaunch >= earliestLaunchTime) {
    nextLaunchShouldBe = nextDayStandardLaunch;
  } else {
    nextLaunchShouldBe = earliestLaunchTime;
  }

  //Set turn time
  const newTurnTime = nextLaunchShouldBe
    .diff(
      DateTime.fromISO(newState[rowNumber].landTimeZulu, {
        zone: 'UTC',
      }),
      'minutes'
    )
    .toFormat('mm');
  const [minutes, time_string] = convertTime(String(newTurnTime));
  newState[rowNumber].turnTime.minutes = minutes;
  newState[rowNumber].turnTime.text = time_string;

  for (let i = Number(rowNumber) + 1; i < newState.length; i++) {
    //Set new zulu takeoff time row after
    if (i === 1) {
      newState[
        Number(rowNumber) + 1
      ].toTimeZulu = nextLaunchShouldBe.toUTC().toISO();
    } else {
      newState[i].toTimeZulu = DateTime.fromISO(newState[i - 1].landTimeZulu, {
        zone: 'UTC',
      })
        .plus(
          Duration.fromObject({
            minutes: newState[i - 1].turnTime.minutes,
          })
        )
        .toUTC()
        .toISO({ includeOffset: false });
    }

    //Set zulu land time row after
    newState[i].landTimeZulu = DateTime.fromISO(newState[i].toTimeZulu, {
      zone: 'UTC',
    })
      .plus(
        Duration.fromObject({
          minutes: newState[i].roundedEnrouteTime,
        })
      )
      .toUTC()
      .toISO({ includeOffset: false });

    //Set new local takeoff time row after
    newState[i].toTime = DateTime.fromISO(newState[i].toTimeZulu, {
      zone: 'UTC',
    })
      .setZone(newState[i].podTimeZone)
      .toISO({ includeOffset: false });

    //Set local land time row after
    newState[i].landTime = DateTime.fromISO(newState[i].landTimeZulu, {
      zone: 'UTC',
    })
      .setZone(newState[i].poaTimeZone)
      .toISO({ includeOffset: false });
  }

  return newState;
}
