import Button from '@material-ui/core/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function LogoutButton() {
  const buttonStyle = {
    margin: '5px',
  };
  let history = useHistory();

  let { from } = { from: { pathname: '/' } };
  return localStorage.getItem('userName') ? (
    <Button
      style={buttonStyle}
      variant='contained'
      size='small'
      onClick={(e) => {
        e.preventDefault();
        localStorage.clear();
        history.replace(from);
      }}>
      Logout
    </Button>
  ) : (
    ''
  );
}
