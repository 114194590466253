import React, { useState } from 'react';
import AirliftDetails from './AirliftDetails';
import AirliftDisplay from './AirliftDisplay';

export default function AirliftColumn(props) {
  const [airliftSelected, setairliftSelected] = useState('');
  return (
    <>
      <AirliftDetails airliftSelected={airliftSelected} />
      <AirliftDisplay
        importFromLR={props?.location?.state}
        setairliftSelected={setairliftSelected}
      />
    </>
  );
}
