import { Hidden, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useContext, useEffect, useState } from 'react';
import useRoutes from '../Utils/Hooks/useRoutes';
import './App.css';
import { MissionInWork } from './MissionGeneratorStore';
import { RouteChip } from './RouteChips';
const { DateTime } = require('luxon');

export default function RouteTiles({
  props,
  aircraftType,
  setselectedRoute,
  selectedRoute,
}) {
  const [missionInWork] = useContext(MissionInWork);
  const { data: routes, isFetching, status } = useRoutes(aircraftType);

  const [filterRoutes, setfilterRoutes] = useState();
  const [podFilter, setpodFilter] = useState('');
  const [poaFilter, setpoaFilter] = useState('');
  const [checked, setChecked] = React.useState(true);
  const [airfieldList, setairfieldList] = useState(['null']);
  const [matching, setmatching] = useState(false);

  useEffect(() => {
    //When the itinerary changes create new set of all airfields in order to filter airlifts
    let airfieldSet = new Set();
    missionInWork.forEach((leg) => {
      airfieldSet.add(leg.pod);
      airfieldSet.add(leg.poa);
    });
    setairfieldList(
      Array.from(airfieldSet).length === 0 ? 'null' : Array.from(airfieldSet)
    );
    return () => {
      airfieldSet = '';
    };
  }, [missionInWork]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleMatchChange = (event) => {
    setmatching(event.target.checked);
  };

  useEffect(() => {
    if (checked === true && matching === true) {
      setfilterRoutes(
        routes.filter((elem) => {
          return (
            elem.validated === '1' &&
            elem.pod.toLowerCase().indexOf(podFilter.toLowerCase()) > -1 &&
            elem.poa.toLowerCase().indexOf(poaFilter.toLowerCase()) > -1 &&
            DateTime.fromSQL(elem.valdate) >
              DateTime.local().minus({ days: 30 }) &&
            (airfieldList.includes(elem.pod) || airfieldList.includes(elem.poa))
          );
        })
      );
    } else if (checked === true && matching === false) {
      //Done
      setfilterRoutes(
        routes.filter((elem) => {
          return (
            elem.validated === '1' &&
            elem.pod.toLowerCase().indexOf(podFilter.toLowerCase()) > -1 &&
            elem.poa.toLowerCase().indexOf(poaFilter.toLowerCase()) > -1 &&
            DateTime.fromSQL(elem.valdate) >
              DateTime.local().minus({ days: 30 })
          );
        })
      );
    } else if (checked === false && matching === true) {
      //done
      setfilterRoutes(
        routes.filter((elem) => {
          return (
            elem.validated === '1' &&
            (airfieldList.includes(elem.pod) ||
              airfieldList.includes(elem.poa)) &&
            elem.pod.toLowerCase().indexOf(podFilter.toLowerCase()) > -1 &&
            elem.poa.toLowerCase().indexOf(poaFilter.toLowerCase()) > -1
          );
        })
      );
    } else {
      //checked === false && matching === false
      //done
      setfilterRoutes(
        routes.filter((elem) => {
          return (
            elem.validated === '1' &&
            elem.pod.toLowerCase().indexOf(podFilter.toLowerCase()) > -1 &&
            elem.poa.toLowerCase().indexOf(poaFilter.toLowerCase()) > -1
          );
        })
      );
    }

    setfilterRoutes((prevlist) => {
      const reversed = prevlist.reverse();
      setfilterRoutes(reversed);
    });
    return () => {};
  }, [poaFilter, podFilter, checked, matching, routes, airfieldList]);

  if (status === 'loading') {
    return <span>Loading</span>;
  }

  return (
    <>
      <Box
        style={{
          backgroundColor: 'lightblue',
          textAlign: 'center',
          padding: '5px',
          overflowY: 'scroll',
        }}>
        <Typography variant='h5' color='initial'>
          Route Filters
        </Typography>
        <Hidden smDown>
          <Grid container justify='center' alignItems='center' direction='row'>
            <Grid item container direction='column' xs={6}>
              <Grid item xs>
                <FormControl component='fieldset'>
                  <FormGroup>
                    <FormControlLabel
                      style={{ margin: '0px' }}
                      control={
                        <Checkbox
                          checked={checked}
                          style={{ padding: '1px' }}
                          onChange={handleChange}
                          value='false'
                        />
                      }
                      label='Only current routes'
                    />
                    <FormControlLabel
                      style={{ margin: '0px' }}
                      control={
                        <Checkbox
                          checked={matching}
                          style={{ padding: '1px' }}
                          onChange={handleMatchChange}
                        />
                      }
                      label='Matching POD or POA'
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container direction='column' xs={6}>
              <Grid item xs>
                <TextField
                  id='outlined-basic'
                  label='POD filter'
                  variant='outlined'
                  value={podFilter}
                  size='small'
                  style={{ marginBottom: '.5vh' }}
                  onChange={(e) => {
                    e.preventDefault();
                    //setrouteFilter({ ...routeFilter, podFilter: e.target.value });
                    setpodFilter(e.target.value.trim());
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  id='outlined-basic'
                  label='POA filter'
                  variant='outlined'
                  value={poaFilter}
                  size='small'
                  onChange={(e) => {
                    e.preventDefault();
                    setpoaFilter(e.target.value.trim());
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Box>
      <Box
        style={{
          backgroundColor: 'lightgrey',
          textAlign: 'center',
          padding: '2px',
          height: '25vh',
          overflowY: 'scroll',
        }}>
        {isFetching === true ? (
          <span>Loading Routes...</span>
        ) : status === 'error' ? (
          <span>Error loading routes</span>
        ) : (
          filterRoutes?.map((elem) => {
            return (
              <RouteChip
                props={elem}
                key={elem.id}
                setselectedRoute={setselectedRoute}
              />
            );
          })
        )}
      </Box>
    </>
  );
}
