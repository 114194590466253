import Typography from '@material-ui/core/Typography';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import useWarnings from '../Utils/Hooks/useWarnings';

export const Warnings = ({ legs, data }) => {
  const { data: warnings, isLoading } = useWarnings();
  //warning types: dow, perm, ??
  const [routesArray, setroutesArray] = useState([]);
  const [warningArray, setwarningArray] = useState([]);

  useEffect(() => {
    let routes_object = {};
    let routes_array = [];
    const routeID_array = data.routeID.trim().split(' ');
    const takeffTime_array = data.takeoffTimes.trim().split(' ');
    const landTime_array = data.landTimes.trim().split(' ');
    routeID_array.forEach((route, index) => {
      const legs_index = legs.findIndex(
        (leg) => leg.id === routeID_array[index]
      );

      routes_object = {
        leg: index + 1,
        type: 'pod',
        ICAO: legs_index !== -1 ? legs[legs_index].pod : '',
        dateISO: takeffTime_array[index],
        time: DateTime.fromISO(takeffTime_array[index]).toFormat('HHmm'),
        dow: DateTime.fromISO(takeffTime_array[index]).toFormat('EEEE'),
      };
      routes_array.push(routes_object);
      routes_object = {
        leg: index + 1,
        type: 'poa',
        ICAO: legs_index !== -1 ? legs[legs_index].poa : '',
        dateISO: landTime_array[index],
        time: DateTime.fromISO(landTime_array[index]).toFormat('HHmm'),
        dow: DateTime.fromISO(landTime_array[index]).toFormat('EEEE'),
      };
      routes_array.push(routes_object);
    });
    setroutesArray(routes_array);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warnings, isLoading, legs, data]);

  useEffect(() => {
    if (warnings && warnings !== [] && routesArray !== []) {
      let warn_out = [];
      let warn_out_out = [];
      warnings.forEach(async (warning) => {
        const matches = routesArray.filter((leg) => leg.ICAO === warning.ICAO);
        if (matches && matches !== []) {
          warn_out = await checkWarning(warning, matches);
          warn_out_out.push(...warn_out);
        }
      });
      setwarningArray(warn_out_out);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warnings, routesArray]);

  if (warningArray.length === 0) {
    return null;
  }

  return (
    <div style={{ textAlign: 'center', color: 'red' }}>
      <Typography variant='h5' color='inherit'>
        Warnings...
      </Typography>
      {warningArray &&
        !isLoading &&
        warningArray.map((elem, ind) => {
          if (elem[1].type === 'dow') {
            return (
              <React.Fragment key={'warning' + ind}>
                <Typography variant='h6' color='inherit'>
                  {`Leg ${elem[0].leg}, ${
                    elem[0].type === 'pod' ? 'Takeoff' : 'Landing'
                  } at ${elem[0].time}Z VIOLATES a warning for ${
                    elem[1].ICAO
                  } on ${elem[1].dayOfWeek} between ${elem[1].startHour}Z and ${
                    elem[1].endHour
                  }Z with notes: ${elem[1].notes}`}
                </Typography>
              </React.Fragment>
            );
          }
          if (elem[1].type === 'perm') {
            return (
              <React.Fragment key={'warning' + ind}>
                <Typography variant='h6' color='inherit'>
                  {`Leg ${elem[0].leg}, ${
                    elem[0].type === 'pod' ? 'Takeoff' : 'Landing'
                  } at ${elem[0].time}Z ${
                    elem[0].type === 'pod' ? 'from' : 'at'
                  } ${elem[1].ICAO} VIOLATES a warning from ${
                    elem[1].startDate
                  } to ${elem[1].endDate}, Notes: ${elem[1].notes}`}
                </Typography>
              </React.Fragment>
            );
          }
          return null;
        })}
    </div>
  );
};

async function checkWarning(warning, matches) {
  let output = [];

  switch (warning.type) {
    case 'dow':
      let matches_dow = matches.filter((leg) => leg.dow === warning.dayOfWeek);
      if (matches_dow && matches_dow !== []) {
        matches_dow.forEach((elem, ind) => {
          if (elem.type === 'pod') {
            //elem.time is takeoff time
            if (
              Number(elem.time) <= Number(warning.endHour) &&
              Number(elem.time) >= Number(warning.startHour)
            ) {
              output.push([elem, warning]);
            }
          }
          if (elem.type === 'poa') {
            //elem.time is arrival time
            if (
              Number(elem.time) <= Number(warning.endHour) &&
              Number(elem.time) <= Number(warning.startHour)
            ) {
              output.push([elem, warning]);
            }
          }
        });
      }
      break;
    case 'perm':
      matches.forEach((match, ind) => {
        if (
          DateTime.fromISO(match.dateISO) >=
            DateTime.fromISO(warning.startDate) &&
          DateTime.fromISO(match.dateISO) <= DateTime.fromISO(warning.endDate)
        ) {
          output.push([match, warning]);
        }
      });
      break;
    default:
      break;
  }

  return output;
}
