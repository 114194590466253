import React, { useContext } from 'react';
import { MissionInWork } from './MissionGeneratorStore';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function CrewChange({ data }) {
  const [missionInWork, setmissionInWork] = useContext(MissionInWork);

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              missionInWork[data.rowNumber].crewChange !== undefined
                ? missionInWork[data.rowNumber].crewChange
                : false
            }
            onChange={() => {
              let newState = [...missionInWork];
              newState[data.rowNumber].crewChange = !newState[data.rowNumber]
                .crewChange;
              setmissionInWork(newState);
            }}
            //  value='crewChange?'
          />
        }
        label='Crew change?'
      />
    </FormGroup>
  );
}
