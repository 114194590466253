import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import produce from 'immer';
import React from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQueryClient } from 'react-query';

export const DeleteButton = ({ data }) => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const mutation = useMutation(
    async ({ data }) => {
      await cancelMission({ data });
    },
    {
      onMutate: async ({ event, pprInfo }) => {
        await queryClient.cancelQueries(['missionDataPartial']);
        const previousData = queryClient.getQueryData(['missionDataPartial']);

        queryClient.setQueryData(
          ['missionDataPartial'],
          produce(previousData, (draft) => {
            const index = draft.findIndex(
              (mission) => mission.missionID === data.missionID
            );
            if (index !== -1) draft.splice(index, 1);
          })
        );

        return [previousData];
      },
      // On failure, roll back to the previous value
      onError: async (err, variables, previousValue) => {
        await queryClient.setQueryData(
          ['missionDataPartial'],
          previousValue[0]
        );
        alert.error('Problem deleting this mission.  See Administrator.');
      },
      // After success or failure, refetch the todos query
      onSuccess: () => {
        alert.success('Mission canceled!');
        queryClient.invalidateQueries(['missionDataPartial']);
      },
    }
  );

  return (
    <TableCell align='center'>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={async () => {
          if (
            window.confirm(
              `You are about to delete this CNV ${data.cnv}.  Are you sure? This action cannot be undone.`
            )
          ) {
            mutation.mutate({ data });
          }
        }}
        startIcon={<DeleteIcon />}>
        Delete
      </Button>
    </TableCell>
  );
};

async function cancelMission({ data }) {
  console.log(data);
  const resp = await fetch('/db/DMSkljuyo.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify({ missionID: data.missionID }),
  });

  if (!resp.ok) {
    throw new Error('Network response was not ok');
  }
  return resp.text();
}
