export function turnTimeCheck(typeAircraft, turnTime, RON, CC) {
  let turnTimeValid = true;

  if (typeAircraft === 'c130routes') {
    if (RON === false && turnTime < 90) {
      turnTimeValid = false;
    }
    if (RON === true && turnTime < 60 * 17 && !CC) {
      turnTimeValid = false;
    }
  }

  if (typeAircraft === 'c40routes') {
    if (RON === false && turnTime < 60) {
      turnTimeValid = false;
    }
    if (RON === true && turnTime < 60 * 15 && !CC) {
      turnTimeValid = false;
    }
  }

  return turnTimeValid;
}
