import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import produce from 'immer';
import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQueryClient } from 'react-query';
import '../App.css';
import { MissionContext } from '../store';

export default function MissionNotesUpdate(props) {
  const { missionObj } = useContext(MissionContext);

  const [note, setNote] = useState(decodeHTML(missionObj.missionNotes));

  const [changed, setChanged] = useState(false);
  const queryClient = useQueryClient();
  const alert = useAlert();
  const mutation = useMutation(
    async ({ note, missionID }) => {
      await uploadNotes({ note, missionID });
    },
    {
      onMutate: async ({ note }) => {
        setChanged(false);
        await queryClient.cancelQueries(['missionDataPartial']);
        const previousData = queryClient.getQueryData(['missionDataPartial']);

        queryClient.setQueryData(
          ['missionDataPartial'],
          produce(previousData, (draft) => {
            const index = draft.findIndex(
              (mission) => mission.missionID === props.data.missionID
            );
            if (index !== -1) draft[index].missionNotes = note;
          })
        );

        return [previousData];
      },
      // On failure, roll back to the previous value
      onError: async (err, variables, previousValue) => {
        setChanged(true);
        await queryClient.setQueryData(
          ['missionDataPartial'],
          previousValue[0]
        );
        alert.error('Problem saving note.  See Administrator.');
      },
      // After success or failure, refetch the todos query
      onSuccess: () => {
        alert.success('Note saved!');
        queryClient.invalidateQueries(['missionDataPartial']);
      },
    }
  );

  useEffect(() => {
    setNote(decodeHTML(missionObj.missionNotes));
  }, [missionObj]);

  const paperStyle = {
    marginTop: '5px',
    outline: 'solid',
    padding: '15px',
    backgroundColor: 'lightyellow',
  };
  const divStyle = {
    padding: '3px',
  };

  function decodeHTML(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  return (
    <div style={divStyle}>
      <Paper style={paperStyle}>
        <Grid container spacing={1} direction='column'>
          <Grid
            container
            item
            spacing={2}
            direction='row'
            alignItems='center'
            justify='center'>
            <Grid item>
              <h4>Mission Notes Entry</h4>
            </Grid>
            <Grid item>
              <Button
                id='notesBtn'
                size='small'
                name='notesBtn'
                variant='outlined'
                color='primary'
                onClick={(e) => {
                  e.preventDefault();
                  const missionID = props.data.missionID;
                  mutation.mutate({ note, missionID });
                }}>
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid container item>
            <TextField
              id='outlined-multiline-static'
              multiline
              rows={4}
              variant='outlined'
              value={note}
              style={
                changed
                  ? { backgroundColor: '#f79292', width: '95%' }
                  : { backgroundColor: 'white', width: '95%' }
              }
              onChange={(e) => {
                setNote(e.target.value);
                setChanged(true);
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

async function uploadNotes({ note, missionID }) {
  const dataUpdate = {
    note: note,
    missionID: missionID,
    type: 'missionNotes',
  };

  const resp = await fetch('/db/updateOfficeNotes.schedule.db.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(dataUpdate),
  });

  if (!resp.ok) {
    throw new Error('Network response was not ok');
  } else {
    return resp.text();
  }
}
