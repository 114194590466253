import { FormControl, Input } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import React, { useContext, useState } from 'react';
import { DownloadFoldersFiles } from '../../../store';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const FileUpload = ({ pprID }) => {
  const [, setDirInfo] = useContext(DownloadFoldersFiles);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState('');
  const [, setFilename] = useState('Choose File');
  const [message, setMessage] = useState('');
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const [show, setshow] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setFilename('');
    const formData = new FormData();

    formData.append('file', file);
    formData.append('pprID', pprID);

    try {
      const res = await fetch('/db/uploadPPRFile.db.php', {
        method: 'POST',
        body: formData,
        withCredentials: true,
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      });
      const [, , fileMessage] = await res.json();
      updatePPRFolders();
      setMessage(fileMessage);
      setshow(true);
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        setMessage('There was a problem with the server when uploading file');
      } else {
        setMessage(err.response.data.msg);
      }
    }
  };

  const updatePPRFolders = async () => {
    try {
      const res = await fetch('/db/pullPPRFolders.efp.php', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      });
      const response = await res.json();
      setDirInfo(response);
      setFile('');
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        console.log('There was a problem with the server');
      } else if (err.response.status === 401) {
        console.log('Access denied');
      } else {
        console.log(err.response.data.msg);
      }
    }
  };

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={handleOpen}>
        Add a file
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Grid
            container
            spacing={4}
            direction='column'
            justify='center'
            alignItems='center'>
            <Grid item>
              <Typography variant='h4' color='primary'>
                Choose a file
              </Typography>
            </Grid>
            {show ? (
              <Grid item>
                <Alert
                  severity='error'
                  onClose={() => {
                    setshow(false);
                  }}>
                  {message}
                </Alert>
              </Grid>
            ) : null}
            <Grid item>
              <form onSubmit={onSubmit}>
                <Grid
                  container
                  item
                  spacing={2}
                  alignItems='center'
                  justify='center'>
                  <Grid item>
                    <FormControl variant='outlined'>
                      <Input
                        type='file'
                        id='customFile'
                        onChange={onChange}
                        variant='outlined'
                        label='Choose a file'
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button variant='contained' color='primary' type='submit'>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};
