import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

//Types of accounts: admin, fw, dh, c40, c130, c26, assist, guest (new)

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const [info, setInfo] = useState({ userEmail: '', password: '' });
  const [signup, setsignup] = useState({ name: '', email: '', password: '' });
  const [showSignUp, setshowSignUp] = useState(false);
  const [loginInfo, setLoginInfo] = useState('');
  const classes = useStyles();

  const handleChange = (event) => {
    event.preventDefault();
    setInfo({
      ...info,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeSignUp = (event) => {
    event.preventDefault();
    setsignup({
      ...signup,
      [event.target.name]: event.target.value,
    });
  };

  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };

  function validateCrendentials() {
    //quick check of entries
    if (info.password === '' && info.userEmail === '') {
      setLoginInfo('User email and password fields are empty');
      return false;
    } else if (info.password === '') {
      setLoginInfo('Password field is empty');
      return false;
    } else if (info.userEmail === '') {
      setLoginInfo('User email entry is empty');
      return false;
    } else {
      return true;
    }
  }

  function validateCrendentialsSignup() {
    //quick check of entries
    if (signup.password === '' && signup.email === '') {
      setLoginInfo('User email and password fields are empty');
      return false;
    } else if (signup.password === '') {
      setLoginInfo('Password field is empty');
      return false;
    } else if (signup.email === '') {
      setLoginInfo('User email entry is empty');
      return false;
    } else if (signup.email.length <= 20) {
      setLoginInfo('Password length is too short, must be 20 characters.');
      return false;
    } else {
      setLoginInfo('Success!');
      return true;
    }
  }

  async function sendCrendentials() {
    const data = { userEmail: info.userEmail, password: info.password };
    fetch('../../db/login.jwt.php', {
      method: 'POST',
      headers: {
        Origin: '*',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.status === 401) {
          setInfo({
            ...info,
            password: '',
          });
          setLoginInfo('Check user email and/or password');
        } else if (resp.status === 200) {
          history.replace(from);
          return resp.json();
        } else if (resp.status === 406) {
          setInfo({
            ...info,
            password: '',
          });
          setLoginInfo(
            'Multiple accounts exist for this user Email.  Contact Administrator.'
          );
        } else if (resp.status === 403) {
          setInfo({
            ...info,
            password: '',
          });
          setLoginInfo('Password is incorrect.  Check password.');
        } else if (resp.status === 405) {
          setInfo({
            ...info,
            password: '',
          });
          setLoginInfo(
            'Account has been reset.  Please establish a new login or contact Administrator.'
          );
        } else if (resp.status === 406) {
          setInfo({
            ...info,
            password: '',
          });
          setLoginInfo('User does not have access to this site.');
        } else if (resp.status === 400) {
          setInfo({
            ...info,
            password: '',
          });
          setLoginInfo('Sign in unsuccessful.  Contact Administrator.');
        }
      })
      .then((js) => {
        if (js !== undefined) {
          localStorage.setItem('userName', js.name);
          localStorage.setItem('typeUser', js.typeUser);
          localStorage.setItem('token', js.jwt);
          localStorage.setItem('exp', js.exp);
          history.replace(from);
          document.cookie = `username=${js.name}; expires=Sat, 31 Oct 2020 12:00:00 UTC; path=/`;
        }
      });
  }

  async function sendCrendentialsSignup() {
    const data = {
      userEmail: signup.email.trim(),
      password: signup.password.trim(),
      userName: signup.name.trim(),
    };
    const resp = await fetch('../../db/signup.php', {
      method: 'POST',
      headers: {
        Origin: '*',
        Accept: 'application/json',
      },
      body: JSON.stringify(data),
    });
    setsignup({
      ...signup,
      password: '',
    });

    if (resp.status === 401) {
      window.alert('Error on login. Check fields filled in properly.');
    }
    if (resp.status === 402) {
      window.alert(
        'Check that your password meets minimum password requirements.  Must be 20 or more characters and include uppercase letter, a number, and a special character'
      );
    }
    if (resp.status === 403 || resp.status === 404) {
      window.alert('Access denied.');
    }
    if (resp.status === 409) {
      window.alert('Proposed password is same as last password.');
    }
    if (resp.status === 410) {
      window.alert('Proposed password insecure, try again.');
    }
    if (resp.status === 202) {
      window.alert('Account established.  Please sign in.');
      setshowSignUp(false);
    }
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='userEmail'
            value={info.userEmail}
            onChange={(e) => {
              e.preventDefault();
              handleChange(e);
            }}
            autoComplete='email'
            autoFocus
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            value={info.password}
            autoComplete='current-password'
            onChange={(e) => {
              e.preventDefault();
              handleChange(e);
            }}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            onClick={(e) => {
              e.preventDefault();
              if (validateCrendentials() === true) {
                sendCrendentials();
              }
            }}
            className={classes.submit}>
            {localStorage.getItem('userName') &&
            localStorage.getItem('exp') > Date.now() / 1000
              ? 'Signed in already'
              : 'Sign In'}
          </Button>
          <br />
          <br />
          {/* //TODO remove comment if you want to expose sign up */}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            onClick={(e) => {
              e.preventDefault();
              setshowSignUp(!showSignUp);
            }}
            className={classes.submit}>
            {showSignUp === true ? 'Hide' : 'Sign Up'}
          </Button>

          {showSignUp === true ? (
            <>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='name'
                label='Signup user name'
                type='text'
                id='signupName'
                value={signup.name}
                onChange={(e) => {
                  e.preventDefault();
                  handleChangeSignUp(e);
                }}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='email'
                label='Signup user email'
                type='text'
                id='signupEmail'
                value={signup.email}
                onChange={(e) => {
                  e.preventDefault();
                  handleChangeSignUp(e);
                }}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                help
                helperText='Password - Must contain a lowercase and uppercase letter, a number, a special character and at least 20 characters'
                id='password'
                type='password'
                value={signup.password}
                onChange={(e) => {
                  e.preventDefault();
                  handleChangeSignUp(e);
                }}
              />{' '}
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                onClick={(e) => {
                  e.preventDefault();
                  if (validateCrendentialsSignup() === true) {
                    sendCrendentialsSignup();
                  }
                }}
                className={classes.submit}>
                Sign up
              </Button>
            </>
          ) : null}
          {loginInfo !== '' ? (
            <h4 style={{ color: 'red', textAlign: 'center' }}>{loginInfo}</h4>
          ) : (
            ''
          )}
        </form>
      </div>
    </Container>
  );
}
