import { useQuery } from 'react-query';

const getUICs = async () => {
  const data = await fetch('/db/getUICs.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
  });
  return data.json();
};

export default function useUICs() {
  return useQuery(['UICsset'], getUICs, {
    staleTime: 20 * 60 * 1000,
    placeholderData: [],
  });
}
