import React from 'react';

//MissionContext used for mission database entry
export const MissionContext = React.createContext();
export const MissionNumberContext = React.createContext();

//ItineraryInfoContext has array of routes for the entire mission
export const ItineraryInfoContext = React.createContext();

//AirfieldContext has all the information for airfields on the itinerary
export const AirfieldContext = React.createContext();
export const PPRContext = React.createContext();
export const AirliftContext = React.createContext();
export const FilterSetting = React.createContext();
export const DownloadFoldersFiles = React.createContext();
export const MissionSet = React.createContext();

//All routes for mission in question for particular type aircraft
export const RoutesForMission = React.createContext();
//All airports for particular type aircraft
export const AirportsForMission = React.createContext();
//All routes for particular type aircraft
export const GougeForMission = React.createContext();

export const PPRValidContext = React.createContext();
export const ContactsValidContext = React.createContext();
export const Events = React.createContext();
export const Event = React.createContext();
export const LocalEvent = React.createContext();
export const RefreshKey = React.createContext();
export const ICAOreadable = React.createContext();
export const oldSchoolSetting = React.createContext();
export const AirliftFilter = React.createContext();
