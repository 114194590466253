import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import MapModal from '../Components/Modal/GoogleMapModalWithButton.jsx';

export default function RouteDetails({ selectedRoute }) {
  function pad2(number) {
    return (number < 10 ? '0' : '') + number;
  }

  if (selectedRoute === '') {
    return (
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
        style={{
          backgroundColor: 'yellow',
          textAlign: 'center',
          padding: '2px',
          height: '5vh',
        }}>
        <Grid item xs>
          <h4>Route Details</h4>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='flex-start'
      style={{
        backgroundColor: 'yellow',
        padding: '2px',
      }}>
      <Hidden lgDown>
        <Grid container item justify='center' direction='row'>
          <Grid item xs={12}>
            <h4 style={{ textAlign: 'center' }}>Route Details</h4>
          </Grid>
        </Grid>
      </Hidden>
      <Grid container item xs direction='row'>
        <Grid container item xs direction='column'>
          <Grid item xs>
            Route: {selectedRoute?.pod}-{selectedRoute?.poa}
          </Grid>
          <Grid item xs>
            Enroute Time:{' '}
            {selectedRoute.routeTime === undefined
              ? ''
              : Math.floor(selectedRoute?.routeTime / 60) +
                '+' +
                pad2(selectedRoute?.routeTime % 60)}
          </Grid>
          <Grid item xs>
            Validated: {selectedRoute?.valdate?.split(' ')[0]}
          </Grid>
          <Grid item xs>
            FIR's covered: {selectedRoute?.firs}
          </Grid>
        </Grid>
        <Hidden mdDown>
          <Grid
            container
            item
            xs
            direction='column'
            alignItems='center'
            justify='center'>
            <Grid item xs>
              {Object.entries(selectedRoute).length !== 0 ? (
                <Button
                  style={{ margin: '2px' }}
                  size='small'
                  variant='contained'
                  onClick={() => {
                    navigator.clipboard.writeText(selectedRoute.route);
                  }}>
                  Route Copy
                </Button>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs>
              {Object.entries(selectedRoute).length !== 0 ? (
                <center>
                  <MapModal
                    routeData={selectedRoute}
                    buttonTitle={'Google Map'}
                  />
                </center>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
}
