import Chip from '@material-ui/core/Chip';
import React from 'react';
import AirliftWrapper from '../../Utils/AirliftWrapper';
import '../App.css';

export const AirliftChipImport = ({ props, handleChipDelete }) => {
  return (
    <AirliftWrapper airlift={props}>
      <Chip
        className='mt-2'
        style={{
          fontSize: '1rem',
          margin: '1px',
          backgroundColor:
            props?.HAZ === '1'
              ? 'red'
              : props?.DV === '1'
              ? 'yellow'
              : 'antiquewhite',
        }}
        clickable
        data={props}
        onDelete={() => handleChipDelete(props.airliftName)}
        label={props.airliftName}
        name={props.airliftName}
      />
    </AirliftWrapper>
  );
};
