import { TableCell, TableRow } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import React, { useEffect, useState } from 'react';
import PPREntry from './PPREntry';
import { translateToArray } from './Utils';

export default function PPRTable({ props, routes, airports }) {
  //All PPRs in DB

  //Array of airlifts on mission by leg
  const [airliftArr, setAirliftArr] = useState();
  const [pprData, setpprData] = useState([]);

  let ppr_array = [];
  useEffect(() => {
    setAirliftArr(translateToArray(props.airlifts1));
    //debugger;
    const routeID_array = props?.routeID.trim().split(' ') || [];
    routeID_array.forEach((route, index) => {
      const route_info = routes.find((elem) => {
        return elem.id === route;
      });
      const ppr_current = {};
      ppr_current.number = index;
      ppr_current.airfieldICAO = route_info?.poa;
      ppr_current.beforeAirfield = route_info?.pod;
      ppr_current.typeAircraft = props?.typeAircraft;
      ppr_current.requiredPPR = true;
      ppr_current.notes = '';
      ppr_current.typeAircraft = props?.typeAircraft;
      ppr_current.pprID =
        props?.cnv +
        '-' +
        props.updateNumber +
        '-' +
        route_info?.poa +
        '-' +
        index;

      //pprIDold just for the old style PPRID without ITS update
      ppr_current.pprIDold = props?.cnv + '-' + route_info?.poa + '-' + index;
      ppr_current.last = index === routeID_array.length - 1 ? true : false;
      ppr_array.push(ppr_current);

      let airfield_info_tz = airports.find(function (element) {
        return element.ICAO === ppr_array[index].airfieldICAO;
      });
      ppr_array[index].airfieldTimeZone = airfield_info_tz?.databaseTimezone;

      if (index !== 0) {
        const back_index = index - 1;
        ppr_array[back_index].nextAirfield = route_info?.poa;
      }
    });

    const takeoffTimes_array = props.takeoffTimes.split(' ');
    takeoffTimes_array.forEach((timeTakeoff, index) => {
      if (index !== 0) {
        const back_index = index - 1;
        ppr_array[back_index].departureDateTime = timeTakeoff;

        //ppr_array[back_index].departureTimeLocal = changeTime(timeTakeoff,)
      }
    });
    const landTimes_array = props.landTimes.split(' ');
    landTimes_array.forEach((timeLand, index) => {
      ppr_array[index].arrivalDateTime = timeLand;
    });
    const airlift_array = props.airlifts1.split('/');
    airlift_array.pop();
    airlift_array.forEach((airlift, index) => {
      airlift = airlift.trim();
      ppr_array[index].airlift = airlift;
    });
    setpprData(ppr_array);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airports, props, routes]);

  return (
    <>
      <Table
        aria-label='customized table'
        size='small'
        style={{ width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
        <TableBody>
          {pprData.map((airfield, index) => {
            return (
              <React.Fragment key={index + 'PPRFrag'}>
                <PPREntry
                  key={index + 'mainPPR'}
                  mission={props}
                  data={airfield}
                  airliftArr={airliftArr}
                  buno={props.buno}
                />
                {index !== pprData.length - 1 ? (
                  <TableRow key={index + 'extra'}>
                    <TableCell style={{ borderStyle: 'none' }}></TableCell>
                  </TableRow>
                ) : null}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
