import React, { useEffect, useState } from 'react';
import PPREntry from './PPREntry';
import { translateToArray } from './Utils';

export default function PPRTable({ data, routes, airports }) {
  const [airliftArr, setAirliftArr] = useState();
  const [pprData, setpprData] = useState([]);

  let ppr_array = [];

  useEffect(() => {
    setAirliftArr(translateToArray(data.airlifts1));
    const routeID_array = data?.routeID.trim().split(' ') || [];
    routeID_array.forEach((route, index) => {
      const route_info = routes.find((elem) => {
        return elem.id === route;
      });
      const ppr_current = {};
      ppr_current.number = index;
      ppr_current.airfieldICAO = route_info?.poa;
      ppr_current.beforeAirfield = route_info?.pod;
      ppr_current.typeAircraft = data?.typeAircraft;
      ppr_current.requiredPPR = true;
      ppr_current.notes = '';
      ppr_current.typeAircraft = data?.typeAircraft;

      ppr_current.pprID =
        data?.cnv +
        '-' +
        data.updateNumber +
        '-' +
        route_info?.poa +
        '-' +
        index;

      //pprIDold just for the old style PPRID without ITS update
      ppr_current.pprIDold = data?.cnv + '-' + route_info?.poa + '-' + index;

      ppr_current.last = index === routeID_array.length - 1 ? true : false;
      ppr_array.push(ppr_current);

      let airfield_info_tz = airports.find(function (element) {
        return element.ICAO === ppr_array[index].airfieldICAO;
      });
      ppr_array[index].airfieldTimeZone = airfield_info_tz?.databaseTimezone;

      if (index !== 0) {
        const back_index = index - 1;
        ppr_array[back_index].nextAirfield = route_info?.poa;
      }
    });

    const takeoffTimes_array = data.takeoffTimes.split(' ');
    takeoffTimes_array.forEach((timeTakeoff, index) => {
      if (index !== 0) {
        const back_index = index - 1;
        ppr_array[back_index].departureDateTime = timeTakeoff;

        //ppr_array[back_index].departureTimeLocal = changeTime(timeTakeoff,)
      }
    });
    const landTimes_array = data.landTimes.split(' ');
    landTimes_array.forEach((timeLand, index) => {
      ppr_array[index].arrivalDateTime = timeLand;
    });
    const airlift_array = data.airlifts1.split('/');
    airlift_array.pop();
    airlift_array.forEach((airlift, index) => {
      airlift = airlift.trim();
      ppr_array[index].airlift = airlift;
    });
    setpprData(ppr_array);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  //TODO removed dependencies due to maximum depth
  // }, [airports, ppr_array, props, routes]);

  return (
    <div
      style={{
        textAlign: 'center',
        margin: '10px',
        marginTop: '20px',
        borderBottom: '10px',
      }}>
      <h3>PPR Information</h3>
      {pprData.map((airfield, index) => {
        return (
          <PPREntry
            key={index + 'pprEntry'}
            mission={data}
            routes={routes}
            airport={airports}
            data={airfield}
            airliftArray={airliftArr}
            indexpointer={index}
          />
        );
      })}
    </div>
  );
}
