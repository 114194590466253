import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import { MissionInWork } from './MissionGeneratorStore';

export default function DutyDay({ data }) {
  const [missionInWork] = useContext(MissionInWork);
  const [dutyDay, setdutyDay] = useState('');

  useEffect(() => {
    let dutyCalc = 120;
    function pad2(number) {
      return (number < 10 ? '0' : '') + number;
    }

    for (let i = data.rowNumber; i >= 0; i--) {
      dutyCalc += Number(missionInWork[i].roundedEnrouteTime);

      if (
        missionInWork[i - 1]?.RON === true ||
        missionInWork[i - 1]?.crewChange === true ||
        i === 0
      ) {
        break;
      } else {
        dutyCalc += Number(missionInWork[i - 1]?.turnTime.minutes);
      }
    }
    let dutyCalcString = Math.floor(dutyCalc / 60) + '+' + pad2(dutyCalc % 60);
    setdutyDay(dutyCalcString);

    return () => {
      setdutyDay(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionInWork]);

  return (
    <Typography variant='h5' color='initial'>
      {dutyDay}
    </Typography>
  );
}
