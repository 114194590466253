import { Button } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { ContactsValidContext } from '../store';
import useAirlifts from '../Utils/Hooks/useAirlifts';
import ContactEntry from './ContactEntry';

export default function ContactPage({ data }) {
  const [display, setdisplay] = useState(false);

  function changeDisplay() {
    display === true ? setdisplay(false) : setdisplay(true);
  }

  return (
    <>
      <div style={{ textAlign: 'center', margin: '10px' }}>
        <Button
          variant='outlined'
          href=''
          onClick={() => {
            changeDisplay();
          }}>
          {display ? 'Hide Contact Information' : 'Show Contact Information'}
        </Button>
      </div>
      {display ? <ContactTable key={'asdert'} data={data} /> : null}
    </>
  );
}

function ContactTable({ data }) {
  const { data: airliftInformation, loading } = useAirlifts();
  const [, setContactsValid] = useContext(ContactsValidContext);
  const [airliftData, setairliftData] = useState([]);

  let lift_set = new Set();

  useEffect(() => {
    if (
      data.airlifts1 !== null &&
      data.airlifts1 !== '' &&
      airliftInformation
    ) {
      let airliftData_array = [];
      let airlift_array_parsed;
      let airlift_array;
      airlift_array = data.airlifts1.split('/').map((elem) => {
        return elem.trim();
      });

      airlift_array_parsed = airlift_array.filter((elem) => {
        return elem !== '';
      });

      airlift_array_parsed.forEach((element) => {
        let element_array = element.split(',').map((elem) => {
          return elem.trim();
        });
        element_array.forEach((elem) => {
          lift_set.add(elem);
        });
      });
      if (lift_set === '' || lift_set === null) {
        alert('No airlifts attached to this mission');
      }

      var validCheck = true;

      [...lift_set].forEach((liftName) => {
        let lift = airliftInformation.find((airlift) => {
          return liftName === airlift.airliftName;
        });
        if (lift !== undefined) {
          //alert('Problem loading Airlift Data. Return to Schedule Page.');
          //debugger;
          airliftData_array.push(lift);
          if (lift.vPAX && lift.vPOC && lift.vCargo && lift.vPhone) {
            validCheck = validCheck && true;
          } else {
            validCheck = false;
          }
        }
      });
      setContactsValid(validCheck);
      setairliftData(airliftData_array);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airliftInformation]);

  if (data.airlifts1 === null || data.airlifts1 === '') {
    return (
      <span style={{ textAlign: 'center' }}>
        No airlifts assigned to this mission
      </span>
    );
  }

  return (
    <div>
      {loading === true ? (
        <span style={{ textAlign: 'center' }}>Loading data...</span>
      ) : null}
      {airliftData.map((data, index) => {
        return (
          <div key={index}>
            <ContactEntry key={data.id + 'c'} airliftData={data} />
            <br />
          </div>
        );
      })}
    </div>
  );
}
