import { useQuery } from 'react-query';

const getMissionData = async () => {
  const data = await fetch('/db/masdfasern.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
  });
  return data.json();
};

export default function useMissionDataPartial() {
  return useQuery(['missionDataPartial'], getMissionData, {
    staleTime: 2 * 60 * 1000,
    refetchInterval: 3 * 60 * 1000,
  });
}
