import {
  Button,
  createMuiTheme,
  Hidden,
  ThemeProvider,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import '../App.css';
import MapModal from '../Components/Modal/GoogleMapModalWithButton';

const { DateTime, Duration } = require('luxon');

export default function Itinerary({ data, routes, airports, gouge }) {
  return (
    <ThemeProvider
      theme={(theme) =>
        createMuiTheme({
          overrides: {
            MuiTableCell: {
              root: {
                '@media (max-width:1100px)': {
                  fontSize: '0.7rem',
                },
                '@media (max-width:800px)': {
                  fontSize: '0.6rem',
                },
              },
              sizeSmall: {
                '@media (max-width:1000px)': {
                  padding: '6px 10px 6px 10px',
                },
              },
            },
          },
        })
      }>
      <div style={{ textAlign: 'center', margin: '10px', marginTop: '20px' }}>
        <h3>Itinerary</h3>
        <Table
          size='small'
          style={{
            backgroundColor: 'azure',
            marginTop: '15px',
            width: '95%',
            marginLeft: 'auto',
            marginRight: 'auto',
            outline: 'solid blue',
          }}>
          <TableHead>
            <TableRow>
              <Hidden mdDown>
                <TableCell align='center'>Leg</TableCell>
              </Hidden>
              <TableCell align='center'>POD</TableCell>
              <TableCell align='center'>Date</TableCell>
              <TableCell align='center'>Dep. Time Local</TableCell>
              <Hidden xsDown>
                <TableCell align='center'>Dep. Time Zulu</TableCell>
              </Hidden>
              <TableCell align='center'>POA</TableCell>
              <TableCell align='center'>Arr. Time Local</TableCell>
              <Hidden xsDown>
                <TableCell align='center'>Arr. Time Zulu</TableCell>
              </Hidden>
              <Hidden lgDown>
                <TableCell align='center'>Leg Time</TableCell>
              </Hidden>
              <Hidden mdDown>
                <TableCell align='center'>RON/CC</TableCell>
              </Hidden>
              <Hidden xsDown>
                <TableCell align='center'>Crew Day</TableCell>
                <Hidden lgDown>
                  <TableCell align='center'>On Deck Time</TableCell>
                </Hidden>
              </Hidden>
              <Hidden lgDown>
                <TableCell align='center'>Route ID</TableCell>
              </Hidden>
              <Hidden xsDown>
                <TableCell align='center'>Skyvector</TableCell>
              </Hidden>
              <TableCell align='center'>Google Maps</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{ItineraryTable(data, routes, airports, gouge)}</TableBody>
        </Table>
      </div>
    </ThemeProvider>
  );
}

const ItineraryTable = (mission, routes, airport, gouge) => {
  const mission_legs = mission.routeID.split(' ');
  var crewDay = Duration.fromObject({ minutes: 120 });
  function changeTime(timeInput, zoneInput) {
    const timeZulu = DateTime.fromISO(timeInput, { zone: 'UTC' });
    const timeLocal = timeZulu.setZone(zoneInput);
    return timeLocal;
  }
  return (
    <>
      {mission_legs.map((data, index) => {
        const route_in_work = routes.find((elem) => {
          return elem.id === data;
        });
        if (route_in_work) {
          const podTimeZone = airport.find((elem) => {
            return elem.ICAO === route_in_work.pod;
          });
          const poaTimeZone = airport.find((elem) => {
            return elem.ICAO === route_in_work.poa;
          });
          let timePODlocal = changeTime(
            mission.takeoffTimes.split(' ')[index],
            podTimeZone.databaseTimezone
          );
          let timePOAlocal = changeTime(
            mission.landTimes.split(' ')[index],
            poaTimeZone.databaseTimezone
          );
          const legTime = timePOAlocal.diff(timePODlocal, ['hours', 'minutes']);

          if (mission.fullMissionDataObject) {
            if (
              mission?.fullMissionDataObject[index - 1]?.RON ||
              mission?.fullMissionDataObject[index - 1]?.crewChange
            ) {
              crewDay = Duration.fromObject({ minutes: 120 });
              crewDay = crewDay.plus(timePOAlocal.diff(timePODlocal));
            } else if (index !== 0) {
              crewDay = crewDay.plus(timePOAlocal.diff(timePODlocal)).plus(
                Duration.fromObject({
                  minutes:
                    mission.fullMissionDataObject[index - 1].turnTime.minutes,
                })
              );
            } else {
              crewDay = crewDay.plus(timePOAlocal.diff(timePODlocal));
            }
          } else {
            crewDay = Duration.fromObject({ minutes: 0 });
          }

          return (
            <TableRow key={index + 'row'}>
              <Hidden mdDown>
                <TableCell>{index + 1}</TableCell>
              </Hidden>
              <TableCell align='center'>{route_in_work.pod}</TableCell>
              <Hidden xsDown>
                <TableCell align='center'>
                  {DateTime.fromISO(
                    mission.takeoffTimes.split(' ')[index]
                  ).toFormat('dd MMM yyyy (ccc)')}
                </TableCell>
              </Hidden>
              <Hidden smUp>
                <TableCell align='center'>
                  {DateTime.fromISO(
                    mission.takeoffTimes.split(' ')[index]
                  ).toFormat('dd MMM')}
                </TableCell>
              </Hidden>
              <TableCell align='center'>
                {changeTime(
                  mission.takeoffTimes.split(' ')[index],
                  podTimeZone.databaseTimezone
                ).toFormat('HHmm')}
                L (
                {changeTime(
                  mission.takeoffTimes.split(' ')[index],
                  podTimeZone.databaseTimezone
                ).offset > 0
                  ? '+'
                  : ''}
                {changeTime(
                  mission.takeoffTimes.split(' ')[index],
                  podTimeZone.databaseTimezone
                ).offset / 60}
                )
              </TableCell>
              <Hidden xsDown>
                <TableCell align='center'>
                  {DateTime.fromISO(
                    mission.takeoffTimes.split(' ')[index]
                  ).toFormat('HHmm')}{' '}
                  Z
                </TableCell>
              </Hidden>
              <TableCell align='center'>{route_in_work.poa}</TableCell>

              <TableCell align='center'>
                {changeTime(
                  mission.landTimes.split(' ')[index],
                  poaTimeZone.databaseTimezone
                ).toFormat('HHmm')}
                L (
                {changeTime(
                  mission.landTimes.split(' ')[index],
                  poaTimeZone.databaseTimezone
                ).offset > 0
                  ? '+'
                  : ''}
                {changeTime(
                  mission.landTimes.split(' ')[index],
                  poaTimeZone.databaseTimezone
                ).offset / 60}
                )
              </TableCell>
              <Hidden xsDown>
                <TableCell align='center'>
                  {DateTime.fromISO(
                    mission.landTimes.split(' ')[index]
                  ).toFormat('HHmm')}{' '}
                  Z
                </TableCell>
              </Hidden>
              <Hidden lgDown>
                <TableCell align='center'>
                  {legTime.toFormat('hh+mm')} leg time
                </TableCell>
              </Hidden>
              <Hidden mdDown>
                <TableCell>
                  {mission?.fullMissionDataObject[index]?.RON
                    ? mission?.fullMissionDataObject[index]?.crewChange
                      ? 'RON/CC'
                      : 'RON'
                    : mission?.fullMissionDataObject[index]?.crewChange
                    ? 'CC'
                    : ''}
                </TableCell>
              </Hidden>
              <Hidden xsDown>
                <TableCell
                  align='center'
                  style={{
                    backgroundColor:
                      crewDay > Duration.fromObject({ minutes: 900 })
                        ? 'red'
                        : crewDay > Duration.fromObject({ minutes: 780 })
                        ? 'yellow'
                        : '',
                  }}>
                  {crewDay.toFormat('hh+mm')}
                </TableCell>
              </Hidden>
              <Hidden lgDown>
                <TableCell align='center'>
                  {Duration.fromObject({
                    minutes: mission.turnTime.split(' ')[index],
                  }).toFormat('hh+mm')}
                </TableCell>
              </Hidden>
              <Hidden lgDown>
                <TableCell align='center'>{mission_legs[index]}</TableCell>
              </Hidden>
              <Hidden xsDown>
                <TableCell align='center'>
                  <Button
                    variant='outlined'
                    color='primary'
                    href={route_in_work.mapLink}
                    target='_blank'
                    rel='noopener noreferrer'>
                    Link
                  </Button>
                </TableCell>
              </Hidden>
              <TableCell align='center'>
                <MapModal
                  routeData={route_in_work}
                  key={index + 'mm'}
                  buttonTitle={'Google Map'}
                />
              </TableCell>
            </TableRow>
          );
        } else {
          return (
            <TableRow key={index + 'row'} style={{ backgroundColor: 'red' }}>
              <TableCell align='center' colSpan={16}>
                Route not found while building itinerary. This is likely due to
                the route no longer existing in the database. Submit a 'Bug
                Report' if problem persists. Problem Route ID:{data}
              </TableCell>
            </TableRow>
          );
        }
      })}
    </>
  );
};
