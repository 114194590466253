import React from 'react';
import { Link } from 'react-router-dom';

export default function MyFallbackComponentPPR({ componentStack, error }) {
  console.log('(Myfallback comp) Error: ', error.toString());
  console.log('(Myfallback comp) Stacktrace:: ', componentStack);
  return (
    <div
      style={{
        textAlign: 'center',
        margin: '10px',
        marginTop: '20px',
        outline: 'auto',
      }}>
      <h3>PPR Information</h3>
      <h3>
        Whoops! There was an error!{' '}
        <span role='img' aria-label='Hmm'>
          🤔
        </span>
      </h3>
      <h4 style={{ textAlign: 'center', color: 'blue' }}>
        <Link
          to={{
            pathname: `/schedule`,
          }}>
          Click here to try again
        </Link>
      </h4>
      <h4 style={{ textAlign: 'center', color: 'red' }}>
        Submit a bug report if you have continued issues.
      </h4>
      <h5 style={{ textAlign: 'center', color: 'red', marginTop: '5px' }}>
        Error Info: {error.toString()}
      </h5>
    </div>
  );
}
