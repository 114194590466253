import { FormControlLabel, Grid, Hidden, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import he from 'he';
import produce from 'immer';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import usePPRData from '../../../Utils/Hooks/usePPRdata';
import { ExportPPR } from './Export/ExportPPR';
import { FileUpload } from './FileUpload';
const { DateTime } = require('luxon');

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const PPRHeader = ({
  data,
  pprInfo,
  setpprInfo,
  setChanged,
  airliftArr,
  buno,
}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ event, name, pprInfo, data }) => {
      await updatePPRstatus({ event, name, pprInfo, data });
    },
    {
      onMutate: async ({ event, name, pprInfo, data }) => {
        //console.log(name, pprInfo, event.target.value);
        await queryClient.cancelQueries(['PPRdata']);
        const previousPPRdata = queryClient.getQueryData(['PPRdata']);

        if (name === 'pprRequired') {
          queryClient.setQueryData(
            ['PPRdata'],
            produce(previousPPRdata, (draft) => {
              const index = draft.findIndex(
                (PPR) => PPR.pprID === pprInfo.pprID
              );
              if (index !== -1) draft[index].pprRequired = event.target.value;
            })
          );
        }

        if (name === 'pprStatus') {
          queryClient.setQueryData(
            ['PPRdata'],
            produce(previousPPRdata, (draft) => {
              const index = draft.findIndex(
                (PPR) => PPR.pprID === pprInfo.pprID
              );
              if (index !== -1) draft[index].pprStatus = event.target.value;
            })
          );
        }

        return [previousPPRdata, name, event];
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) => {
        alert('Error saving');
        queryClient.setQueryData(['PPRdata'], previousValue[0]);
        if (previousValue[1] === 'pprRequired') {
          setpprInfo({
            ...pprInfo,
            pprRequired: !previousValue[2].target.value,
          });
        }
        if (previousValue[1] === 'pprStatus') {
          setpprInfo({
            ...pprInfo,
          });
        }
      },
      // After success or failure, refetch the todos query
      onSuccess: () => {
        //queryClient.fetchQuery(['PPRdata']);
      },
    }
  );

  return (
    <>
      <TableCell align='center'>
        <Typography variant='h4' color='initial'>
          {data.airfieldICAO}
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <FormControlLabel
          control={
            <Switch
              name='pprRequired'
              checked={pprInfo.pprRequired}
              onChange={(event) => {
                event.preventDefault();
                console.log('changed');
                setpprInfo({
                  ...pprInfo,
                  pprRequired: event.target.checked,
                });
                const name = event.target.name;
                mutation.mutate({ event, name, pprInfo, data });
              }}
              value={pprInfo.pprRequired}
              color='primary'
            />
          }
          label='PPR required'
        />
      </TableCell>
      <TableCell align='center'>{data.airlift}</TableCell>
      <Hidden lgDown>
        <TableCell align='center'>
          {data.airfieldICAO === 'LGSA' ? (
            <ExportPPR data={data} airliftArr={airliftArr} buno={buno} />
          ) : null}
        </TableCell>
      </Hidden>
      <TableCell align='center'>
        <PPRImport
          pprInfo={pprInfo}
          setpprInfo={setpprInfo}
          setChanged={setChanged}
        />
      </TableCell>
      <TableCell align='center'>
        <FileUpload pprID={pprInfo.pprID} />
      </TableCell>
      <TableCell align='center'>
        Status update by:{' '}
        {pprInfo.pprChangedBy === undefined ||
        pprInfo.pprChangedBy === null ||
        pprInfo.pprChangedBy === ''
          ? 'Nobody'
          : pprInfo.pprChangedBy.split(';')[1] +
            ' on ' +
            DateTime.fromISO(pprInfo.pprChangedBy.split(';')[2]).toFormat(
              'DD HHmm'
            )}
      </TableCell>
    </>
  );
};

const PPRImport = ({ pprInfo, setpprInfo, setChanged }) => {
  const [open, setOpen] = useState(false);
  const { data: pprInfoAll } = usePPRData();
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [PPRtoImport, setPPRtoImport] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    setPPRtoImport(event.target.value);
  };

  const handleImport = () => {
    setOpen(false);
    setChanged(true);
    setpprInfo({
      ...pprInfo,
      pprServices: PPRtoImport.pprServices,
      pprNumber: PPRtoImport.pprNumber,
      pprAgent: PPRtoImport.pprAgent,
      pprContact: PPRtoImport.pprContact,
      notes: PPRtoImport.notes,
    });
  };

  const cssTest = {
    margin: '10px',
    minWidth: '120px',
    paddingLeft: '35px',
  };

  const formCtrl = {
    width: '200px',
  };

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={handleOpen}>
        Import PPR Data
      </Button>
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Grid
            container
            spacing={2}
            direction='column'
            justify='center'
            alignItems='center'>
            <Grid item>
              <Typography variant='h5' color='initial'>
                Choose a PPR for import
              </Typography>
            </Grid>
            <Grid item>
              <FormControl variant='outlined' style={formCtrl}>
                <InputLabel shrink htmlFor='importPPR' style={cssTest}>
                  Import
                </InputLabel>
                <Select
                  name='importPPR'
                  id='importPPR'
                  inputProps={{
                    name: 'ppr ID',
                    id: 'importPPR',
                  }}
                  onChange={handleChange}
                  input={<OutlinedInput name='importPPR' id='importPPR' />}>
                  <MenuItem value='none'>
                    <em>None</em>
                  </MenuItem>
                  {pprInfoAll.map((element) => {
                    if (
                      element.pprICAO === pprInfo.pprICAO &&
                      element.pprID.split('-')[0] ===
                        pprInfo.pprID.split('-')[0]
                    ) {
                      return (
                        <MenuItem
                          key={element.id}
                          display={element.pprID}
                          value={element}>
                          {element.pprID}
                        </MenuItem>
                      );
                    } else return null;
                  })}
                </Select>
                <FormHelperText>Choose a PPR</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography variant='h6' color='initial'>
                {PPRtoImport !== {}
                  ? `${
                      PPRtoImport?.pprNumber !== '' && PPRtoImport.pprNumber
                        ? `PPR Number: ${he.unescape(
                            he.decode(PPRtoImport.pprNumber)
                          )}, `
                        : ''
                    }${
                      PPRtoImport?.pprServices !== '' && PPRtoImport.pprServices
                        ? `Services: ${he.unescape(
                            he.decode(PPRtoImport.pprServices)
                          )}, `
                        : ''
                    }${
                      PPRtoImport?.pprAgent !== '' && PPRtoImport.pprAgent
                        ? `Agent: ${he.unescape(
                            he.decode(PPRtoImport.pprAgent)
                          )}, `
                        : ''
                    }${
                      PPRtoImport?.pprContact !== '' && PPRtoImport.pprContact
                        ? `Contact: ${he.unescape(
                            he.decode(PPRtoImport.pprContact)
                          )}, `
                        : ''
                    }${
                      PPRtoImport?.notes !== '' && PPRtoImport.notes
                        ? `Notes: ${he.unescape(he.decode(PPRtoImport.notes))} `
                        : ''
                    }`
                  : null}
              </Typography>
            </Grid>
            <Grid container item direction='row' spacing={2} justify='center'>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={handleImport}>
                  Import data
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='secondary'
                  size='small'
                  onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};

const updatePPRstatus = async ({ event, name, pprInfo, data }) => {
  //This function called whenever pprStatus changes or pprRequired changes
  const nameTemp = localStorage.getItem('userName');
  const dateStamp = DateTime.utc().toISO();

  let pprTypeChange = '';
  let pprRequiredNew = '';
  let pprStatusNew = pprInfo.pprStatus;
  if (name === 'pprStatus') {
    pprTypeChange = name;
    pprRequiredNew = pprInfo.pprRequired;
    pprStatusNew = event.target.value;
  }
  if (name === 'pprRequired') {
    pprTypeChange = name;
    pprRequiredNew = event.target.checked;
    pprStatusNew = pprInfo.pprStatus;
  } else {
  }

  const pprHistoryNew = {
    status: pprStatusNew,
    date: dateStamp,
    name: nameTemp,
    beforeAirfield: data.beforeAirfield,
    nextAirfield: data.nextAirfield,
    arrivalDateTime: data.arrivalDateTime,
    departureDateTime: data.departureDateTime,
    airfieldTimeZone: data.airfieldTimeZone,
    typeAircraft: data.typeAircraft,
  };

  const history = pprInfo.pprHistory;
  history.unshift(pprHistoryNew);

  const dataUpdate = {
    pprChange: pprTypeChange,
    pprID: pprInfo.pprID,
    pprStatus: pprStatusNew,
    pprRequired: pprRequiredNew,
    pprICAO: pprInfo.pprICAO,
    name: nameTemp,
    date: dateStamp,
    pprHistory: JSON.stringify(history),
  };

  const resp = await fetch('/db/updatePPRstatus.schedule.db.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(dataUpdate),
  });
  if (!resp.ok) {
    throw new Error('Network response was not ok');
  }
  return resp.json();
};
