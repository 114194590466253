import React from 'react';
import StandardLoadingFallback from '../../Loading/StandardLoadingFallback';
import useAirports from '../../Utils/Hooks/useAirports';
import TableWrapper from '../TableWrapper';

export default function AirportTable() {
  const { data, isFetching } = useAirports();
  const databaseName = 'airportInfo';
  const queryKey = ['airportsSet'];
  const columns = [
    {
      Header: `Airports`,
      columns: [
        {
          Header: 'ID',
          accessor: 'id',
          edit: false,
          minWidth: '5vw',
          maxWidth: '7vw',
        },
        {
          Header: 'ICAO',
          accessor: 'ICAO',
          minWidth: '5vw',
          maxWidth: '7vw',
        },
        {
          Header: 'Name',
          accessor: 'name',
          minWidth: '15vw',
          maxWidth: '17vw',
        },
        {
          Header: 'Country',
          accessor: 'country',
        },
        {
          Header: 'Time Zone',
          accessor: 'databaseTimezone',
        },
        {
          Header: 'Email',
          accessor: 'email',
          minWidth: '15vw',
          maxWidth: '17vw',
        },
        {
          Header: 'Notes',
          accessor: 'notes',
          minWidth: '15vw',
          maxWidth: '17vw',
        },
        {
          Header: 'PPR Notes',
          accessor: 'pprNotes',
          minWidth: '15vw',
          maxWidth: '17vw',
        },
      ],
    },
  ];

  if (isFetching === true) {
    return <StandardLoadingFallback />;
  }

  const rowsNotToEdit = ['ID'];

  return (
    <TableWrapper
      data={data}
      numberOfRowsToDisplay={20}
      columnsArray={columns}
      queryKey={queryKey}
      pagination={true}
      databaseName={databaseName}
      rowsNotToEdit={rowsNotToEdit}
    />
  );
}

/* const DeleteRow = ({ id, databaseName, queryKey }) => {
  const queryClient = useQueryClient();
  return (
    <Button
      size='small'
      variant='contained'
      onClick={async (e) => {
        confirmAlert({
          title: 'Confirm delete',
          message:
            'If you delete this row it will be gone forever.  Are you sure you want to proceed?',
          buttons: [
            {
              label: 'Yes',
              onClick: async () => {
                const data = {
                  action: 'delete',
                  databaseName: databaseName,
                  id: id,
                };
                const response = await fetch('/db/updateDB.php', {
                  method: 'POST',
                  withCredentials: true,
                  credentials: 'include',
                  headers: {
                    Accept: 'application/json',
                    Authorization: localStorage.getItem('token'),
                  },
                  body: JSON.stringify(data),
                });
                if (response.status === 401) {
                  console.log('Issue at (handle errors)', response.url);
                  window.alert('Error deleting');
                  throw Error(response.status);
                }
                if (!response.ok) {
                  window.alert('Error deleting');
                  throw Error(response.status);
                } else {
                  queryClient.setQueryData(queryKey, (old) => {
                    return produce(old, (draft) => {
                      const index = draft.findIndex((route) => route.id === id);
                      if (index !== -1) draft.splice(index, 1);
                    });
                  });
                }
              },
            },
            {
              label: 'No',
              onClick: () => {
                //Nothing
              },
            },
          ],
        });
      }}>
      Delete
    </Button>
  );
}; */
