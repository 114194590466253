import { ThemeProvider } from '@material-ui/core/styles';
import React, { lazy, Suspense, useState } from 'react';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import AircraftLineupTable from './AircraftLineup/AircraftLineupTable';
import './App.css';
import MissionDetailPageDet from './detmission/MissionDetail';
import DetSchedule from './DetSchedule/DetSchedule';
import EmailTable from './EmailTable/EmailTable';
import FeedbackTable from './Feedback/Feedback';
import FeedbackForm from './Feedback/FeedbackForm';
import SignIn from './login/Login.jsx';
import MissionGeneratorPage from './mission/MissionGenerator.jsx';
import MissionDetailPage from './MissionDetail/MissionDetail.jsx';
import NavTop from './nav.js';
import Schedule from './Schedule/Schedule';
import SplashPage from './Splash/Splash';
import {
  AirfieldContext,
  AirliftContext,
  DownloadFoldersFiles,
  FilterSetting,
  ItineraryInfoContext,
  MissionContext,
  MissionSet,
  PPRContext,
} from './store';
import AirportTable from './Table/Airports/AirportTable';
import LogTable from './Table/Log/LogTable';
import UICTable from './Table/UIC/UICtable';
import { theme } from './theme/theme';
import ScrollToTop from './Utils/scrolltotop.jsx';

const APACS = lazy(() => import('./APACS/APACS'));
const AirliftEntry = lazy(() => import('./AirliftEntry/AirliftEntry'));
const AirliftTable = lazy(() => import('./Table/Airlifts/AirliftTable'));
const GougeRoutesPage = lazy(() => import('./GougeRoutes/GougeRoutes'));
const WarningPage = lazy(() => import('./warning/WarningPage'));
const GougeTable = lazy(() => import('./Table/Gouge/GougeTable'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App(props) {
  const [missionObj, setMissionObj] = useState({
    APACSstatus: '',
    Contactsstatus: '',
    FinalReviewstatus: '',
    JALISstatus: null,
    LNOnotes: '',
    POA: null,
    POD: null,
    PPRstatus: '',
    airlifts1: '',
    cnv: '6666-07-4',
    emailCC: null,
    emailOthers: null,
    emailTo: null,
    enteredDate: '',
    firstTOlocal: '',
    landTimes: '',
    missionID: '',
    missionNotes: null,
    missionNumber: '',
    modification: '',
    routeID: '',
    takeoffTimes: '',
    turnTime: '',
    typeAircraft: '',
  });

  const [itineraryInfoObj, setItineraryInfoObj] = useState([]);
  const [airfieldInformation, setAirfieldInformation] = useState([]);
  const [pprInformation, setpprInformation] = useState([]);
  const [filterSetting, setFilterSetting] = useState('all');
  const [airliftInformation, setairliftInformation] = useState([]);
  const [downloadDir, setDownloadDir] = useState([]);
  const [missionSet, setMissionSet] = useState([]);

  const MyFallbackComponent = ({ componentStack, error }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Here’s what we know…</p>
      <p>
        <strong>Error:</strong> {error.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  const MainSuspenseFallback = () => (
    <div style={{ textAlign: 'center', marginTop: '20vh' }}>
      <img
        src='images/NavalAviator-PNG.png'
        height='200px'
        className='d-inline-block align-top'
        alt='EG logo'
      />
      <h3 style={{ textAlign: 'center', marginTop: '10vh' }}>Loading....</h3>
    </div>
  );

  const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE,
  };

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <AlertProvider template={AlertTemplate} {...options}>
          <MissionContext.Provider value={{ missionObj, setMissionObj }}>
            <ItineraryInfoContext.Provider
              value={[itineraryInfoObj, setItineraryInfoObj]}>
              <AirfieldContext.Provider
                value={[airfieldInformation, setAirfieldInformation]}>
                <PPRContext.Provider
                  value={[pprInformation, setpprInformation]}>
                  <AirliftContext.Provider
                    value={[airliftInformation, setairliftInformation]}>
                    <FilterSetting.Provider
                      value={[filterSetting, setFilterSetting]}>
                      <DownloadFoldersFiles.Provider
                        value={[downloadDir, setDownloadDir]}>
                        <MissionSet.Provider
                          value={[missionSet, setMissionSet]}>
                          <ErrorBoundary
                            FallbackComponent={MyFallbackComponent}>
                            <ThemeProvider theme={theme}>
                              <Suspense fallback={<MainSuspenseFallback />}>
                                <Router {...props}>
                                  <ScrollToTop />
                                  <NavTop {...props} />
                                  <Switch>
                                    <Route
                                      path={'/signin'}
                                      exact
                                      render={(props) => (
                                        <SignIn
                                          {...props}
                                          someProp={props.user}
                                        />
                                      )}
                                    />
                                    <PrivateRoute
                                      path='/generator'
                                      component={MissionGeneratorPage}
                                    />
                                    <PrivateRoute
                                      path={'/mission/:missionID'}
                                      component={MissionDetailPage}
                                    />
                                    <PrivateRoute
                                      path={'/detmission/:missionID'}
                                      component={MissionDetailPageDet}
                                    />
                                    <PrivateRoute
                                      path='/warnings'
                                      component={WarningPage}
                                    />
                                    <PrivateRoute
                                      path='/airliftentry'
                                      component={AirliftEntry}
                                    />
                                    <PrivateRoute
                                      path='/gougeroutes'
                                      component={GougeRoutesPage}
                                    />
                                    <PrivateRoute
                                      path='/UICtable/'
                                      component={UICTable}
                                    />
                                    <PrivateRoute
                                      path='/airliftTable/'
                                      component={AirliftTable}
                                    />
                                    <PrivateRoute
                                      path='/emails/'
                                      component={EmailTable}
                                    />
                                    <PrivateRoute
                                      path='/detschedule/'
                                      component={DetSchedule}
                                    />
                                    <PrivateRoute
                                      path='/feedback/'
                                      component={FeedbackTable}
                                    />
                                    <PrivateRoute
                                      path='/feedbackform/'
                                      component={FeedbackForm}
                                    />
                                    <PrivateRoute
                                      path='/apacs/'
                                      component={APACS}
                                    />
                                    <PrivateRoute
                                      path='/schedule'
                                      component={Schedule}
                                    />
                                    <PrivateRoute
                                      path='/airportsTable'
                                      component={AirportTable}
                                    />
                                    <PrivateRoute
                                      path='/gougeTable'
                                      component={GougeTable}
                                    />
                                    <PrivateRoute
                                      path='/logTable'
                                      component={LogTable}
                                    />
                                    <PrivateRoute
                                      path='/aircraftLineup'
                                      component={AircraftLineupTable}
                                    />
                                    <PrivateRoute
                                      path='/'
                                      component={SplashPage}
                                    />
                                  </Switch>
                                  <ReactQueryDevtools initialIsOpen={false} />
                                </Router>
                              </Suspense>
                            </ThemeProvider>
                          </ErrorBoundary>
                        </MissionSet.Provider>
                      </DownloadFoldersFiles.Provider>
                    </FilterSetting.Provider>
                  </AirliftContext.Provider>
                </PPRContext.Provider>
              </AirfieldContext.Provider>
            </ItineraryInfoContext.Provider>
          </MissionContext.Provider>
        </AlertProvider>
      </QueryClientProvider>
    </div>
  );
}
export default App;

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('userName') &&
        localStorage.getItem('exp') > Date.now() / 1000 ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
