//Pulls all available missions
//Aka missionSet

import { useQuery } from 'react-query';

const getFeedback = async () => {
  const data = await fetch('/db/feedback.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
  });
  return data.json();
};

export default function useMissionData() {
  return useQuery(['feedback'], getFeedback, {
    staleTime: 10 * 60 * 1000,
    placeholderData: [],
  });
}
