import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function StandardLoadingFallback() {
  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <h2>Loading...</h2>
      <FontAwesomeIcon icon={faPlaneDeparture} style={{ fontSize: '50px' }} />
    </div>
  );
}
