import Button from '@material-ui/core/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import React, { useContext } from 'react';
import useEmails from '../../Utils/Hooks/useEmails';
import { MissionInWork } from '../MissionGeneratorStore';
import AddBCCemails from './BCCemails';
import AddCCemails from './CCemails';

const { DateTime } = require('luxon');

export default function ITSbutton({ headerValues }) {
  const [missionInWork] = useContext(MissionInWork);
  const { data: emails } = useEmails();

  return (
    <Button
      color='primary'
      variant='contained'
      disabled={
        missionInWork.length === 0 ||
        headerValues.buno === '' ||
        headerValues.buno === null ||
        headerValues.callsign === '' ||
        (headerValues.mod === null && headerValues.updateNumber === null) ||
        (headerValues.mod === '' && headerValues.updateNumber === '') ||
        (headerValues.mod === undefined &&
          headerValues.updateNumber === undefined)
          ? true
          : false
      }
      size='small'
      onClick={(e) => {
        e.preventDefault();

        //Copy body of email to clipboard for pasting in email
        navigator.clipboard.writeText(
          createEmailBody(headerValues, missionInWork)
        );

        let emailSet = new Set();

        missionInWork.forEach((leg) => {
          if (leg.airlifts !== undefined) {
            leg.airlifts.forEach((element) => {
              let email_array = element?.emails.split(';');
              email_array.forEach((email) => {
                emailSet.add(email.trim());
              });
              emailSet.add(element.requestorEmail.trim());
            });
          } else {
            emailSet.add('M-NA-CTF-63AIRLOGOPS-GD@EU.NAVY.MIL');
          }
        });
        let airfieldSet = new Set();

        //Creat unique set of all airfields
        missionInWork.forEach((leg) => {
          airfieldSet.add(leg.pod);
          airfieldSet.add(leg.poa);
        });

        let emailSubject =
          headerValues.stage === 'JALIS'
            ? `CNV ${headerValues.callsign}, Mod ${headerValues.mod}`
            : `CNV ${headerValues.callsign}, Update ${headerValues.updateNumber}, Intent to support (ITS)`;

        window.location.href = `mailto:${[...emailSet].join(
          ';'
        )}?cc=${AddCCemails(
          [...airfieldSet],
          headerValues.type,
          emails
        )}&bcc=${AddBCCemails(
          [...airfieldSet],
          headerValues.type,
          emails
        )}&subject=${emailSubject}&body=Paste body here`;
      }}>
      <MailOutlineIcon />
      {headerValues.stage === 'JALIS'
        ? 'Generate JALIS email'
        : 'Generate ITS email'}
    </Button>
  );
}

function navTableData(missionInWork) {
  let nav_info = '';

  missionInWork.forEach((elem, index) => {
    let lift_list = [];
    if (elem?.airlifts) {
      elem.airlifts.forEach((lift) => {
        lift_list.push(lift.airliftName);
      });
    }

    nav_info += `Leg: ${index + 1}
Depart ${elem.pod}, ${DateTime.fromISO(elem.toTimeZulu).toFormat(
      'dd LLL yy'
    )} at ${DateTime.fromISO(elem.toTimeZulu, { zone: 'UTC' })
      .setZone('UTC')
      .toFormat('HH:mm')}(Z) / ${DateTime.fromISO(elem.toTimeZulu, {
      zone: 'UTC',
    })
      .setZone(elem.podTimeZone)
      .toFormat('HH:mm')}(L)
Arrive ${elem.poa}, ${DateTime.fromISO(elem.landTimeZulu).toFormat(
      'dd LLL yy'
    )} at ${DateTime.fromISO(elem.landTimeZulu, { zone: 'UTC' })
      .setZone('UTC')
      .toFormat('HH:mm')}(Z) / ${DateTime.fromISO(elem.landTimeZulu, {
      zone: 'UTC',
    })
      .setZone(elem.poaTimeZone)
      .toFormat('HH:mm')}(L)
with the following airlifts onboard: ${
      lift_list.length === 0 ? 'None' : lift_list.join(', ')
    }
`;
  });

  return nav_info;
}

function airliftInfo(missionInWork) {
  let airlift_row = '';
  let airlift_set = new Set();
  if (missionInWork.length !== 0) {
    missionInWork.forEach((elem) => {
      if (elem?.airlifts) {
        elem.airlifts.forEach((lift) => {
          let exists = false;
          airlift_set.forEach((el) => {
            if (el.airliftName === lift.airliftName) {
              exists = true;
            }
          });
          if (exists === false) {
            airlift_set.add(lift);
          }
        });
      }
    });
  }

  airlift_set.forEach((elem) => {
    let HAZ_class_set = new Set();
    if (Number(elem.HAZ) === 1) {
      let haz_array = elem.HAZdescription.split(';');
      haz_array.shift();
      haz_array.shift();
      let haz_class_array = haz_array.filter((_, i) => i % 5 === 0 || i === 0);
      haz_class_array.forEach((element) => {
        HAZ_class_set.add(element);
      });
    }

    airlift_row += `${elem.airliftName} / ${elem.UIC} / ${elem.POD} - ${
      elem.POA
    } / ${elem.PAX} PAX / ${elem.cargoWeight} lbs / ${
      Number(elem.HAZ) === 1
        ? `HAZ ? Y - ${[...HAZ_class_set].join(', ')}`
        : 'HAZ? N'
    } / ${Number(elem.DV) === 1 ? 'DV? Y' : 'DV? N'}
`;
  });

  return airlift_row;
}

function liftCoord(missionInWork) {
  //create a set of airlifts and then list each  LIFT UIC/ REQ COORD / EMAIL / PHONE
  let airlift_set = new Set();
  if (missionInWork.length !== 0) {
    missionInWork.forEach((elem) => {
      if (elem?.airlifts) {
        elem.airlifts.forEach((lift) => {
          let exists = false;
          airlift_set.forEach((el) => {
            if (el.airliftName === lift.airliftName) {
              exists = true;
            }
          });
          if (exists === false) {
            airlift_set.add(lift);
          }
        });
      }
    });
  }

  let liftCoord_string = '';
  if (airlift_set.length === 0) {
    liftCoord_string = `None
    `;
  } else {
    airlift_set.forEach((lift) => {
      liftCoord_string += `${lift.airliftName} / ${lift.UIC} / ${lift.liftRequestor} / ${lift.requestorEmail} / ${lift.requestorPhone}
`;
    });
  }
  return liftCoord_string;
}

function squadronContact(plane) {
  var squadronContact = '';
  //Determine squadron and set contact phone number
  if (plane === 'C-40') {
    squadronContact = '+39-335-743-5372';
  } else if (plane === 'C-130') {
    squadronContact = '+39-335-830-7894';
  } else if (plane === 'C-26') {
    squadronContact = '+39-081-568-6897 or +39-095-86-6352';
  } else {
    squadronContact = 'TBD';
  }
  return squadronContact;
}

function createEmailBody(headerValues, missionInWork) {
  if (missionInWork.length !== 0) {
    return `ALCON, 
Do not respond to the sender of this email for questions, changes, or issues with this Intent to Support message.  Please use M-NA-CTF-63AIRLOGOPS-GD@EU.NAVY.MIL for any correspondence.
1.  This message is notification of an intent to support your airlift request for logistics and/or personnel transport. 
    
2.  Requests for diplomatic clearance for the following mission have been submitted.
Aircraft: ${headerValues.type}
Call Sign: CNV ${headerValues.callsign}
ITS update: ${headerValues.updateNumber}
Date: ${DateTime.fromISO(missionInWork[0].toTime).toFormat(
      'dd LLL yy'
    )} - ${DateTime.fromISO(
      missionInWork[missionInWork.length - 1].landTime
    ).toFormat('dd LLL yy')}

3. Projected Flight Plan:
${navTableData(missionInWork)}
4. Airlifts
Read as: LIFT CODE / UIC / POD - POA / PAX / CARGO / HAZMAT (If yes, class/es of HAZ)? / DV?
${airliftInfo(missionInWork)}
5. Lift Requestor Information
Read as: LIFT CODE / UIC/ REQ COORD / EMAIL / PHONE
${liftCoord(missionInWork)}
6. TF 63 Air Logistics Contact Information 
6.A. PHONE NUMBERS:
Duty office number: DSN 314-626-3150, COMM (39) 081-568-3150, Duty 24hr number: COMM (39) 335-774-4952 
6.B. Email Address: M-NA-CTF-63AIRLOGOPS-GD@EU.NAVY.MIL

7. To contact squadron assigned this mission please call ${squadronContact(
      headerValues.type
    )}
    
8. For all passengers: Contact the applicable terminals for your airlift itinerary in order to determine show times, baggage requirements and cargo loading procedures.  Travel uniform is IAW Navy Uniform regulations and DOD foreign clearance guide.
    
9. For all airlifts: Departure coordinators must ensure cargo is palletized/compatible with the aircraft assigned.  Contact applicable Load Planning/Terminals for timeline and loading information.  
    
10. Official flight advisory to be released 3 days prior to mission execution.
    
V/R,
TF 63 Air Logistics 
DSN 314-626-3150
COMM: +39-081-568-3150   
    `;
  }
}
