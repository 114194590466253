import Chip from '@material-ui/core/Chip';
import React from 'react';
import { useDrag } from 'react-dnd';
import RouteTooltip from '../Utils/RouteTooltip';
import './App.css';
const { DateTime } = require('luxon');

export const RouteChip = ({ props, setselectedRoute }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { props, type: 'route' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <RouteTooltip route={props}>
      <Chip
        className='mt-2'
        key={props.id}
        style={{
          opacity: isDragging ? 0.5 : 1,
          color: 'white',
          fontSize: '1rem',
          margin: '1px',
        }}
        ref={drag}
        color={
          DateTime.fromSQL(props?.valdate) >
          DateTime.local().minus({ days: 31 })
            ? 'primary'
            : 'secondary'
        }
        clickable
        data={props}
        onClick={(e) => {
          setselectedRoute(props);
        }}
        label={props.id + '/' + props.pod + '-' + props.poa}
      />
    </RouteTooltip>
  );
};
