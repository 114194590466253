//Left off here.  Working new Itinerary Class.  new States are not working correctly Line ~322

//Crew Day counter set here (540 mins)
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import '../App.css';
import useMissionDataPartial from '../Utils/Hooks/useMissionDataPartial';
const { DateTime } = require('luxon');

export const CrewRest = ({ mission }) => {
  const { data: missionSet, isLoading } = useMissionDataPartial();
  //debugger;
  let LT_array = mission.landTimes.split(' ');
  let TT_array = mission.takeoffTimes.split(' ');
  //Land time last leg
  let LT = DateTime.fromISO(LT_array[LT_array.length - 1]);
  //Takeoff time first leg
  let TT = DateTime.fromISO(TT_array[0]);

  let missionNextDay, missionPreviousDay;
  if (missionSet && missionSet.length !== 0) {
    missionNextDay = missionSet.find((elem) => {
      return (
        elem.buno === mission.buno &&
        LT.plus({ days: 1 }).toISODate() ===
          DateTime.fromISO(elem.takeoffTimes.split(' ')[0]).toISODate()
      );
    });
  }
  if (missionSet && missionSet.length !== 0) {
    missionPreviousDay = missionSet.find((elem) => {
      let landTimePrev_arr = elem.landTimes.split(' ');
      return (
        elem.buno === mission.buno &&
        TT.minus({ days: 1 }).toISODate() ===
          DateTime.fromISO(
            landTimePrev_arr[landTimePrev_arr.length - 1]
          ).toISODate()
      );
    });
  }

  let CRafter = '';
  if (missionNextDay !== undefined) {
    CRafter = DateTime.fromISO(missionNextDay.takeoffTimes.split(' ')[0])
      .diff(LT, ['hours'])
      .minus({ hours: 2 })
      .toFormat('hh+mm');
  }
  if (missionNextDay === undefined) {
    CRafter = `No mission next day`;
  }
  let CRbefore = '';
  if (missionPreviousDay !== undefined) {
    CRbefore = TT.diff(
      DateTime.fromISO(
        missionPreviousDay.landTimes.split(' ')[
          missionPreviousDay.landTimes.split(' ').length - 1
        ]
      ),
      ['hours']
    )
      .minus({ hours: 2 })
      .toFormat('hh+mm');
  }
  if (missionPreviousDay === undefined) {
    CRbefore = `No mission previous day`;
  }

  if (missionSet === undefined) {
    return null;
  }
  if (isLoading === true) {
    return (
      <Grid
        container
        direction='row'
        spacing={3}
        alignItems='center'
        justify='center'>
        <Grid item xs={6}>
          <Typography variant='h6' color='initial'>
            Loading
          </Typography>
        </Grid>
      </Grid>
    );
  }
  //TODO convert to readable format
  return (
    <>
      {missionSet && (
        <Grid
          container
          direction='row'
          spacing={3}
          alignItems='center'
          justify='center'
          style={{
            outline: '3px solid steelblue',
            width: '95%',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: 'lightgrey',
          }}>
          <Grid item xs={6}>
            <Typography
              variant='h6'
              color='initial'
              style={{ textAlign: 'center' }}>
              Crew Rest before (scheduled land to brief): {CRbefore}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant='h6'
              color='initial'
              style={{ textAlign: 'center' }}>
              Crew Rest after (scheduled land to brief): {CRafter}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
