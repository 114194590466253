export default function convertTime(time_string) {
  //60,90, 1:30, 1+30, 1.10
  let minutes, minutes_hours;
  let error = false;
  if (time_string.includes(':')) {
    const time_string_array = time_string.split(':');
    minutes = 60 * Number(time_string_array[0]) + Number(time_string_array[1]);
  } else if (time_string.includes('+')) {
    const time_string_array = time_string.split('+');
    minutes = 60 * Number(time_string_array[0]) + Number(time_string_array[1]);
  } else if (time_string.includes('.')) {
    const time_string_array = time_string.split('.');
    minutes =
      60 * Number(time_string_array[0]) +
      Number('.' + time_string_array[1]) * 60;
  } else {
    minutes = Number(time_string);
    minutes_hours =
      Math.floor(minutes / 60) + '+' + String(minutes % 60).padEnd(2, '0') + '';
    time_string = minutes_hours;
  }
  if (minutes === undefined || isNaN(minutes) || minutes === '') {
    error = true;
    minutes = 0;
  }
  //console.log('convertTime output', minutes, time_string, error, minutes_hours);
  return [minutes, time_string, error, minutes_hours];
}
