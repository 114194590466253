import { Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import React, { useContext } from 'react';
import { useAlert } from 'react-alert';
import { useDrop } from 'react-dnd';
import './App.css';
import AirliftDrop from './components/AirliftDrop';
import { LandTimeLocal } from './components/LandTimeLocal';
import { LandTimeZulu } from './components/LandTimeZulu';
import { LegCol } from './components/LegCol';
import { TakeOffLocal } from './components/TakeOffLocal';
import { TakeOffZulu } from './components/TakeOffZulu';
import { TimeDown } from './components/TimeDown';
import { TimeUp } from './components/TimeUp';
import CrewChange from './CrewChange';
import DutyDay from './DutyDay';
import { itineraryTimeUpdate } from './itineraryTimeUpdate';
import { MissionInWork } from './MissionGeneratorStore';
import RON from './RON';
import TurnTime from './TurnTime';

function timeSwitch(time_minutes) {
  return (
    Math.floor(Number(time_minutes) / 60) +
    '+' +
    ((Number(time_minutes) % 60) + '').padStart(2, '0')
  );
}

export default function MissionRow(props) {
  const [, setmissionInWork] = useContext(MissionInWork);

  const alert = useAlert();

  const [{ isOver: isOverOverwrite }, dropOverwrite] = useDrop({
    accept: 'route',
    drop: (monitor) => {
      const routeToAdd = { ...monitor.props };
      setmissionInWork((prevState) => {
        let newState = [...prevState];
        routeToAdd.roundedEnrouteTime =
          Math.floor(routeToAdd.routeTime / 60) * 60 +
          Math.ceil((routeToAdd.routeTime % 60) / 15) * 15;
        let newLegEntry = { ...prevState[props.rowNumber], ...routeToAdd };
        newState[props.rowNumber] = newLegEntry;
        //TODO need to rerun all times here
        return itineraryTimeUpdate('first', newState);
        //Test 6512 with new EDDS-LRCK route

        //return newState;
      });
    },
    collect: (mon) => ({
      isOver: mon.isOver(),
    }),
  });

  let backgroundColor = 'white';
  if (isOverOverwrite) {
    backgroundColor = 'lightgreen';
  }

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'airlift',
    drop: (monitor) => {
      const objectToAdd = { ...monitor.props };

      setmissionInWork((prevState) => {
        let newState = [...prevState];
        if (newState[props.rowNumber].airlifts === undefined) {
          newState[props.rowNumber].airlifts = [objectToAdd];
        } else {
          function containsObject(obj, list) {
            var x;
            for (x in list) {
              if (list.hasOwnProperty(x) && list[x].id === obj.id) {
                return true;
              }
            }
            return false;
          }
          if (
            containsObject(objectToAdd, newState[props.rowNumber].airlifts) ===
            true
          ) {
            //Do not add airlift to array
            alert.show('Airlift already added to leg');
          } else {
            newState[props.rowNumber].airlifts.push(objectToAdd);
          }
        }
        return newState;
      });
    }, //Drop route into mission builder
    collect: (mon) => ({
      isOver: mon.isOver(),
    }),
  });
  const isActive = isOver;

  if (isActive) {
    backgroundColor = 'green';
  } else if (canDrop) {
    backgroundColor = 'darkkhaki';
  }

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      direction='row'
      ref={dropOverwrite}
      //spacing={1}
      style={{
        outlineStyle: 'solid',
        backgroundColor: backgroundColor,
        marginTop: '15px',
        marginBottom: '7px',
      }}>
      <Grid
        item
        container
        direction='column'
        justify='center'
        alignItems='center'
        lg={1}>
        <LegCol props={props} />
      </Grid>
      <Grid
        item
        container
        lg={2}
        xs={1}
        direction='column'
        justify='center'
        alignItems='center'>
        <Grid item xs>
          <TextField
            id='outlined-basic'
            label='POD'
            value={props.info.pod}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <FlightTakeoffIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            id='outlined-basic'
            label='POA'
            value={props.info.poa}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <FlightLandIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={2}
        direction='column'
        justify='center'
        alignItems='center'>
        <Grid item xs>
          <TakeOffZulu props={props} />
        </Grid>
        <Grid item xs>
          <LandTimeZulu props={props} />
        </Grid>
      </Grid>
      <Hidden lgDown>
        <Grid
          item
          container
          xs={1}
          style={{ maxWidth: '25px' }}
          direction='column'
          justify='center'
          alignItems='center'>
          <Grid item xs>
            <TimeUp props={props} />
          </Grid>
          <Grid item xs>
            <TimeDown props={props} />
          </Grid>
        </Grid>
      </Hidden>
      <Grid
        item
        container
        xs={2}
        direction='column'
        justify='center'
        alignItems='center'>
        <Grid item xs>
          <TakeOffLocal props={props} />
        </Grid>
        <Grid item xs>
          <LandTimeLocal props={props} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={1}
        direction='column'
        justify='center'
        alignItems='center'>
        <Grid item xs>
          <TextField
            id='outlined-basic'
            label='Enroute Time'
            value={timeSwitch(props.info.roundedEnrouteTime)}
          />
        </Grid>
        <Grid item xs>
          <TurnTime props={props} key={props.id} />
        </Grid>
      </Grid>
      <Grid
        item
        md={2}
        ref={drop}
        direction='column'
        container
        justify='center'
        alignItems='center'
        style={{ backgroundColor: backgroundColor, minWidth: '7vw' }}>
        <AirliftDrop props={props} />
      </Grid>
      <Grid
        item
        container
        xs={2}
        direction='column'
        justify='center'
        alignItems='center'
        md>
        <Grid item xs>
          <RON data={props} />
        </Grid>
        <Grid item xs>
          <CrewChange data={props} />
        </Grid>
        <Grid item xs>
          <DutyDay data={props} />
        </Grid>
      </Grid>
    </Grid>
  );
}
