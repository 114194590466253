import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import '../App.css';
import MyFallbackComponent from '../Errors/MyFallbackComponent.jsx';
import MyFallbackComponentContact from '../Errors/MyFallbackComponentContact';
import MyFallbackComponentGouge from '../Errors/MyFallbackComponentGouge';
import MyFallbackComponentItinerary from '../Errors/MyFallbackComponentItinerary';
import MyFallbackComponentPPR from '../Errors/MyFallbackComponentPPR';
import GougeReports from '../GougeReports';
import useMissionDetail from '../Utils/Hooks/useMissionDetail';
import ContactPage from './ContactPage';
import Itinerary from './Itinerary';
import LNOnotes from './LNONotes';
import MissionNotes from './MissionNotes';
import PPRTable from './PPRTable';
import { Header } from './Utils';

const MissionDetailPageFallback = () => (
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
    <h3>Loading Mission detail...</h3>
  </div>
);

export default function MissionDetailPageDet(props) {
  return (
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      <Suspense fallback={<MissionDetailPageFallback />}>
        <MissionDetail props={props} />
      </Suspense>
    </ErrorBoundary>
  );
}

function MissionDetail({ props }) {
  const { data: data_output, status } = useMissionDetail(
    props?.location?.state?.missionDataLink?.routeID,
    props?.location?.state?.missionDataLink?.typeAircraft
  );

  return (
    <>
      <Header data={props} />
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <LNOnotes props={props} />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <MissionNotes props={props} />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponentItinerary}>
        {status === 'loading' ? <MissionDetailPageFallback /> : null}
        {data_output && (
          <Itinerary
            data={props.location.state.missionDataLink}
            routes={data_output[0]}
            airports={data_output[1]}
            gouge={data_output[2]}
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponentPPR}>
        {status === 'loading' ? <MissionDetailPageFallback /> : null}
        {data_output && (
          <PPRTable
            data={props.location.state.missionDataLink}
            routes={data_output[0]}
            airports={data_output[1]}
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponentContact}>
        <ContactPage data={props.location.state.missionDataLink} />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponentGouge}>
        {data_output && <GougeReports reports={data_output[2]} />}
      </ErrorBoundary>
    </>
  );
}
