import { Button } from '@material-ui/core';
import React, { Suspense, useState } from 'react';
import PPRTable from './PPRTable';

//import { createResource } from '../API/missionDetailAPI';

export default function PPRPage({ data, routes, airports }) {
  const [display, setdisplay] = useState(true);

  function changeDisplay() {
    display === true ? setdisplay(false) : setdisplay(true);
  }

  return (
    <>
      <div style={{ textAlign: 'center', margin: '10px' }}>
        <Button
          variant='outlined'
          href=''
          onClick={() => {
            changeDisplay();
          }}>
          {display ? 'Hide PPR Information' : 'Show PPR information'}
        </Button>
      </div>
      {display ? (
        <PPRTableSuspended props={data} routes={routes} airports={airports} />
      ) : null}
    </>
  );
}

function PPRTableSuspended({ props, routes, airports }) {
  return (
    <Suspense fallback={<h3>Loading PPR Table....</h3>}>
      <PPRTable props={props} routes={routes} airports={airports} />
    </Suspense>
  );
}
