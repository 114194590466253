import { DateTime, Duration } from 'luxon';
import convertTime from '../convertTime';
import { addAirlifts } from './Utils/addAirlifts';

export function addTimes(newMissionArray, missionDataToImport) {
  let missionArray = [...newMissionArray];

  const startDateTime = DateTime.fromISO(missionDataToImport.startDate, {
    zone: newMissionArray[0].podTimeZone,
  }).set({
    hour: '9',
  });

  let turnTime = {};
  let minimumTurn;
  switch (missionDataToImport?.type) {
    case 'c130routes':
      turnTime = { minutes: 90, text: '1+30' };
      minimumTurn = 17;
      break;
    case 'c40routes':
      turnTime = { minutes: 60, text: '1+00' };
      minimumTurn = 15;
      break;
    case 'c26routes':
      turnTime = { minutes: 60, text: '1+00' };
      minimumTurn = 15;
      break;
    default:
      turnTime = { minutes: 60, text: '1+00' };
      minimumTurn = 15;
      break;
  }

  for (let i = 0; i < missionArray.length; i++) {
    if (missionDataToImport.legs[i].airlifts) {
      missionArray[i].airlifts = addAirlifts(
        i,
        newMissionArray,
        missionDataToImport
      );
    }

    if (i === 0) {
      missionArray[i].toTimeZulu = startDateTime.toUTC().toISO();
      missionArray[i].roundedEnrouteTime =
        Math.floor(missionArray[i].routeTime / 60) * 60 +
        Math.ceil((missionArray[i].routeTime % 60) / 15) * 15;

      missionArray[i].landTimeZulu = startDateTime
        .plus({ minutes: missionArray[i].roundedEnrouteTime })
        .toUTC()
        .toISO();

      missionArray[i].toTime = DateTime.fromISO(missionArray[i].toTimeZulu, {
        zone: 'UTC',
      })
        .setZone(missionArray[i].podTimeZone)
        .toISO();

      missionArray[i].landTime = DateTime.fromISO(
        missionArray[i].landTimeZulu,
        {
          zone: 'UTC',
        }
      )
        .setZone(missionArray[i].poaTimeZone)
        .toISO();

      //Note crew change
      if (missionDataToImport.legs[i].CC === true) {
        missionArray[i].crewChange = true;
      }

      missionArray[i].turnTime = { ...turnTime };
      missionArray[i].RON = false;

      //Calculate turn times accounting for RON
      if (missionDataToImport.legs[i].RON === true) {
        missionArray[i].RON = true;

        let earliestLaunchTime = DateTime.fromISO(
          missionArray[i].landTimeZulu,
          {
            zone: 'UTC',
          }
        )
          .plus({ hours: minimumTurn })
          .setZone(missionArray[i].poaTimeZone);
        let nextDayStandardLaunch = DateTime.fromISO(missionArray[i].landTime)
          .setZone(missionArray[i].poaTimeZone)
          .plus({ days: 1 })
          .set({ hour: 9, minute: 0 });

        let nextLaunchShouldBe = '';
        if (nextDayStandardLaunch >= earliestLaunchTime) {
          nextLaunchShouldBe = nextDayStandardLaunch;
        } else {
          nextLaunchShouldBe = earliestLaunchTime;
        }

        //Set turn time
        const newTurnTime = nextLaunchShouldBe
          .diff(
            DateTime.fromISO(missionArray[i].landTimeZulu, {
              zone: 'UTC',
            }),
            'minutes'
          )
          .toFormat('mm');
        const [minutes, time_string] = convertTime(String(newTurnTime));

        missionArray[i].turnTime.minutes = minutes;
        missionArray[i].turnTime.text = time_string;
      }
    } else {
      missionArray[i].roundedEnrouteTime =
        Math.floor(missionArray[i].routeTime / 60) * 60 +
        Math.ceil((missionArray[i].routeTime % 60) / 15) * 15;

      missionArray[i].toTimeZulu = DateTime.fromISO(
        missionArray[i - 1].landTimeZulu,
        { zone: 'UTC' }
      )
        .plus(
          Duration.fromObject({
            minutes: missionArray[i - 1].turnTime.minutes,
          })
        )
        .toUTC()
        .toISO();

      missionArray[i].landTimeZulu = DateTime.fromISO(
        missionArray[i].toTimeZulu,
        { zone: 'UTC' }
      )
        .plus(
          Duration.fromObject({
            minutes: missionArray[i].roundedEnrouteTime,
          })
        )
        .toUTC()
        .toISO();

      missionArray[i].toTime = DateTime.fromISO(missionArray[i].toTimeZulu, {
        zone: 'UTC',
      })
        .setZone(missionArray[i].podTimeZone)
        .toISO();

      missionArray[i].landTime = DateTime.fromISO(
        missionArray[i].landTimeZulu,
        {
          zone: 'UTC',
        }
      )
        .setZone(missionArray[i].poaTimeZone)
        .toISO();

      //Note crew change
      if (missionDataToImport.legs[i].CC === true) {
        missionArray[i].crewChange = true;
      }

      missionArray[i].turnTime = { ...turnTime };
      missionArray[i].RON = false;

      //Calculate turn times accounting for RON
      if (missionDataToImport.legs[i].RON === true) {
        missionArray[i].RON = true;

        let earliestLaunchTime = DateTime.fromISO(
          missionArray[i].landTimeZulu,
          {
            zone: 'UTC',
          }
        )
          .plus({ hours: minimumTurn })
          .setZone(missionArray[i].poaTimeZone);
        let nextDayStandardLaunch = DateTime.fromISO(missionArray[i].landTime)
          .setZone(missionArray[i].poaTimeZone)
          .plus({ days: 1 })
          .set({ hour: 9, minute: 0 });

        let nextLaunchShouldBe = '';

        if (nextDayStandardLaunch >= earliestLaunchTime) {
          nextLaunchShouldBe = nextDayStandardLaunch;
        } else {
          nextLaunchShouldBe = earliestLaunchTime;
        }

        //Set turn time
        const newTurnTime = nextLaunchShouldBe
          .diff(
            DateTime.fromISO(missionArray[i].landTimeZulu, {
              zone: 'UTC',
            }),
            'minutes'
          )
          .toFormat('mm');
        const [minutes, time_string] = convertTime(String(newTurnTime));
        missionArray[i].turnTime.minutes = minutes;
        missionArray[i].turnTime.text = time_string;
      }
    }
  }

  return missionArray;
}
