import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import '../App.css';
import MyFallbackComponent from '../Errors/MyFallbackComponent';
import { FilterSetting } from '../store';
import useMissionDataPartial from '../Utils/Hooks/useMissionDataPartial';
import { MissionLink } from './utils';

const { DateTime } = require('luxon');
/* 
const fetchFiles = async () => {
  const data = await fetch('/db/pullPPRFolders.efp.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
  });
  if (!data.ok) {
    throw Error(data.statusText);
  } else {
    return data.json();
  }
};

const fetchAirlifts = async () => {
  const data = await fetch('/db/pullAllAirlifts.schedule.db.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
  });
  if (!data.ok) {
    throw Error(data.statusText);
  } else {
    return data.json();
  }
};
const fetchPPR = async () => {
  const data = await fetch('/db/chunkPR.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
  });
  if (!data.ok) {
    throw Error(data.statusText);
  } else {
    return data.json();
  }
}; */

export default function DetSchedule(props) {
  return (
    <>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <ScheduleTable />
      </ErrorBoundary>
    </>
  );
}

function ScheduleTable() {
  const queryClient = useQueryClient();
  const [filterSetting, setFilterSetting] = useContext(FilterSetting);
  const { data: missionData, status, isFetching } = useMissionDataPartial();

  /*   queryClient.prefetchQuery(['airlifts'], () => fetchAirlifts(), {
    staleTime: 600 * 1000,
  });
  queryClient.prefetchQuery(['PPR'], () => fetchPPR(), {
    staleTime: 600 * 1000,
  });
  queryClient.prefetchQuery(['filesPPR'], () => fetchFiles(), {
    staleTime: 1200 * 1000,
  }); */

  const matches = useMediaQuery('(min-width:400px)');
  const desktop = useMediaQuery('(min-width:1100px)');

  const bodyStyle = {
    borderBottom: '',
  };

  const tableStyle = {
    width: '90%',
    marginTop: '3px',
    overflowX: 'auto',
    marginLeft: '5vw',
    marginRight: '5vw',
  };

  const cssTest = {
    margin: '10px',
    minWidth: '120px',
    paddingLeft: '35px',
  };

  const formCtrl = {
    paddingLeft: '35px',
    marginTop: '10px',
  };

  var filteredData = '';
  if (missionData && missionData.length !== 0) {
    if (filterSetting === 'oldMissions') {
      // alll archived missions less than 45 days old
      filteredData = missionData.filter((input) => {
        let nnow = new Date();
        //debugger;
        return DateTime.fromISO(input.firstTOlocal) <= nnow;
      });
    } else {
      filteredData = missionData.filter((input) => {
        if (filterSetting === 'all') {
          return (
            DateTime.fromISO(input.landTimes.trim().split(' ').reverse()[0]) >=
            DateTime.local().plus({ days: -1 })
          );
        } else {
          return (
            input.typeAircraft === filterSetting &&
            DateTime.fromISO(input.landTimes.trim().split(' ').reverse()[0]) >=
              DateTime.local().plus({ days: -1 })
          );
        }
      });
    }
  }

  if (status === 'loading') {
    return (
      <h2 style={{ textAlign: 'center', color: 'red', marginTop: '15vh' }}>
        Loading Schedule Data
      </h2>
    );
  }
  if (status === 'error') {
    return <h3>Whoops, error</h3>;
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <Grid
          container
          direction='row'
          justify='space-evenly'
          alignItems='center'>
          <Grid item>
            <FormControl variant='outlined' style={formCtrl}>
              <InputLabel shrink htmlFor='actypeTest' style={cssTest}>
                Filter
              </InputLabel>
              <Select
                value={filterSetting}
                onChange={(e) => {
                  e.preventDefault();
                  setFilterSetting(e.target.value);
                }}
                name='actypeTest'
                id='actypeTest'
                input={<OutlinedInput name='actypeTest' id='actypeTest' />}>
                <MenuItem value='all'>
                  <em>All</em>
                </MenuItem>
                <MenuItem value='c130routes'>C-130</MenuItem>
                <MenuItem value='c40routes'>C-40</MenuItem>
                <MenuItem value='c26routes'>C-26</MenuItem>
                <MenuItem value='oldMissions'>Old Missions</MenuItem>
              </Select>
              <FormHelperText>Choose an aircraft</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              size={matches === true ? 'large' : 'small'}
              onClick={() => {
                queryClient.fetchQuery(['missions']);
              }}>
              {isFetching === true ? 'Refeshing...' : 'Refresh'}
            </Button>
          </Grid>
        </Grid>
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <Table style={tableStyle} size={desktop === true ? 'medium' : 'small'}>
          <TableHead>
            <TableRow style={{ backgroundColor: 'lightgray' }}>
              <TableCell align='center'>
                <Typography variant='h5'>Callsign</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography variant='h5'>Itinerary</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography variant='h5'>BuNo</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography variant='h5'>Start Date</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={bodyStyle}>
            {filteredData !== undefined ||
            filteredData.length === 0 ||
            filteredData.length === '' ? (
              filteredData.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow key={index} hover={true}>
                      <TableCell align='center'>
                        <Link
                          to={{
                            pathname: `/detmission/${data.missionID}`,
                            state: {
                              missionDataLink: data,
                            },
                          }}>
                          <MissionLink key={data.missionID + 'm'} data={data} />
                        </Link>
                      </TableCell>
                      <TableCell align='center'>
                        <Typography variant='h6'>
                          {data.fullMissionDataObject.map((leg, index) => {
                            if (index === 0) {
                              if (leg.RON === true) {
                                return leg.pod + ' - ' + leg.poa + '/RON';
                              } else {
                                return leg.pod + ' - ' + leg.poa;
                              }
                            } else {
                              if (leg.RON === true) {
                                return ' - ' + leg.poa + '/RON';
                              } else {
                                return ' - ' + leg.poa;
                              }
                            }
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell align='center'>
                        <Typography variant='h6' color='initial'>
                          {data?.buno}
                        </Typography>
                      </TableCell>
                      <TableCell align='center'>
                        <Typography variant='h6' color='initial'>
                          {DateTime.fromISO(data?.firstTOlocal).toFormat(
                            'dd MMM'
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <TableCell>
                <h2 style={{ textAlign: 'center', color: 'red' }}>
                  No Schedule Data
                </h2>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </ErrorBoundary>
    </>
  );
}
