import { Button } from '@material-ui/core';
import produce from 'immer';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useQueryClient } from 'react-query';
import StandardLoadingFallback from '../Loading/StandardLoadingFallback';
import TableWrapper from '../Table/TableWrapper';
import useEmails from '../Utils/Hooks/useEmails';

export default function EmailTable() {
  const { data, isFetching } = useEmails();
  const databaseName = 'emails';
  const queryKey = ['emailSet'];
  const columns = [
    {
      Header: `Emails (updates automatically on changes)`,
      columns: [
        {
          Header: 'Type (aircraft, airfield, all)',
          accessor: 'type',
        },
        {
          Header: 'Distribution (cc or bcc)',
          accessor: 'distribution',
        },
        {
          Header:
            'Aircraft (if you want email added to missions of type a/c)(can be blank)',
          accessor: 'aircraft',
        },
        {
          Header:
            'ICAO (if you want email added to missions going thru airfield)(can be blank)',
          accessor: 'ICAO',
        },
        {
          Header: 'Email (multiples with ;)',
          accessor: 'email',
        },
        {
          Header: 'Delete',
          accessor: '',
          disableFilters: true,
          disableSortBy: true,
          Cell: (props) => {
            return (
              <DeleteRow
                id={props.cell.row.original.id}
                databaseName={databaseName}
                queryKey={queryKey}
              />
            );
          },
        },
      ],
    },
  ];

  if (isFetching === true) {
    return <StandardLoadingFallback />;
  }

  const rowsNotToEdit = ['ID'];

  return (
    <TableWrapper
      data={data}
      numberOfRowsToDisplay={20}
      columnsArray={columns}
      queryKey={queryKey}
      pagination={true}
      databaseName={databaseName}
      rowsNotToEdit={rowsNotToEdit}
    />
  );
}

const DeleteRow = ({ id, databaseName, queryKey }) => {
  const queryClient = useQueryClient();
  return (
    <Button
      size='small'
      variant='contained'
      onClick={async (e) => {
        confirmAlert({
          title: 'Confirm delete',
          message:
            'If you delete this route it will be gone forever.  Are you sure you want to proceed?',
          buttons: [
            {
              label: 'Yes',
              onClick: async () => {
                const data = {
                  action: 'delete',
                  databaseName: databaseName,
                  id: id,
                };
                const response = await fetch('/db/updateDB.php', {
                  method: 'POST',
                  withCredentials: true,
                  credentials: 'include',
                  headers: {
                    Accept: 'application/json',
                    Authorization: localStorage.getItem('token'),
                  },
                  body: JSON.stringify(data),
                });
                if (response.status === 401) {
                  console.log('Issue at (handle errors)', response.url);
                  window.alert('Error deleting');
                  throw Error(response.status);
                }
                if (!response.ok) {
                  window.alert('Error deleting');
                  throw Error(response.status);
                } else {
                  queryClient.setQueryData(queryKey, (old) => {
                    return produce(old, (draft) => {
                      const index = draft.findIndex((route) => route.id === id);
                      if (index !== -1) draft.splice(index, 1);
                    });
                  });
                }
              },
            },
            {
              label: 'No',
              onClick: () => {
                //Nothing
              },
            },
          ],
        });
      }}>
      Delete
    </Button>
  );
};
