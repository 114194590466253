export const aircraftBunoToType = [
  {
    buno: 910502,
    type: 'C-26',
    typeAircraft: 'c26routes',
  },
  {
    buno: 900531,
    type: 'C-26',
    typeAircraft: 'c26routes',
  },
  {
    buno: 900528,
    type: 'C-26',
    typeAircraft: 'c26routes',
  },
  {
    buno: 900530,
    type: 'C-26',
    typeAircraft: 'c26routes',
  },
  {
    buno: 164996,
    type: 'C-130',
    typeAircraft: 'c130routes',
  },
  {
    buno: 164994,
    type: 'C-130',
    typeAircraft: 'c130routes',
  },
  {
    buno: 165158,
    type: 'C-130',
    typeAircraft: 'c130routes',
  },
  {
    buno: 165833,
    type: 'C-40',
    typeAircraft: 'c40routes',
  },
  {
    buno: 168981,
    type: 'C-40',
    typeAircraft: 'c40routes',
  },
];
