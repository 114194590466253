import { FormHelperText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import he from 'he';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useQueryClient } from 'react-query';
const { DateTime } = require('luxon');

export default function FeedbackForm() {
  // Get QueryClient from the context
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const [validInputs, setValidInputs] = useState({
    ICAO: true,
    feedback: true,
  });

  const [feedback, setFeedback] = useState({
    type: 'Airfield',
    aircraft: '',
    userName: window.localStorage.getItem('userName'),
    date: DateTime.local().toFormat('yyyy-LL-dd'),
    feedback: '',
    callsign: '',
    action: 'post',
    missionDate: DateTime.local().toFormat('yyyy-LL-dd'),
    submitterName: '',
    ICAO: '',
  });

  const handleChange = (event) => {
    if (event.target.name === 'ICAO') {
      setValidInputs({ ...validInputs, ICAO: true });
    }
    if (event.target.name === 'feedback') {
      setValidInputs({ ...validInputs, feedback: true });
    }
    const name = event.target.name;
    setFeedback({
      ...feedback,
      [name]: event.target.value,
    });
  };

  function sendFeedback() {
    setLoading(true);
    let data = { ...feedback };
    data.feedback = he.encode(data.feedback);

    fetch('/db/submitFeedback.php', {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          alert.error(
            'Whoops there was a problem uploading your info to the database.  Try again or contact Administrator.'
          );
          setLoading(false);
          throw Error(response.status);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        alert.success('Successful submission. Thank you.');
        setLoading(false);
        queryClient.invalidateQueries('feedback');
        setFeedback({
          type: 'Airfield',
          aircraft: '',
          userName: window.localStorage.getItem('userName'),
          date: DateTime.local().toFormat('yyyy-LL-dd'),
          feedback: '',
          callsign: '',
          action: 'post',
          missionDate: DateTime.local().toFormat('yyyy-LL-dd'),
          submitterName: '',
          ICAO: '',
        });
      });
  }

  function testSubmission() {
    let valid = true;
    let copy = { ...validInputs };
    if (feedback.feedback === '') {
      valid = false;
      copy.feedback = false;
    }
    if (feedback.ICAO === '' && feedback.type === 'Airfield') {
      valid = false;
      copy.ICAO = false;
    }
    setValidInputs(copy);
    return valid;
  }

  return (
    <>
      <Container component='main' maxWidth='lg'>
        <Typography
          component='h1'
          variant='h5'
          style={{ margin: '15px', textAlign: 'center' }}>
          Please use the form below to submit gouge to the TF 63 database. Your
          gouge & trip reports will help make future missions better.
          {<br />}
          Reports are indexed by ICAO and automatically displayed in all future
          missions which include that ICAO.
        </Typography>
        <form noValidate>
          <Grid container spacing={2} style={{ marginTop: '2vh' }}>
            {/*        <Grid item sm={6}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='status'>Type of Feedback</InputLabel>
                <Select
                  labelId='type'
                  id='type'
                  name='type'
                  value={feedback.type}
                  onChange={handleChange}
                  label='type'>
                  <MenuItem value='Airfield'>Airfield</MenuItem>
                  <MenuItem value='Mission'>Mission</MenuItem>
                  <MenuItem value='Scheduling'>Scheduling</MenuItem>
                  <MenuItem value='Airlift'>Airlift</MenuItem>
                  <MenuItem value='Other'>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item sm={6}>
              <FormControl required variant='outlined' fullWidth>
                <InputLabel id='typeOfAircraft'>Type of Aircraft</InputLabel>
                <Select
                  labelId='typeOfAircraft'
                  id='aircraft'
                  name='aircraft'
                  value={feedback.aircraft}
                  onChange={handleChange}
                  label='Type of Aircraft'>
                  <MenuItem value=''>Select one</MenuItem>
                  <MenuItem value='C-26'>C-26</MenuItem>
                  <MenuItem value='C-130'>C-130</MenuItem>
                  <MenuItem value='C-40'>C-40</MenuItem>
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <FormControl required variant='outlined' fullWidth>
                <TextField
                  id='outlined-basic'
                  label='ICAO'
                  value={feedback.ICAO}
                  onChange={handleChange}
                  variant='outlined'
                  name='ICAO'
                  error={validInputs.ICAO === false ? true : false}
                  helperText={
                    validInputs.ICAO === false ? 'Input required' : null
                  }
                />
              </FormControl>
              <FormHelperText>Required</FormHelperText>
            </Grid>
            <Grid item sm={6}>
              <FormControl variant='outlined' fullWidth>
                <TextField
                  id='outlined-basic'
                  label='Mission Callsign'
                  value={feedback.callsign}
                  onChange={handleChange}
                  variant='outlined'
                  name='callsign'
                />
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <FormControl variant='outlined' fullWidth>
                <TextField
                  id='outlined-basic'
                  label='Submitter Name'
                  value={feedback.submitterName}
                  onChange={handleChange}
                  variant='outlined'
                  name='submitterName'
                />
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <FormControl variant='outlined' fullWidth>
                <TextField
                  id='outlined-basic'
                  label='Mission Date'
                  value={feedback.missionDate}
                  onChange={handleChange}
                  variant='outlined'
                  name='missionDate'
                  type='date'
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                error={validInputs.feedback === false ? true : false}
                helperText={
                  validInputs.feedback === false ? 'Input required' : null
                }
                fullWidth
                value={feedback.feedback}
                onChange={handleChange}
                name='feedback'
                label='Feedback'
                type='text'
                id='feedback'
                multiline
                rows={8}
              />
            </Grid>
            <Grid item sm={4}></Grid>
            <Grid item sm={4}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                onClick={(e) => {
                  e.preventDefault();
                  if (testSubmission()) {
                    sendFeedback();
                  }
                }}
                fullWidth>
                {loading === true ? 'Loading' : 'Submit'}
              </Button>
            </Grid>
            <Grid item sm={4}></Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
