import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';
import ErrorBoundary from 'react-error-boundary';
import MyFallbackComponent from '../MyFallbackComponent';
import useMissionDataPartial from '../Utils/Hooks/useMissionDataPartial';
import './App.css';
import airfieldCheck from './components/airfieldCheck';
import { MissionInWork } from './MissionGeneratorStore';
const { DateTime, Duration } = require('luxon');

export default function MissionRowEmpty(props) {
  const [missionInWork, setmissionInWork] = useContext(MissionInWork);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: props.edit === true ? 'route' : '',
    drop: (monitor) => {
      //props.id is position in array that new monitor.props should be injected
      //console.log('object to add', props, objectToAdd);

      const [PODvalid, POAvalid] = airfieldCheck(monitor.props);
      if (PODvalid === false) {
        alert(
          'POD airfield does not appear to be in database.  Check airfield database (Time Zone missing)'
        );
      }
      if (POAvalid === false) {
        alert(
          'POA airfield does not appear to be in database.  Check airfield database (Time Zone missing)'
        );
      }

      if (PODvalid === true && POAvalid === true) {
        //TODO add formula to recalculate times
        //recalculateRows(missionInWork);
        setmissionInWork((prevState) => {
          let newState = [...prevState];
          const objectToAdd = { ...monitor.props };
          switch (props.aircraftType) {
            case 'c130routes':
              objectToAdd.turnTime = { minutes: 90, text: '1+30' };
              break;
            case 'c40routes':
              objectToAdd.turnTime = { minutes: 60, text: '1+00' };
              break;
            case 'c26routes':
              objectToAdd.turnTime = { minutes: 60, text: '1+00' };
              break;
            default:
              objectToAdd.turnTime = { minutes: 60, text: '1+00' };
              break;
          }
          objectToAdd.roundedEnrouteTime =
            Math.floor(objectToAdd.routeTime / 60) * 60 +
            Math.ceil((objectToAdd.routeTime % 60) / 15) * 15;
          newState.splice(props.id, 0, objectToAdd);

          //Update times after row dropped props.id is row number where dropped

          /*         newState[props.id - 1]?.landTime
      ? console.log('The row before contains valid land time')
      : console.log('The row before contains NO land time'); */

          if (newState[props.id - 1]?.landTime) {
            //Row before exists
            //Need to base new row on previous land and recalculate all after
            //Reviewed: 3 JAN 2021
            newState[props.id].toTimeZulu = DateTime.fromISO(
              newState[props.id - 1].landTimeZulu,
              { zone: 'UTC' }
            )
              .plus(
                Duration.fromObject({
                  minutes: newState[props.id - 1].turnTime.minutes,
                })
              )
              .toUTC()
              .toISO();

            newState[props.id].toTime = DateTime.fromISO(
              newState[props.id].toTimeZulu,
              { zone: 'UTC' }
            )
              .setZone(newState[props.id].podTimeZone)
              .toISO();

            newState[props.id].landTimeZulu = DateTime.fromISO(
              newState[props.id].toTimeZulu,
              { zone: 'UTC' }
            )
              .plus(
                Duration.fromObject({
                  minutes: newState[props.id].roundedEnrouteTime,
                })
              )
              .toUTC()
              .toISO();

            newState[props.id].landTime = DateTime.fromISO(
              newState[props.id].landTimeZulu,
              { zone: 'UTC' }
            )
              .setZone(newState[props.id].poaTimeZone)
              .toISO();

            //update after
            for (let i = Number(props.id) + 1; i < newState.length; i++) {
              newState[i].toTimeZulu = DateTime.fromISO(
                newState[i - 1].landTimeZulu,
                { zone: 'UTC' }
              )
                .plus(
                  Duration.fromObject({
                    minutes: newState[i - 1].turnTime.minutes,
                  })
                )
                .toUTC()
                .toISO();

              newState[i].toTime = DateTime.fromISO(newState[i].toTimeZulu, {
                zone: 'UTC',
              })
                .setZone(newState[i].podTimeZone)
                .toISO();

              newState[i].landTimeZulu = DateTime.fromISO(
                newState[i].toTimeZulu,
                { zone: 'UTC' }
              )
                .plus(
                  Duration.fromObject({
                    minutes: newState[i].roundedEnrouteTime,
                  })
                )
                .toUTC()
                .toISO();

              newState[i].landTime = DateTime.fromISO(
                newState[i].landTimeZulu,
                {
                  zone: 'UTC',
                }
              )
                .setZone(newState[i].poaTimeZone)
                .toISO();
            }
          } else if (
            newState[props.id - 1]?.landTime === undefined &&
            newState[props.id + 1]?.landTime === undefined
          ) {
            //Drop in first row with no other rows (before or after)
            //Reviewed: 3 JAN 2021
            /*           console.log(
        'Mission row empty, case 2, drop in first row with no other rows',
        newState
      ); */

            const takeoffTimeNoZone = DateTime.local()
              .plus({ days: 15 })
              .set({ hour: 9, minutes: 0, seconds: 0 })
              .toISO()
              .split('.')[0];

            newState[props.id].toTime = DateTime.fromISO(takeoffTimeNoZone, {
              zone: newState[props.id].podTimeZone,
            }).toISO();

            newState[props.id].toTimeZulu = DateTime.fromISO(
              newState[props.id].toTime
            )
              .toUTC()
              .toISO();

            newState[props.id].landTimeZulu = DateTime.fromISO(
              newState[props.id].toTimeZulu,
              { zone: 'UTC' }
            )
              .plus(
                Duration.fromObject({
                  minutes: newState[props.id].roundedEnrouteTime,
                })
              )
              .toUTC()
              .toISO();

            newState[props.id].landTime = DateTime.fromISO(
              newState[props.id].landTimeZulu,
              { zone: 'UTC' }
            )
              .setZone(newState[props.id].poaTimeZone)
              .toISO();
          } else {
            //First row (no previous row exists) with rows after
            //Reviewed 3 JAN 2021
            /*           console.log('mission row empty case 3.  First row with rows after'); */

            newState[props.id].landTimeZulu = DateTime.fromISO(
              newState[props.id + 1].toTimeZulu,
              { zone: 'UTC' }
            )
              .minus(
                Duration.fromObject({
                  minutes: newState[props.id].turnTime.minutes,
                })
              )
              .toUTC()
              .toISO();

            newState[props.id].landTime = DateTime.fromISO(
              newState[props.id].landTimeZulu,
              { zone: 'UTC' }
            )
              .setZone(newState[props.id].poaTimeZone)
              .toISO();

            newState[props.id].toTimeZulu = DateTime.fromISO(
              newState[props.id].landTimeZulu,
              { zone: 'UTC' }
            )
              .minus(
                Duration.fromObject({
                  minutes: newState[props.id].roundedEnrouteTime,
                })
              )
              .toUTC()
              .toISO();

            newState[props.id].toTime = DateTime.fromISO(
              newState[props.id].toTimeZulu,
              { zone: 'UTC' }
            )
              .setZone(newState[props.id].podTimeZone)
              .toISO();
          }
          //Return here
          return newState;
        });
      }
    }, //Drop route into mission builder
    collect: (mon) => ({
      isOver: mon.isOver(),
    }),
  });
  const isActive = isOver;
  let backgroundColor = 'lightcoral';
  if (isActive) {
    backgroundColor = 'darkgreen';
  } else if (canDrop) {
    backgroundColor = 'darkkhaki';
  }

  return (
    <>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        ref={drop}
        id='testRow'
        style={{
          outlineStyle: 'solid',
          backgroundColor: backgroundColor,
          marginTop: '2px',
        }}
        spacing={1}>
        <Grid item>Drag a route here </Grid>
        {props.type === 'first' && missionInWork.length >= 1 ? (
          <ErrorBoundary FallbackComponent={MyFallbackComponent}>
            <CrewRest
              type={'first'}
              missionInWork={missionInWork}
              props={props}
            />
          </ErrorBoundary>
        ) : null}
        {props.type === 'last' && missionInWork.length >= 1 ? (
          <ErrorBoundary FallbackComponent={MyFallbackComponent}>
            <CrewRest
              type={'last'}
              missionInWork={missionInWork}
              props={props}
            />
          </ErrorBoundary>
        ) : null}
      </Grid>
    </>
  );
}

const CrewRest = ({ type, missionInWork, props }) => {
  const { aircraftType } = props;
  const { data: missionSet, isLoading } = useMissionDataPartial();

  //Land time last leg
  let LT = DateTime.fromISO(
    missionInWork[missionInWork.length - 1].landTimeZulu
  );
  //Takeoff time first leg
  let TT = DateTime.fromISO(missionInWork[0].toTimeZulu);

  let missionNextDay, missionPreviousDay;
  if (missionSet.length !== 0 && missionSet) {
    missionNextDay = missionSet.find((elem) => {
      return (
        elem.typeAircraft === aircraftType &&
        LT.plus({ days: 1 }).toISODate() ===
          DateTime.fromISO(elem.takeoffTimes.split(' ')[0]).toISODate()
      );
    });
  }
  if (missionSet.length !== 0 && missionSet) {
    missionPreviousDay = missionSet.find((elem) => {
      let landTimePrev_arr = elem.landTimes.split(' ');
      return (
        elem.typeAircraft === aircraftType &&
        TT.minus({ days: 1 }).toISODate() ===
          DateTime.fromISO(
            landTimePrev_arr[landTimePrev_arr.length - 1]
          ).toISODate()
      );
    });
  }

  let CRafter = '';
  if (missionNextDay !== undefined) {
    CRafter = DateTime.fromISO(missionNextDay.takeoffTimes.split(' ')[0])
      .diff(LT, ['hours'])
      .minus({ hours: 2 })
      .as('hours');
  }
  if (missionNextDay === undefined) {
    CRafter = `No mission next day`;
  }
  let CRbefore = '';
  if (missionPreviousDay !== undefined) {
    CRbefore = TT.diff(
      DateTime.fromISO(
        missionPreviousDay.landTimes.split(' ')[
          missionPreviousDay.landTimes.split(' ').length - 1
        ]
      ),
      ['hours']
    )
      .minus({ hours: 2 })
      .as('hours');
  }
  if (missionPreviousDay === undefined) {
    CRbefore = `No mission previous day`;
  }

  if (missionSet === undefined) {
    return null;
  }
  if (isLoading === true) {
    return null;
  }

  if (type === 'first') {
    return (
      <Grid
        item>{`Crew Rest before (scheduled land to brief): ${CRbefore}`}</Grid>
    );
  }
  if (type === 'last') {
    return (
      <Grid
        item>{`Crew Rest after (scheduled land to brief): ${CRafter}`}</Grid>
    );
  }

  return null;
};
