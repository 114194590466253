import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { SimpleMap } from '../Components/Modal/googlemaps.js';
import ModalWrapper from '../Components/Modal/ModalWrapper.jsx';

export default function GoogleMapLink({ props }) {
  const [open, setOpen] = useState(false);

  const title = `${props?.pod}-${props?.poa}, ${props?.id}, Validation Date:${props?.valdate}`;

  return (
    <>
      <Button
        variant='contained'
        size='small'
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}>
        Map
      </Button>
      <ModalWrapper title={title} open={open} setOpen={setOpen}>
        <SimpleMap data={props} />
      </ModalWrapper>
    </>
  );
}
