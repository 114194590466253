import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function MyFallbackComponent({
  componentStack,
  error,
  ...rest
}) {
  console.log('(Myfallback comp) Error: ', error.toString());
  console.log('(Myfallback comp) Stacktrace:: ', componentStack);
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )}
    />
  );
}
