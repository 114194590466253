const { DateTime } = require('luxon');

/* 
History object
{
name:
date: 
typeOfEdit: userChange, drag, auto, missionGen
statusOfEdit: new, update, cancel, delete
eventOrMission: event, mission
eventType: mission, maintenance, reserve, null, etc
startDate
endDate
dateArray:[]
buno:
status: JALIS, ITS, mod, ect

}

*/

export const setHistoryMissionGen = async (headerValues, mission) => {
  let history = [];
  let startDate,
    endDate = '';
  let dateArray = [];
  let itinerary = [];

  if (mission && Array.isArray(mission)) {
    startDate = DateTime.fromISO(mission[0].toTimeZulu).toISODate();
    endDate = DateTime.fromISO(
      mission[mission.length - 1].landTimeZulu
    ).toISODate();

    let lastDate = '';
    mission.forEach((leg, ind) => {
      if (ind === 0) {
        dateArray.push(DateTime.fromISO(leg.toTimeZulu).toISODate());
        lastDate = DateTime.fromISO(leg.toTimeZulu).toISODate();
      } else {
        if (DateTime.fromISO(leg.landTimeZulu).toISODate() !== lastDate) {
          dateArray.push(DateTime.fromISO(leg.landTimeZulu).toISODate());
        }
      }
    });

    mission.forEach((leg, index) => {
      let mission_to_itin = {};
      mission_to_itin.leg = index + 1;
      mission_to_itin.pod = leg.pod;
      mission_to_itin.poa = leg.poa;
      leg.RON === true
        ? (mission_to_itin.RON = true)
        : (mission_to_itin.RON = false);
      leg.crewChange === true
        ? (mission_to_itin.CC = true)
        : (mission_to_itin.CC = false);
      mission_to_itin.fuel = false;

      let airlifts = [];
      if (leg.airlifts) {
        leg.airlifts.forEach((lift) => {
          airlifts.push({
            id: lift.id,
            airliftName: lift.airliftName,
          });
        });
      }
      mission_to_itin.airlifts = airlifts;
      itinerary.push(mission_to_itin);
    });
  }

  if (
    headerValues.history &&
    headerValues.history !== '' &&
    headerValues.history !== null
  ) {
    history = JSON.parse(headerValues.history);
    history.unshift({
      name: localStorage.getItem('userName'),
      date: DateTime.local().toISO(),
      typeOfEdit: 'missionGen',
      statusOfEdit: 'update',
      eventOrMission: 'mission',
      buno: headerValues.buno,
      eventType: 'mission',
      itinerary: itinerary,
      startDate: startDate,
      endDate: endDate,
      dateArray: dateArray,
      notes: 'Generated on mission generator page',
    });
  } else {
    history.push({
      name: localStorage.getItem('userName'),
      date: DateTime.local().toISO(),
      typeOfEdit: 'missionGen',
      statusOfEdit: 'new',
      eventOrMission: 'mission',
      buno: headerValues.buno,
      eventType: 'mission',
      itinerary: itinerary,
      startDate: startDate,
      endDate: endDate,
      dateArray: dateArray,
      notes: 'Generated on mission generator page',
    });
  }
  return JSON.stringify(history);
};
