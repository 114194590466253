import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQueryClient } from 'react-query';
import ModalWrapper from './Components/Modal/ModalWrapper';

const saveRoute = async ({ CFP, typeAircraft }) => {
  const dataToSave = {
    CFP: CFP,
    aircraftType: typeAircraft,
    enteredBy: localStorage.getItem('userName'),
  };
  const resp = await fetch('/db/PmGaskljawhe.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(dataToSave),
  });

  if (resp.status === 401) {
    console.log('Problem uploading route to parse file (Error 401)');
  }
  if (resp.status === 403) {
    console.log('Problem parsing route (Error 403)');
  }

  if (!resp.ok) {
    throw new Error('Network response was not ok, status: ' + resp.status);
  }
};

export default function RouteEntry({ props }) {
  const buttonStyle = {
    margin: '5px',
  };

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        style={buttonStyle}
        size='small'
        variant='contained'
        alt='Gouge'
        onClick={(e) => {
          e.preventDefault();
          setOpenModal(true);
        }}>
        Route Entry
      </Button>
      <ModalWrapper
        open={openModal}
        setOpen={setOpenModal}
        title={'Route Entry - copy Milplan CFP'}>
        <ModalMapBody setOpenModal={setOpenModal} />
      </ModalWrapper>
    </>
  );
}

function ModalMapBody({ setOpenModal }) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        flexGrwow: 1,
      },
    },
  }));

  const classes = useStyles();

  const [typeAircraft, settypeAircraft] = useState('');
  const [CFP, setCFP] = useState('');
  const alert = useAlert();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async ({ airliftItinerary, airliftPOC, airliftHAZ }) => {
      await saveRoute({ CFP, typeAircraft });
    },
    {
      onMutate: async () => {
        setOpenModal(false);
      },
      // On failure, roll back to the previous value
      onError: (err, variables) => {
        setOpenModal(true);
        console.log(err);
        window.alert('Error saving');
      },
      // After success or failure, refetch the todos query
      onSuccess: () => {
        window.alert('Success');
        let acType = '';
        switch (typeAircraft) {
          case 'C-40':
            acType = 'c40routes';
            break;
          case 'C-130':
            acType = 'c130routes';
            break;
          case 'C-26':
            acType = 'c26routes';
            break;
          default:
            acType = 'c26routes';
            break;
        }
        queryClient.invalidateQueries(['routes', acType]);
      },
    }
  );

  const handleChange = (event) => {
    settypeAircraft(event.target.value);
  };
  const handleCFPChange = (e) => setCFP(e.target.value);

  return (
    <>
      <Grid
        container
        spacing={3}
        direction='row'
        justify='center'
        alignItems='center'>
        <Grid item md={6}>
          <TextField
            id='outlined-basic-milplan'
            label='Milplan'
            variant='outlined'
            multiline
            fullWidth
            rows='8'
            value={CFP}
            onChange={handleCFPChange}
            //TODO set as controlled
          />
        </Grid>
        <Grid item md={4} style={{ textAlign: 'center' }}>
          <FormControl className={classes.formControl}>
            <InputLabel id='typeAircraft'>Type Aircraft</InputLabel>
            <Select
              labelId='type-aircraft-label'
              id='type-aircraft'
              value={typeAircraft}
              onChange={handleChange}>
              <MenuItem value={'C-26'}>C-26</MenuItem>
              <MenuItem value={'C-130'}>C-130</MenuItem>
              <MenuItem value={'C-40'}>C-40</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <Button
            variant='outlined'
            color='primary'
            onClick={(e) => {
              e.preventDefault();
              if (typeAircraft === '' && CFP === '') {
                alert.show(
                  'No aircraft type selected and no CFP data present. 😩'
                );
              } else if (CFP === '') {
                alert.show('No data input. 😩');
              } else if (typeAircraft === '') {
                alert.show('No aircraft type selected. 😩');
              } else {
                mutation.mutate({ CFP, typeAircraft });
              }
            }}>
            Submit Route
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
