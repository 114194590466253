import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from 'react-query';
import '../App.css';
import MyFallbackComponent from '../Errors/MyFallbackComponentRedirect';
import StandardLoadingFallback from '../Loading/StandardLoadingFallback';
import useSplashTable from '../Utils/Hooks/useSplashTable';

export default function SplashPage() {
  return (
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      <Suspense fallback={<h3>Loading....</h3>}>
        <SplashDetails />
      </Suspense>
    </ErrorBoundary>
  );
}

function SplashDetails() {
  const queryClient = useQueryClient();
  const { status, data } = useSplashTable();

  const getAirportData = async () => {
    const data = await fetch('/db/AasdhilaewrAll.php', {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
      },
    });
    return data.json();
  };

  const getAicraftLineup = async () => {
    const resp = await fetch('/db/loadAircraftLineup.db.php', {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
      },
    });
    if (!resp.ok) {
      throw new Error('Network response was not ok');
    }
    return resp.json();
  };

  const getEmails = async () => {
    const data = await fetch('/db/getEmails.php', {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
      },
    });
    return data.json();
  };

  const prefetchEmails = async () => {
    await queryClient.prefetchQuery(['emailSet'], getEmails, {
      staleTime: Infinity,
      cacheTime: Infinity,
    });
  };

  const prefetchAirports = async () => {
    await queryClient.prefetchQuery(['airportsSet'], getAirportData, {
      staleTime: Infinity,
      cacheTime: Infinity,
    });
  };

  const prefetchAirlifts = async () => {
    await queryClient.prefetchQuery(['airliftSet'], async () => {
      const resp = await fetch('/db/chnkAL.php', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('token'),
        },
      });
      if (!resp.ok) {
        throw new Error('Network response was not ok');
      }
      return resp.json();
    });
  };
  const prefetchAircraftLineup = async () => {
    await queryClient.prefetchQuery(['aircraftLineup'], getAicraftLineup, {
      staleTime: Infinity,
    });
  };

  prefetchAirports();
  prefetchAirlifts();
  prefetchAircraftLineup();
  prefetchEmails();

  return (
    <>
      {status === 'success' ? (
        data.map((entry, index) => {
          let entryDate = new Date(entry.date);

          return (
            <Card
              key={index}
              className='splashCard'
              style={{
                backgroundColor: 'antiquewhite',
                whiteSpace: 'pre-wrap',
                textAlign: 'center',
              }}>
              <CardContent>
                {unescape(entry.entry)}
                <br />
                {unescape(entryDate.toDateString())}
              </CardContent>
            </Card>
          );
        })
      ) : (
        <StandardLoadingFallback />
      )}
    </>
  );
}
