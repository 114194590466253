import React from 'react';
import { useHistory } from 'react-router-dom';
import useAirlifts from '../Utils/Hooks/useAirlifts';
import ContactEntry from './ContactEntry';

export default function ContactPage({ data }) {
  return (
    <div style={{ textAlign: 'center', margin: '10px', marginTop: '20px' }}>
      <ContactTable key={'asdert'} data={data} />
    </div>
  );
}

function ContactTable({ data }) {
  const { data: airliftInformation, status } = useAirlifts();
  let history = useHistory();
  let airlift_array;
  let airlift_array_parsed;
  let lift_set = new Set();
  let airliftData = [];

  if (status === 'error') {
    throw Error(status);
  }
  if (status === 'loading') {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <h3>Loading Mission detail...</h3>
      </div>
    );
  }

  if (data.airlifts1 === null || data.airlifts1 === '') {
    alert('No airlifts on this mission?  No data present.');
  } else {
    airlift_array = data.airlifts1.split('/').map((elem) => {
      return elem.trim();
    });

    airlift_array_parsed = airlift_array.filter((elem) => {
      return elem !== '';
    });

    airlift_array_parsed.forEach((element) => {
      let element_array = element.split(',').map((elem) => {
        return elem.trim();
      });
      element_array.forEach((elem) => {
        lift_set.add(elem);
      });
    });
    if (lift_set === '' || lift_set === null) {
      alert('No airlifts attached to this mission');
    }
    if (airliftInformation.length === 0) {
      alert('Problem loading Airlift Data. Returning to Schedule Page.');
      history.push('/detschedule');
    }
    [...lift_set].forEach((liftName) => {
      let lift = airliftInformation.find((airlift) => {
        return liftName === airlift.airliftName;
      });
      if (lift !== undefined) {
        //alert('Problem loading Airlift Data. Return to Schedule Page.');
        airliftData.push(lift);
      }
    });
  }
  //airliftData is array (Set) of Airlifts on mission

  return (
    <div>
      <h3>Airlift Contact Information</h3>
      {airliftData.length !== 0
        ? airliftData.map((data, index) => {
            return (
              <div key={index}>
                <ContactEntry key={data.airliftID + 'c'} airliftData={data} />
                <br />
              </div>
            );
          })
        : ''}
      {airliftData.length === 0 ? (
        <h3>No airlifts associated with this mission.</h3>
      ) : (
        ''
      )}
    </div>
  );
}
