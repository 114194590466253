import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { checkJALIS } from './checkJALIS.js';

export const JALISCheck = ({ JALIS_output, setJALIS_output }) => {
  const [show, setShow] = useState(false);
  const [inputJALIS, setinputJALIS] = useState('');

  function parseJALIS() {
    if (inputJALIS === '') {
      alert('No data');
    } else {
      setJALIS_output(checkJALIS(inputJALIS));
    }
  }

  return (
    <Grid
      container
      direction='column'
      spacing={3}
      alignItems='center'
      justify='center'
      style={{ marginTop: '1vh', marginBottom: '1vh' }}>
      <Grid
        container
        item
        direction='row'
        spacing={3}
        alignItems='center'
        justify='center'>
        <Button
          variant='outlined'
          color='primary'
          onClick={(e) => {
            e.preventDefault();
            setShow(!show);
            setJALIS_output({});
          }}>
          {show === true ? 'Hide JALIS check' : 'Show JALIS check'}
        </Button>
      </Grid>

      {show === true ? (
        <Grid
          container
          item
          direction='row'
          spacing={3}
          alignItems='center'
          justify='center'>
          <Grid item>
            <TextField
              id='outlined-multiline-flexible'
              label='JALIS'
              multiline
              rows='20'
              style={{ minWidth: '40vw' }}
              value={inputJALIS}
              onChange={(e) => {
                setinputJALIS(e.target.value);
              }}
              margin='normal'
              helperText='Copy JALIS here'
              variant='outlined'
            />
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              color='primary'
              onClick={(e) => {
                e.preventDefault();
                parseJALIS();
              }}>
              Parse
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              color='primary'
              onClick={(e) => {
                e.preventDefault();
                setJALIS_output({});
              }}>
              Clear
            </Button>
          </Grid>
          <Grid item>
            {JALIS_output && (
              <Typography
                variant='h5'
                color='initial'
                style={{ display: 'inline-block' }}>
                AC Type: {JALIS_output.AC_type}
                {<br />}
                Callsign: CNV{' '}
                {JALIS_output.callsign && JALIS_output?.callsign.split(' ')[2]}
                {<br />}
                Squadron: {JALIS_output.squadron}
                {<br />}
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
