import { Hidden, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useContext, useEffect, useState } from 'react';
import { AirliftFilter } from '../store';
import useAirlifts from '../Utils/Hooks/useAirlifts';
import { AirliftChip } from './AirliftChips';
const { DateTime } = require('luxon');

export default function AirliftDisplay({ importFromLR, setairliftSelected }) {
  const { data: airlifts, status: airliftsStatus, isFetching } = useAirlifts();
  const [filteredAirlifts, setFilteredAirlifts] = useState([]);
  /* const [airliftTextFilter, setairliftTextFilter] = useState(''); */
  const [airliftTextFilter, setairliftTextFilter] = useContext(AirliftFilter);

  const [filter, setfilter] = useState({
    future: false,
    import: false,
  });

  useEffect(() => {
    if (importFromLR !== undefined) {
      setfilter({ ...filter, import: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFromLR]);

  useEffect(() => {
    if (airlifts) {
      if (filter.future === true) {
        setFilteredAirlifts(
          airlifts.filter((elem) => {
            return (
              DateTime.fromISO(elem.dateScheduled) > DateTime.local() &&
              elem.airliftName
                .toLowerCase()
                .indexOf(airliftTextFilter.toLowerCase()) > -1
            );
          })
        );
      } else if (importFromLR !== undefined && filter.import === true) {
        if (importFromLR.itinerary) {
          let airliftSetFromLR = new Set();
          importFromLR.itinerary.forEach((leg) => {
            leg.airlifts.map((lift) => airliftSetFromLR.add(lift));
          });

          let airliftSetFromLR_array = Array.from(airliftSetFromLR);
          let tt = [];
          airliftSetFromLR_array.forEach((lift) => {
            if (airlifts.length !== 0) {
              let returned = airlifts.filter((elem) => elem.id === lift.id);
              tt.push(...returned);
            }
          });
          setFilteredAirlifts(tt);
        } else if (importFromLR.fullMissionDataObject) {
          if (importFromLR.modification === null) {
            //Mission not in mod
            let airliftSetFromLR = new Set();
            importFromLR.fullMissionDataObject.forEach((leg) => {
              if (leg.airlifts) {
                leg.airlifts.map((lift) => airliftSetFromLR.add(lift));
              }
              let airliftSetFromLR_array = Array.from(airliftSetFromLR);
              let tt = [];
              airliftSetFromLR_array.forEach((lift) => {
                if (airlifts.length !== 0) {
                  let returned = airlifts.filter((elem) => elem.id === lift.id);
                  tt.push(...returned);
                }
              });
              setFilteredAirlifts(tt);
            });
          }
          if (importFromLR.modification !== null) {
            //Mission in mod
            let airliftSetFromLR = new Set();
            importFromLR.modification.fullMissionDataObject.forEach((leg) => {
              if (leg.airlifts) {
                leg.airlifts.map((lift) => airliftSetFromLR.add(lift));
              }
              let airliftSetFromLR_array = Array.from(airliftSetFromLR);
              let tt = [];
              airliftSetFromLR_array.forEach((lift) => {
                if (airlifts.length !== 0) {
                  let returned = airlifts.filter((elem) => elem.id === lift.id);
                  tt.push(...returned);
                }
              });
              setFilteredAirlifts(tt);
            });
          }
        }
      } else {
        setFilteredAirlifts(
          airlifts.filter((elem) => {
            return (
              elem.airliftName &&
              elem.airliftName
                .toLowerCase()
                .indexOf(airliftTextFilter.toLowerCase()) > -1
            );
          })
        );
      }
    }

    return () => {
      setFilteredAirlifts([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airlifts, filter, airliftTextFilter, importFromLR]);

  const handleChange = (name) => (event) => {
    setfilter({ ...filter, [name]: event.target.checked });
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: 'lightblue',
          textAlign: 'center',
          padding: '5px',
        }}>
        <Typography variant='h5' color='initial'>
          Airlift Filters
        </Typography>
        <Hidden smDown>
          <Grid container justify='center' alignItems='center' direction='row'>
            <Grid item container direction='column' xs={6}>
              <Grid item xs>
                <FormControl component='fieldset'>
                  <FormGroup>
                    <FormControlLabel
                      style={{ margin: '0px' }}
                      control={
                        <Checkbox
                          checked={filter.future}
                          style={{ padding: '1px' }}
                          onChange={handleChange('future')}
                        />
                      }
                      label='Future Only'
                    />
                    {importFromLR !== undefined ? (
                      <FormControlLabel
                        style={{ margin: '0px' }}
                        control={
                          <Checkbox
                            checked={filter.import}
                            style={{ padding: '1px' }}
                            onChange={handleChange('import')}
                          />
                        }
                        label='Filter lifts from LR'
                      />
                    ) : null}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container direction='column' xs={6}>
              <Grid item xs>
                <TextField
                  id='outlined-basic'
                  label='Airlift filter'
                  variant='outlined'
                  size='small'
                  value={airliftTextFilter}
                  onChange={(e) => {
                    e.preventDefault();
                    setairliftTextFilter(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Box>
      <Box
        style={{
          backgroundColor: 'lightgrey',
          textAlign: 'center',
          padding: '2px',
          height: '37vh',
          overflowY: 'scroll',
        }}>
        {airliftsStatus === 'error' ? (
          <span>Error loading airlifts</span>
        ) : isFetching === true ? (
          <span>Loading airlifts....</span>
        ) : (
          filteredAirlifts?.map((elem) => {
            return (
              <AirliftChip
                props={elem}
                key={elem.id}
                setairliftSelected={setairliftSelected}
              />
            );
          })
        )}
      </Box>
    </>
  );
}
