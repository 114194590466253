export default function AddBCCemails(ICAO_array, plane, emails) {
  let emailsToAdd = '';

  if (emails && emails.length !== 0) {
    ICAO_array.forEach((field) => {
      emails.forEach((elem) => {
        if (elem.ICAO === field) {
          emailsToAdd += elem.email + ';';
        }
      });
    });

    emails.forEach((elem) => {
      if (elem.type === 'all') {
        emailsToAdd += elem.email + ';';
      }
    });
  }

  return emailsToAdd;
}
