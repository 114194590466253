const { DateTime } = require('luxon');

export function translateToArray(airlifts_old) {
  //debugger;
  // a,c / c / / d => [[a c], [c], [], [d]]
  let total_airlift_array = [];
  let leg_airlift_array = [];
  const al_array_1 = airlifts_old.split('/');
  al_array_1.forEach((element) => {
    element = element.trim();
    if (element !== '') {
      const element_old = element.split(',');
      element_old.forEach((lift) => {
        lift = lift.trim();
        leg_airlift_array.push(lift);
      });
    } else {
      //Nothing
    }

    total_airlift_array.push(leg_airlift_array);
    leg_airlift_array = [];
  });
  total_airlift_array.pop();
  return total_airlift_array;
}

export function changeTime(timeInput, zoneInput) {
  const timeZulu = DateTime.fromISO(timeInput, { zone: 'UTC' });
  const timeLocal = timeZulu.setZone(zoneInput);
  return timeLocal;
}

export function decodeACtype(type) {
  switch (type) {
    case 'c40routes':
      return 'C-40';
    case 'c130routes':
      return 'C-130';
    case 'c26routes':
      return 'C-26';

    default:
      console.log('Problem with decodeACtype');
      return 'unknown';
  }
}

export function decodeAircraftServicing(type) {
  switch (type) {
    case 'C-40':
      return 'K-Loader, 30,000lbs Jet-A fuel';
    case 'C-130':
      return 'C-130 services';
    case 'C-26':
      return 'C-26 services';

    default:
      return 'error in decode aircraft servicing';
  }
}

export function decodeAirlifts(index, airliftArray) {
  /* [[a,b], [b, c],[c] ]  => Leg 1, Leg 2, Leg 3
Decode the airlifts on each leg :
thruLoad, onLoad, offLoad
*/

  const thruLoad = [];
  const onLoad = [];
  const offLoad = [];
  const length_airlift = airliftArray.length - 1;
  if (index === length_airlift) {
    //Last PPR, last airfield on itinerary and all airlifts assumed to offLoad
    airliftArray[index].forEach((lift_on_leg) => {
      offLoad.push(lift_on_leg);
    });
  }
  //All other stops
  else {
    airliftArray[index].forEach((lift_on_leg) => {
      const lift_match = airliftArray[index + 1].find((lift_on_next) => {
        return lift_on_next === lift_on_leg;
      });
      if (lift_match === undefined) {
        offLoad.push(lift_on_leg);
      }
    });
    //Onload:
    airliftArray[index + 1].forEach((lift_on_next_leg) => {
      const lift_match = airliftArray[index].find((lift_on_leg) => {
        return lift_on_leg === lift_on_next_leg;
      });
      if (lift_match === undefined) {
        onLoad.push(lift_on_next_leg);
      } else {
        thruLoad.push(lift_on_next_leg);
      }
    });
  }

  return [onLoad, thruLoad, offLoad];
}

export function createBody(
  aircraft_type,
  email_type,
  props,
  missionObj,
  history,
  onLoadObj,
  thruLoadObj,
  offLoadObj
) {
  function decodeHAZ(haz) {
    let haz_string = '';
    const haz_array = haz.split(';');
    haz_array.pop();
    const num_items = haz_array.length / 5;
    for (let index = 0; index < num_items; index++) {
      const UN = haz_array[index * 5] || 'Unknown';
      const name = haz_array[index * 5 + 1] || 'Unknown';
      const classHAZ = haz_array[index * 5 + 2] || 'Unknown';
      const weight = haz_array[index * 5 + 4] || 'Unknown';
      haz_string += `${UN} / ${name} / ${classHAZ} / ${weight}%0D%0A`;
    }
    return haz_string;
  }

  let onLoad_string = '';
  let HAZ_string = '';
  if (onLoadObj.length === 0) {
    onLoad_string = 'No airlifts onloaded at this location';
  } else {
    onLoad_string = 'Airlifts to be onloaded at this location';
    onLoadObj.forEach((lift) => {
      onLoad_string += `%0D%0A 
Passengers: ${lift.PAX} / Total cargo weight (lbs): ${lift.cargoWeight}%0D%0A 
Hazardous Cargo: ${lift.HAZ === '0' ? 'None' : 'YES'}%0D%0A`;
      if (lift.HAZ === '1') {
        HAZ_string = decodeHAZ(lift.HAZdescription);
        onLoad_string += `UN Code / Description / Class / Weight (lbs)%0D%0A ${HAZ_string}`;
      }
    });
  }

  let offLoad_string = '';
  if (offLoadObj.length === 0) {
    offLoad_string = 'No airlifts offloaded at this location';
  } else {
    offLoad_string = 'Airlifts to be offloaded at this location';
    offLoadObj.forEach((lift) => {
      offLoad_string += `%0D%0A 
Passengers: ${lift.PAX} / Total cargo weight (lbs): ${lift.cargoWeight}%0D%0A 
Hazardous Cargo: ${lift.HAZ === '0' ? 'None' : 'YES'}%0D%0A`;
      if (lift.HAZ === '1') {
        HAZ_string = decodeHAZ(lift.HAZdescription);
        offLoad_string += `UN Code / Description / Class / Weight (lbs)%0D%0A ${HAZ_string}`;
      }
    });
  }

  let thruLoad_string = '';
  if (thruLoadObj.length === 0) {
    thruLoad_string = 'No airlifts onboard at this location';
  } else {
    thruLoad_string =
      'Airlifts onboard (neither on nor offload) at this location';
    thruLoadObj.forEach((lift) => {
      thruLoad_string += `%0D%0A 
Passengers: ${lift.PAX} / Total cargo weight (lbs): ${lift.cargoWeight}%0D%0A 
Hazardous Cargo: ${lift.HAZ === '0' ? 'None' : 'YES'}%0D%0A`;
      if (lift.HAZ === '1') {
        HAZ_string = decodeHAZ(lift.HAZdescription);
        thruLoad_string += `UN Code / Description / Class / Weight (lbs)%0D%0A ${HAZ_string}`;
      }
    });
  }

  //C-40, C-130, C-26 , initial, cancel, change
  let body = '';
  //Set common, standard part
  let body_initial = `To Whom It May Concern (${props.airfieldICAO}),%0D%0A
On behalf of Task Force 63 we would like to request aircraft servicing for the mission noted below.  Please include the email addresses in the cc line on any email correspondence regarding this request. %0D%0A
%0D%0A
Call Sign:   ${missionObj.cnv.split('-')[0]}%0D%0A
Branch:      US NAVY%0D%0A
Type:        ${aircraft_type}%0D%0A
BUNO:        TBD%0D%0A
  
ARR FM:      ${props.beforeAirfield} %0D%0A
ETA (Z):     ${DateTime.fromISO(props.arrivalDateTime).toFormat('DD T')}%0D%0A
ETD (Z):     ${DateTime.fromISO(props.departureDateTime).toFormat('DD T')}%0D%0A
DEP TO:      ${props.nextAirfield}%0D%0A
%0D%0A
Cargo information: %0D%0A
${onLoad_string}%0D%0A%0D%0A
${offLoad_string}%0D%0A%0D%0A
${thruLoad_string}%0D%0A%0D%0A`;

  let body_change = `To Whom It May Concern (${props.airfieldICAO}),%0D%0A
**CHANGE OF PREVIOUS REQUEST**%0D%0A
On behalf of Task Force 63 we would like to request aircraft servicing for the mission noted below.  Please include the email addresses in the cc line on any email correspondence regarding this request.  Please note this is a change of an existing request-for reference the old request details have been included.%0D%0A
%0D%0A
Call Sign:   ${missionObj.cnv.split('-')[0]}%0D%0A
Branch:      US NAVY%0D%0A
Type:        ${aircraft_type}%0D%0A
BUNO:        TBD%0D%0A
NEW ARR FM:      ${props.beforeAirfield}%0D%0A
ETA (Z):     ${DateTime.fromISO(props.arrivalDateTime).toFormat('DD T')}%0D%0A
ETD (Z):     ${DateTime.fromISO(props.departureDateTime).toFormat('DD T')}%0D%0A
NEW DEP TO:      ${props.nextAirfield}%0D%0A
%0D%0A
OLD ARR FM:      ${history[0].beforeAirfield || ''}%0D%0A
OLD ETA(Z):         ${DateTime.fromISO(
    history[0].arrivalDateTime || ''
  ).toFormat('DD T')}%0D%0A
OLD ETD(Z):         ${DateTime.fromISO(
    history[0].departureDateTime || ''
  ).toFormat('DD T')}%0D%0A
OLD DEP TO:      ${history[0].nextAirfield || ''}%0D%0A
%0D%0A
NEW Cargo information: %0D%0A
${onLoad_string}%0D%0A%0D%0A
${offLoad_string}%0D%0A%0D%0A
${thruLoad_string}%0D%0A%0D%0A`;

  let body_cancel = `To Whom It May Concern (${props.airfieldICAO}),%0D%0A
On behalf of Task Force 63 we would like to CANCEL the PPR and/or aircraft servicing/support for the mission with details are below.  Please include the email addresses in the cc line on any email correspondence regarding this request. %0D%0A
%0D%0A
FLIGHT INFORMATION FOR CANCELED REQUEST%0D%0A
Call Sign:   ${missionObj.cnv.split('-')[0]}%0D%0A
Branch:      US NAVY%0D%0A
Type:        ${aircraft_type}%0D%0A
BUNO:        TBD%0D%0A
ARR FM:      ${props.beforeAirfield}%0D%0A
ETA (Z):     ${DateTime.fromISO(props.arrivalDateTime).toFormat('DD T')}%0D%0A
ETD (Z):     ${DateTime.fromISO(props.departureDateTime).toFormat('DD T')}%0D%0A
DEP TO:      ${props.nextAirfield}%0D%0A
%0D%0A
We appreciate your support and should you have any further questions please contact Task Force 63 Air Operations.%0D%0A
%0D%0A
Very Respectfully,%0D%0A
%0D%0A
Task Force 63 Air Logistics%0D%0A 
DSN 314-626-3150%0D%0A
COMM: +39-081-568-3150  %0D%0A
Duty 24hr number: COMM +39-335-774-4952%0D%0A
Duty email: m-na-ctf-63airlogops-gd@eu.navy.mil
  `;

  if (aircraft_type === 'C-40' && email_type === 'initial') {
    body += body_initial;
    body += `
Fuel:        35,000 lbs JET A%0D%0A
Requested services:  Parking, fuel, cargo offload via K-Loader or High Loader%0D%0A
%0D%0A
Notes:       Please confirm the name and phone number of on-site servicing agent (if applicable), parking location (if known), and type of cargo offload equipment available (if required).%0D%0A
%0D%0A
Please note we are only a scheduling agency and not the unit flying this mission. %0D%0A
%0D%0A
Very Respectfully,%0D%0A
%0D%0A
Task Force 63 Air Logistics%0D%0A 
DSN 314-626-3150%0D%0A
COMM: +39-081-568-3150  %0D%0A
Duty 24hr number: COMM +39-335-774-4952%0D%0A
Duty email: m-na-ctf-63airlogops-gd@eu.navy.mil
`;
  }
  if (aircraft_type === 'C-40' && email_type === 'change') {
    body += body_change;
    body += `
Fuel:        35,000 lbs JET A%0D%0A
Requested services:  Parking, fuel, cargo offload via K-Loader or High Loader%0D%0A
%0D%0A
Notes:       Please confirm the name and phone number of on-site servicing agent (if applicable), parking location (if known), and type of cargo offload equipment available (if required).%0D%0A
Please note we are only a scheduling agency and not the unit flying this mission. %0D%0A
%0D%0A
Very Respectfully,%0D%0A
%0D%0A
Task Force 63 Air Logistics%0D%0A 
DSN 314-626-3150%0D%0A
COMM: +39-081-568-3150  %0D%0A
Duty 24hr number: COMM +39-335-774-4952%0D%0A
Duty email: m-na-ctf-63airlogops-gd@eu.navy.mil
`;
  }
  if (aircraft_type === 'C-40' && email_type === 'cancel') {
    body += body_cancel;
  }
  if (aircraft_type === 'C-130' && email_type === 'initial') {
    body += body_initial;
    body += `
Fuel:        55,000 lbs JET A%0D%0A
Requested services:  Parking, fuel, cargo offload.%0D%0A
%0D%0A
Notes:       Please confirm name and phone number of on-site servicing agent, parking location(if known), and type of cargo offload equipment available.%0D%0A
%0D%0A
Please note we are only a scheduling agency and not the unit flying this mission. %0D%0A
%0D%0A
%0D%0A
Very Respectfully,%0D%0A
%0D%0A
Task Force 63 Air Logistics%0D%0A 
DSN 314-626-3150%0D%0A
COMM: +39-081-568-3150  %0D%0A
Duty 24hr number: COMM +39-335-774-4952%0D%0A
Duty email: m-na-ctf-63airlogops-gd@eu.navy.mil
`;
  }
  if (aircraft_type === 'C-130' && email_type === 'change') {
    body += body_change;
    body += `Fuel:        55,000 lbs JET A%0D%0A
Requested services:  Parking, fuel, cargo offload via K-Loader or High Loader%0D%0A
%0D%0A
Notes:       Please confirm name and phone number of on-site servicing agent, parking location, and type of cargo offload equipment.%0D%0A
%0D%0A
Very Respectfully,%0D%0A
%0D%0A
Task Force 63 Air Logistics%0D%0A 
DSN 314-626-3150%0D%0A
COMM: +39-081-568-3150  %0D%0A
Duty 24hr number: COMM +39-335-774-4952%0D%0A
Duty email: m-na-ctf-63airlogops-gd@eu.navy.mil`;
  }

  if (aircraft_type === 'C-130' && email_type === 'cancel') {
    body += body_cancel;
  }
  if (aircraft_type === 'C-26' && email_type === 'initial') {
    body += body_initial;
    body += `
Fuel:        4,000 lbs JET A%0D%0A
Requested services:  Parking and fuel%0D%0A
%0D%0A
Notes:       Please confirm name and phone number of on-site servicing agent and parking location (if known).%0D%0A
%0D%0A
Please note we are only a scheduling agency and not the unit flying this mission. %0D%0A
%0D%0A
Very Respectfully,%0D%0A
%0D%0A
Task Force 63 Air Logistics%0D%0A 
DSN 314-626-3150%0D%0A
COMM: +39-081-568-3150  %0D%0A
Duty 24hr number: COMM +39-335-774-4952%0D%0A
Duty email: m-na-ctf-63airlogops-gd@eu.navy.mil
    `;
  }
  if (aircraft_type === 'C-26' && email_type === 'change') {
    body += body_change;
    body += `
Fuel:        4,000 lbs JET A%0D%0A
Requested services:  Parking and fuel%0D%0A
%0D%0A
Notes:       Please confirm name and phone number of on-site servicing agent and parking location (if known).%0D%0A
%0D%0A
Very Respectfully,%0D%0A
%0D%0A
Task Force 63 Air Logistics%0D%0A 
DSN 314-626-3150%0D%0A
COMM: +39-081-568-3150  %0D%0A
Duty 24hr number: COMM +39-335-774-4952%0D%0A
Duty email: m-na-ctf-63airlogops-gd@eu.navy.mil`;
  }

  if (aircraft_type === 'C-26' && email_type === 'cancel') {
    body += body_cancel;
  }

  return body;
}

export function returnColor(row) {
  if (isNaN(Number(row))) {
    return null;
  } else {
    if (row % 2 === 0) {
      return '#ccebff';
    } else {
      return 'lightgrey';
    }
  }
}
