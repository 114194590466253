import { useQuery } from 'react-query';

const getEmails = async (missionID) => {
  const data = await fetch('/db/getEmails.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
  });

  if (!data.ok) {
    throw Error(data.statusText);
  } else {
    //console.log(data.json());
    return data.json();
  }
};

export default function useEmails(missionID, missionIdent) {
  return useQuery(['emailSet'], getEmails, {
    staleTime: Infinity,
    placeholderData: [],
  });
}
