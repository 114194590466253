import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export const FirstColumn = ({
  missionDataToImport,
  setOpenMissionModal,
  setExportData,
}) => {
  function exportAndClose() {
    setOpenMissionModal(false);
    setExportData();
  }

  function close() {
    setOpenMissionModal(false);
  }

  return (
    <Grid container item direction='column' justify='center' spacing={1} xs={3}>
      <Grid item>
        <Typography variant='h5' color='initial'>
          BuNo: {missionDataToImport?.buno}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='h5' color='initial'>
          Aircraft Type: {missionDataToImport?.aircraftType}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='h5' color='initial'>
          Start Date: {missionDataToImport?.startDate}
        </Typography>
      </Grid>
      <Grid item>
        <Button variant='contained' color='primary' onClick={exportAndClose}>
          Import
        </Button>
      </Grid>
      <Grid item>
        <Button variant='contained' color='secondary' onClick={close}>
          Close (no import)
        </Button>
      </Grid>
    </Grid>
  );
};
