import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import ModalWrapper from '../../Components/Modal/ModalWrapper';
import useAircraftLineup from '../../Utils/Hooks/useAircraftLineup';
import useAirlifts from '../../Utils/Hooks/useAirlifts';
import { DisplayMissionModal } from './DisplayMissionModal';

export const ImportfromLR = ({ setheaderValues, headerValues }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openMissionModal, setOpenMissionModal] = useState(false);
  const [missionDataToImport, setmissionDataToImport] = useState({});

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        onClick={(e) => {
          e.preventDefault();
          setOpenModal(true);
        }}>
        Import
      </Button>
      <ModalWrapper
        open={openModal}
        setOpen={setOpenModal}
        title={'Import from Excel LR'}>
        <ImportFromLRModal
          setOpenModal={setOpenModal}
          setOpenMissionModal={setOpenMissionModal}
          setmissionDataToImport={setmissionDataToImport}
        />
      </ModalWrapper>
      <ModalWrapper
        open={openMissionModal}
        setOpen={setOpenMissionModal}
        title={'Mission Data to Import'}>
        <DisplayMissionModal
          setOpenMissionModal={setOpenMissionModal}
          missionDataToImport={missionDataToImport}
          setmissionDataToImport={setmissionDataToImport}
          setheaderValues={setheaderValues}
          headerValues={headerValues}
        />
        {/*  <TestModal /> */}
      </ModalWrapper>
    </div>
  );
};

function ImportFromLRModal({
  setOpenModal,
  setmissionDataToImport,
  setOpenMissionModal,
}) {
  const { data: BUNOS } = useAircraftLineup();
  const { data: airliftSet } = useAirlifts();

  const [importText, setimportText] = useState(``);

  const setRawText = (e) => setimportText(e.target.value);

  const parseLR = () => {
    let str = importText.toUpperCase().trim();

    let buno, aircraftType, aircraftFromLineup, route, type, startDate;
    let airlifts = [];

    buno = str
      .match(/BUNO.*\s+[0-9]{6}/)
      .toString()
      .replace('BUNO', '')
      .replace(':', '')
      .trim();

    if (BUNOS) {
      aircraftFromLineup = BUNOS.find((elem) => elem.buno === buno);
    }
    if (!aircraftFromLineup) {
      alert(
        'Aircraft (' +
          buno +
          ') not found in database.  Ensure that aircraft BuNo is listed correctly and is in database lineup.'
      );
      return;
    } else {
      aircraftType = aircraftFromLineup.aircraftType;
      type = aircraftFromLineup.type;
    }

    //Parse start date
    startDate = str
      .match(/START.*\s*/)
      .toString()
      .replace('DATE', '')
      .replace(':', '')
      .split(/\s+/)[1]
      .trim();

    //Parse route
    route = str.substring(str.lastIndexOf('ROUTE'), str.lastIndexOf('//'));
    if (!str.includes('ROUTE') || !str.includes('//')) {
      alert(
        "Itinerary needs to be preceeded by word 'route' and ended with //."
      );
      return;
    } else {
      if (route.includes(':')) {
        route = route.split(':')[1].trim().split('-');
      } else {
        route = route.trim().split('-');
      }
      var legs = [];
      route.forEach((elem, index) => {
        let leg = { poa: '', pod: '', RON: false, CC: false, airlifts: [] };
        if (route.length === 0 || route.length === 1) {
          alert(
            'There appears to be a problem with the route.  Ensure the route includes at least one leg (POD and POA) and is formatted with a "//" at the end of the line.'
          );
          return;
        } else {
          if (index === 0) {
            leg.legNumber = index;
            leg.pod = elem.trim().replace('ROUTE', '').trim();
            leg.poa = route[index + 1]
              .replace('/R', '')
              .trim()
              .replace('/F', '')
              .trim()
              .replace('/C', '')
              .trim();
            leg.RON = route[index + 1].includes('/R') ? true : false;
            leg.CC = route[index + 1].includes('/C') ? true : false;
            legs.push(leg);
          } else if (index === route.length - 1) {
            //Nothing
          } else {
            leg.legNumber = index;
            leg.pod = elem
              .replace('/R', '')
              .trim()
              .replace('/F', '')
              .trim()
              .replace('/C', '')
              .trim();

            leg.poa = route[index + 1]
              .replace('/R', '')
              .trim()
              .replace('/F', '')
              .trim()
              .replace('/C', '')
              .trim();

            leg.RON = route[index + 1].includes('/R') ? true : false;
            leg.CC = route[index + 1].includes('/C') ? true : false;
            legs.push(leg);
          }
        }
      });
      //Parse airlifts
      let str_array = str.split('\n');
      let airlift_obj = {};
      let airliftValid = false;
      for (let i = 0; i < str_array.length; i++) {
        if (str_array[i].match(/[0-9]{4}\./)) {
          airlift_obj.code = str_array[i].trim();

          //Check to see if airlift is in database
          if (airliftSet && airliftSet.length !== 0) {
            let al = airliftSet.find(
              (elem) => elem.airliftName === airlift_obj.code
            );
            if (al) {
              if (al.POD !== str_array[i - 4].split('-')[0].trim()) {
                console.log(
                  'Problem on import (POD)',
                  al,
                  al.POD,
                  str_array[i - 4].split('-')[0].trim()
                );
                alert(
                  'The POD listed in this import (in the text you pasted) and the POD listed for the airlift, as entered in the database via the airlift entry page, do not match.  Double check your entry and the database entry. Airlift code: ' +
                    al.airliftName
                );
              } else if (
                al.POA !==
                str_array[i - 4].split('-')[1].trim().split(' ')[0].trim()
              ) {
                console.log(
                  'Problem on import (POA)',
                  al,
                  al.POA,
                  str_array[i - 4].split('-')[1].trim().split(' ')[0].trim()
                );
                alert(
                  'The POA listed in this import (in the text you pasted) and the POA listed for the airlift, as entered in the database via the airlift entry page, do not match.  Double check your entry and the database entry. Airlift code: ' +
                    al.airliftName
                );
              } else airlifts.push(al);
              let airliftMatched = false;
              for (let j = 0; j < legs.length; j++) {
                if (legs[j].pod === al.POD) {
                  for (let k = 0; k < legs.length - 1; k++) {
                    if (legs[j + k].poa === al.POA) {
                      airliftValid = true;
                      break;
                    }
                  }
                  if (airliftValid === true) {
                    for (let k = 0; k < legs.length - 1; k++) {
                      if (legs[j + k].poa !== al.POA) {
                        legs[j + k].airlifts.push(al);
                      } else {
                        legs[j + k].airlifts.push(al);
                        airliftMatched = true;
                        break;
                      }
                    }
                  }
                }
              }

              if (airliftMatched === false) {
                alert(
                  'Airlift: ' +
                    al.airliftName +
                    ' does not have a matching itinerary on this import (the listed POD and POA do not match a POD and POA on the itinerary).  Please review the details for this mission.'
                );
              }
            } else {
              alert(
                'Airlift: ' +
                  airlift_obj.code +
                  ' not found in Database.  Ensure that the airlift has been entered in the website via the airlift entry form.  '
              );
            }
          } else {
            alert('Airlift Set not loaded.  Please refresh');
          }
        }
      }
    }

    setmissionDataToImport({
      buno: buno,
      route: route,
      aircraftType: aircraftType,
      type: type,
      legs: legs,
      startDate: startDate,
      airlifts: airlifts,
    });
    setOpenModal(false);
    setOpenMissionModal(true);
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        direction='row'
        justify='center'
        alignItems='center'>
        <Grid item xs={2}>
          <Typography variant='h6' color='initial'>
            Example
          </Typography>
          <Typography variant='body1' color='initial'>
            Buno:
            <br /> Route: LICZ-LERT //
            <br /> ...Airlifts...
            <br /> start date: 2021-06-21
          </Typography>
        </Grid>
        <Grid item md={8}>
          <TextField
            id='outlined-basic-milplan'
            label='Copied text'
            variant='outlined'
            multiline
            fullWidth
            rows='8'
            value={importText}
            onChange={setRawText}
          />
        </Grid>
        <Grid item md={2}>
          <Button variant='outlined' color='primary' onClick={parseLR}>
            Import
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
