import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import './App.css';

const { DateTime } = require('luxon');

//User Type can be admin, dh, fw, c40, c130, c26

//['pending', 'reviewed'],['pending', 'approved'], ['pending', 'reviewed', 'approved']
//StatusChange used for APACS
//StatusChangePR (pending, reviewed) used for Contacts, PPR
//StatusChangePA (pending, approved) used for final approval

const postChange = async ({ newStatusNumber, statusTypeFull, missionObj }) => {
  const dateStamp = DateTime.utc();
  const dataUpdate = {
    type: statusTypeFull,
    newStatus: newStatusNumber,
    missionID: missionObj.missionID,
    name: localStorage.getItem('userName'),
    date: dateStamp.toISO(),
  };
  const url = '/db/updateStatus.schedule.db.php';
  const response = await fetch(url, {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify(dataUpdate),
  });
  if (!response.ok) {
    throw Error(response.statusText);
  } else {
    return response.text();
  }
};

export default function StatusChange({
  authorizedUsers,
  missionID,
  notes,
  statusType,
}) {
  const queryClient = useQueryClient();
  const qC = queryClient.getQueryData(['missionDataPartial']);
  let missionObj = React.useMemo(() => [], []);

  if (qC) {
    missionObj = qC.find((missions) => missions.missionID === missionID);
  }

  let statusTypeFull =
    statusType === '' ||
    statusType === null ||
    statusType === 'Contactsstatus' ||
    statusType === undefined
      ? 'Contactsstatus'
      : statusType + 'status';

  const [authUserNoStatus] = useState(() => {
    const userType = localStorage.getItem('typeUser');
    const auth = authorizedUsers.nostatus.includes(userType);
    return auth;
  });
  const [authUserReview] = useState(() => {
    const userType = localStorage.getItem('typeUser');
    const auth = authorizedUsers.review.includes(userType);
    return auth;
  });
  const [authUserApproved] = useState(() => {
    const userType = localStorage.getItem('typeUser');
    const auth = authorizedUsers.approve.includes(userType);
    return auth;
  });
  const [name, setName] = useState();
  const [dateLast, setDateLast] = useState();
  const [status, setStatus] = useState();
  const [loading] = useState(false);

  //Set status after Context loads
  useEffect(() => {
    if (
      missionObj[statusTypeFull] === null ||
      missionObj[statusTypeFull] === undefined
    ) {
      setName('No status');
      setDateLast('');
      setStatus('noneC');
    } else {
      setName(missionObj[statusTypeFull].split(';')[1]);
      setDateLast(missionObj[statusTypeFull].split(';')[2]);
      switch (missionObj[statusTypeFull].split(';')[0]) {
        case '0':
          setStatus('noneC');
          break;
        case '1':
          setStatus('review');
          break;
        case '2':
          setStatus('complete');
          break;
        default:
          break;
      }
    }
  }, [missionObj, statusTypeFull]);

  const changeStatus = useMutation(postChange, {
    onMutate: ({ newStatusNumber, statusTypeFull, missionObj }) => {
      queryClient.cancelQueries(['missionDataPartial']);
      const previousMissions = queryClient.getQueryData(['missionDataPartial']);

      setName(localStorage.getItem('userName'));
      setDateLast(DateTime.utc().toISO());

      const updatedTodosArray = produce(previousMissions, (draft) => {
        const index = draft.findIndex(
          (mission) => mission.missionID === missionObj.missionID
        );
        if (index !== -1)
          draft[index][
            statusTypeFull
          ] = `${newStatusNumber};${localStorage.getItem(
            'userName'
          )};${DateTime.utc().toISO()}`;
      });
      queryClient.setQueryData(['missionDataPartial'], updatedTodosArray);

      return () =>
        queryClient.setQueryData('missionDataPartial', previousMissions);
    },
    onError: (err, newTodo, rollback) => {
      rollback();
      alert(
        'There was an error updating status.  Status reverts to previously selected.  Contact Administrator for help.'
      );
    },
    onSettled: async () => {
      await queryClient.refetchQueries(['missionDataPartial']);
    },
  });

  const headerStyle = {
    textAlign: 'center',
    margin: '3px',
    backgroundColor: status === 'complete' ? 'green' : '',
  };

  return (
    <div>
      <h3 style={headerStyle}>{statusType}</h3>
      <FormControl component='fieldset' style={{ padding: '10px' }}>
        <FormLabel
          component='legend'
          style={{ padding: '10px', textAlign: 'center' }}>
          {notes}
        </FormLabel>
        <FormGroup>
          <FormControlLabel
            style={{ color: 'red' }}
            control={
              <Checkbox
                checked={status === 'noneC' ? true : false}
                onClick={() => {
                  if (authUserNoStatus === true) {
                    if (status !== 'noneC') {
                      setStatus('noneC');
                      const newStatusNumber = 0;
                      changeStatus.mutate({
                        newStatusNumber,
                        statusTypeFull,
                        missionObj,
                      });
                    } else {
                      setStatus('none');
                    }
                  } else {
                  }
                }}
                color='primary'
              />
            }
            label='No status'
          />
          <FormControlLabel
            style={{ color: 'darkorange' }}
            control={
              <Checkbox
                checked={status === 'review' ? true : false}
                onClick={() => {
                  if (authUserReview === true) {
                    if (status !== 'review') {
                      setStatus('review');
                      const newStatusNumber = 1;
                      changeStatus.mutate({
                        newStatusNumber,
                        statusTypeFull,
                        missionObj,
                      });
                    } else {
                      setStatus('none');
                      const newStatusNumber = 1;
                      changeStatus.mutate({
                        newStatusNumber,
                        statusTypeFull,
                        missionObj,
                      });
                    }
                  }
                }}
                color='primary'
              />
            }
            label='Reviewed'
          />
          <FormControlLabel
            style={{ color: 'green' }}
            control={
              <Checkbox
                checked={status === 'complete' ? true : false}
                onClick={() => {
                  if (authUserApproved === true) {
                    if (status !== 'complete') {
                      setStatus('complete');
                      const newStatusNumber = 2;
                      changeStatus.mutate({
                        newStatusNumber,
                        statusTypeFull,
                        missionObj,
                      });
                    } else {
                      setStatus('none');
                      const newStatusNumber = 0;
                      changeStatus.mutate({
                        newStatusNumber,
                        statusTypeFull,
                        missionObj,
                      });
                    }
                  } else {
                  }
                }}
                color='primary'
              />
            }
            label='Approved'
          />
        </FormGroup>
        {loading ? (
          <>
            <p style={{ color: 'black' }}>Loading....</p>
            <p style={{ color: 'black' }}>Be patient...</p>
          </>
        ) : (
          <>
            <p style={{ color: 'black' }}>Updated by: {name}</p>
            <p style={{ color: 'black' }}>
              On date: {DateTime.fromISO(dateLast).toFormat('LL-dd-yyyy HHmm')}
            </p>
          </>
        )}
      </FormControl>
    </div>
  );
}
