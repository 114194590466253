import Chip from '@material-ui/core/Chip';
import React from 'react';
import '../../App.css';
import RouteTooltip from '../../Utils/RouteTooltip';
const { DateTime } = require('luxon');

export const RouteChipsImport = ({
  route,
  setpossibleRoutes,
  possibleRoutes,
  missionDataToImport,
  setmissionDataToImport,
  leg,
}) => {
  return (
    <RouteTooltip route={route}>
      <Chip
        className='mt-2'
        key={route.id}
        style={{
          color: 'white',
          fontSize: '1rem',
          margin: '1px',
        }}
        color={
          DateTime.fromSQL(route?.valdate) >
          DateTime.local().minus({ days: 31 })
            ? 'primary'
            : 'secondary'
        }
        value={route.id}
        onClick={(e) => {
          e.preventDefault();
          let copy = [...possibleRoutes];
          copy.forEach((elem) => (elem.selected = false));
          const index = copy.findIndex((elem) => elem.id === route.id);
          if (index !== -1) copy[index].selected = true;
          setpossibleRoutes(copy);

          let copy1 = { ...missionDataToImport };
          copy1.legs[leg.legNumber].selected = route.id;
          setmissionDataToImport(copy1);
        }}
        data={route}
        label={route.id + '/' + route.pod + '-' + route.poa}
      />
    </RouteTooltip>
  );
};
