import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import '../App.css';
import MyFallbackComponent from '../Errors/MyFallbackComponent';
import StandardLoadingFallback from '../Loading/StandardLoadingFallback';
import { FilterSetting, PPRContext } from '../store';
import useMissionDataPartial from '../Utils/Hooks/useMissionDataPartial';
import usePPRData from '../Utils/Hooks/usePPRdata';
import { checkUser } from '../Utils/pemissions';
import { DeleteButton } from './DeleteButton';
import ReleaseMission from './ReleaseMission';
import { DaysTill, MissionLink, PrintDate, StatusButton } from './utils';

const { DateTime } = require('luxon');

const getMissionDetail = async (routeIDs, ACtype) => {
  const data = await fetch('/db/chunkMDA.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify({ routeIDs: routeIDs, type: ACtype }),
  });

  return data.json();
};

export default function Schedule(props) {
  const [dataFilter, setdataFilter] = React.useState('current');
  const [textFilter, setTextFilter] = useState('');
  const [filterSetting, setFilterSetting] = useContext(FilterSetting);
  const [filteredData, setfilteredData] = useState([]);
  const [Loading, setLoading] = useState(false);
  //const { status: airliftStatus, data: airliftData } = useAirliftData();
  const [, setpprInformation] = useContext(PPRContext);
  const { status: PPRStatus, data: PPRData } = usePPRData();
  const {
    data: missionData,
    isFetching: missionIsFetching,
    loading,
  } = useMissionDataPartial();
  //const { data: missionDataFull } = useMissionData();
  //const { data: missionDataOldMods } = useMissionDataOldMods();

  useEffect(() => {
    if (PPRStatus === 'success' && PPRData) {
      setpprInformation(PPRData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PPRData, PPRStatus]);

  /*   useEffect(() => {
    if (showFull === true && !missionIsFetchingFull && missionDataFull) {
      if (filterSetting !== 'all') {
        let dataFil = missionDataFull.filter((input) => {
          return input.typeAircraft === filterSetting;
        });
        setfilteredData(dataFil);
      } else {
        setfilteredData(missionDataFull);
      }
    }
    if (showFull === false && !missionIsFetching && missionData) {
      let dataFil = missionData.filter((input) => {
        if (filterSetting === 'all') {
          return (
            DateTime.fromISO(input.landTimes.trim().split(' ').reverse()[0]) >=
            DateTime.local().plus({ days: -1 })
          );
        } else {
          return (
            input.typeAircraft === filterSetting &&
            DateTime.fromISO(input.landTimes.trim().split(' ').reverse()[0]) >=
              DateTime.local().plus({ days: -1 })
          );
        }
      });
      setfilteredData(dataFil);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionData, filterSetting, missionStatus, showFull, missionIsFetching]);  */

  async function pullOldMods() {
    const data = await fetch('/db/loadSquadronMissionsOldMods.db.php', {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
      },
    });
    setLoading(false);
    return data.json();
  }

  async function pullOldMissions() {
    const data = await fetch('/db/loadSquadronMissions.db.php', {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
      },
    });
    setLoading(false);
    return data.json();
  }

  useEffect(() => {
    // if (missionData && missionDataFull && missionDataOldMods) {
    if (missionData) {
      let missionDataTextFilter = [];
      if (textFilter !== '') {
        missionDataTextFilter = missionData.filter((elem) =>
          elem.cnv.split('-')[0].includes(textFilter)
        );
      } else {
        missionDataTextFilter = missionData;
      }

      switch (dataFilter) {
        case 'current':
          let dataFil = missionDataTextFilter.filter((input) => {
            if (filterSetting === 'all') {
              return (
                DateTime.fromISO(
                  input.landTimes.trim().split(' ').reverse()[0]
                ) >= DateTime.local().plus({ days: -1 })
              );
            } else {
              return (
                input.typeAircraft === filterSetting &&
                DateTime.fromISO(
                  input.landTimes.trim().split(' ').reverse()[0]
                ) >= DateTime.local().plus({ days: -1 })
              );
            }
          });
          setfilteredData(dataFil);
          break;

        case 'archive':
          setLoading(true);
          setfilteredData([]);
          const archiveMissions = pullOldMissions();
          setfilteredData(archiveMissions);
          /*           let dataFilFull = missionDataFull.filter((input) => {
            if (filterSetting === 'all') {
              return input;
            } else {
              return input.typeAircraft === filterSetting;
            }
          });
          setfilteredData(dataFilFull); */
          break;

        case 'oldMods':
          setLoading(true);
          setfilteredData([]);
          const oldMods = pullOldMods();
          setfilteredData(oldMods);

          /*           let dataFillArchive = missionDataOldMods.filter((input) => {
            if (filterSetting === 'all') {
              return (
                DateTime.fromISO(
                  input.landTimes.trim().split(' ').reverse()[0]
                ) >= DateTime.local().plus({ days: -30 })
              );
            } else {
              return (
                input.typeAircraft === filterSetting &&
                DateTime.fromISO(
                  input.landTimes.trim().split(' ').reverse()[0]
                ) >= DateTime.local().plus({ days: -30 })
              );
            }
          });
          setfilteredData(dataFillArchive); */
          break;

        default:
          let dataFilDefault = missionData.filter((input) => {
            if (filterSetting === 'all') {
              return (
                DateTime.fromISO(
                  input.landTimes.trim().split(' ').reverse()[0]
                ) >= DateTime.local().plus({ days: -1 })
              );
            } else {
              return (
                input.typeAircraft === filterSetting &&
                DateTime.fromISO(
                  input.landTimes.trim().split(' ').reverse()[0]
                ) >= DateTime.local().plus({ days: -1 })
              );
            }
          });
          setfilteredData(dataFilDefault);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataFilter,
    filterSetting,
    missionData,
    //missionDataFull,
    //missionDataOldMods,
    textFilter,
  ]);

  useEffect(() => {
    if (missionData) {
      let missionDataTextFilter = [];
      if (textFilter !== '') {
        missionDataTextFilter = missionData.filter((elem) =>
          elem.cnv.split('-')[0].includes(textFilter)
        );
      } else {
        missionDataTextFilter = missionData;
      }

      switch (dataFilter) {
        case 'current':
          let dataFil = missionDataTextFilter.filter((input) => {
            if (filterSetting === 'all') {
              return (
                DateTime.fromISO(
                  input.landTimes.trim().split(' ').reverse()[0]
                ) >= DateTime.local().plus({ days: -1 })
              );
            } else {
              return (
                input.typeAircraft === filterSetting &&
                DateTime.fromISO(
                  input.landTimes.trim().split(' ').reverse()[0]
                ) >= DateTime.local().plus({ days: -1 })
              );
            }
          });
          setfilteredData(dataFil);
          break;

        default:
          let dataFilDefault = missionData.filter((input) => {
            if (filterSetting === 'all') {
              return (
                DateTime.fromISO(
                  input.landTimes.trim().split(' ').reverse()[0]
                ) >= DateTime.local().plus({ days: -1 })
              );
            } else {
              return (
                input.typeAircraft === filterSetting &&
                DateTime.fromISO(
                  input.landTimes.trim().split(' ').reverse()[0]
                ) >= DateTime.local().plus({ days: -1 })
              );
            }
          });
          setfilteredData(dataFilDefault);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter, filterSetting, missionData, textFilter]);

  return (
    <>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <ScheduleHeader
          dataFilter={dataFilter}
          filterSetting={filterSetting}
          missionIsFetching={missionIsFetching}
          setdataFilter={setdataFilter}
          setFilterSetting={setFilterSetting}
          textFilter={textFilter}
          setTextFilter={setTextFilter}
        />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <ScheduleTable
          filteredData={filteredData}
          loading={loading || Loading}
          dataFilter={dataFilter}
        />
      </ErrorBoundary>
    </>
  );
}

function ScheduleHeader({
  dataFilter,
  filterSetting,
  missionIsFetching,
  setdataFilter,
  setFilterSetting,
  textFilter,
  setTextFilter,
}) {
  const queryClient = useQueryClient();
  const cssTest = {
    margin: '10px',
    minWidth: '120px',
    paddingLeft: '35px',
  };

  const formCtrl = {
    paddingLeft: '35px',
    marginTop: '10px',
  };

  return (
    <>
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        spacing={3}>
        <Grid item>
          <FormControl variant='outlined' style={formCtrl}>
            <InputLabel shrink htmlFor='actypeTest' style={cssTest}>
              Filter
            </InputLabel>
            <Select
              value={filterSetting}
              onChange={(e) => {
                e.preventDefault();
                setFilterSetting(e.target.value);
              }}
              name='actypeTest'
              id='actypeTest'
              input={<OutlinedInput name='actypeTest' id='actypeTest' />}>
              <MenuItem value='all'>
                <em>All</em>
              </MenuItem>
              <MenuItem value='c130routes'>C-130</MenuItem>
              <MenuItem value='c40routes'>C-40</MenuItem>
              <MenuItem value='c26routes'>C-26</MenuItem>
            </Select>
            <FormHelperText>Choose an aircraft</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            onClick={async () => {
              queryClient.invalidateQueries(['missionDataPartial']);
              queryClient.invalidateQueries(['missionDataOldMods']);
            }}>
            Refresh Schedule Data
          </Button>
        </Grid>
        <Grid item>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Schedule displayed</FormLabel>
            <RadioGroup
              aria-label='ScheduleData'
              name='scheduleData'
              row
              value={dataFilter}
              onChange={(e) => {
                setdataFilter(e.target.value);
              }}>
              <FormControlLabel
                value='current'
                control={<Radio />}
                label='Current'
              />
              <FormControlLabel
                value='archive'
                control={<Radio />}
                label='Archive'
              />
              <FormControlLabel
                value='oldMods'
                control={<Radio />}
                label='Old Mods'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {dataFilter === 'current' ? (
          <Grid item>
            <TextField
              id='outlined-basic'
              label='Filter'
              variant='outlined'
              value={textFilter}
              onChange={(e) => {
                e.preventDefault();
                setTextFilter(e.target.value);
              }}
            />
          </Grid>
        ) : null}
        <Grid item>
          {missionIsFetching ? <span>Loading data....</span> : null}
        </Grid>
      </Grid>
    </>
  );
}

function ScheduleTable({ filteredData, loading, dataFilter }) {
  const queryClient = useQueryClient();
  const bodyStyle = {
    borderBottom: '',
  };
  const paperStyle = {
    width: '100%',
    marginTop: '3px',
    overflowX: 'auto',
  };

  const tableStyle = {
    margin: '10px',
    minWidth: 650,
    color: 'lightyellow',
  };

  if (!filteredData || filteredData === []) {
    return (
      <h2 style={{ textAlign: 'center', color: 'red' }}>
        No Schedule Data - If you suspect this is an error contact Administrator
      </h2>
    );
  }

  if (loading === true) {
    return <StandardLoadingFallback />;
  }

  return (
    <Paper style={paperStyle}>
      <Table style={tableStyle}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Callsign</TableCell>
            <TableCell align='center'>Start Date/Time</TableCell>
            <TableCell align='center' style={{ width: '20vw' }}>
              Itinerary
            </TableCell>
            <TableCell align='center'>Days till start</TableCell>
            <TableCell align='center'>BUNO</TableCell>
            {dataFilter === 'current' ? (
              <TableCell align='center'>Viewable by squadron?</TableCell>
            ) : null}
            <TableCell align='center'>APACS</TableCell>
            <TableCell align='center'>Contacts Confirmed</TableCell>
            <TableCell align='center'>PPR</TableCell>
            <TableCell align='center'>Final Review</TableCell>
            {checkUser(
              localStorage.getItem('typeUser'),
              'schedule_DeleteButton'
            ) ? (
              <TableCell align='center'>Delete</TableCell>
            ) : null}
            {checkUser(
              localStorage.getItem('typeUser'),
              'schedule_EnteredBy'
            ) ? (
              <TableCell align='center'>Updated By</TableCell>
            ) : null}

            {/*         {localStorage.getItem('typeUser') === 'admin' ||
            localStorage.getItem('typeUser') === 'dh' ? (
              <TableCell align='center'>
                Delete, {localStorage.getItem('typeUser')}
              </TableCell>
            ) : null} */}
            {/*        {localStorage.getItem('typeUser') === 'admin' ? (
              <TableCell align='center'>Updated By</TableCell>
            ) : null} */}
          </TableRow>
        </TableHead>
        <TableBody style={bodyStyle}>
          {filteredData.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow key={index}>
                  <TableCell
                    align='center'
                    onMouseEnter={async () => {
                      await queryClient.prefetchQuery(
                        ['missionDetail', data.routeID, data.typeAircraft],
                        () => getMissionDetail(data.routeID, data.typeAircraft),
                        {
                          staleTime: 5 * 60 * 1000,
                        }
                      );
                    }}>
                    <Link
                      to={{
                        pathname: `/mission/${data.missionID}`,
                        state: {
                          missionDataLink: data,
                          // modificationTracker: modificationTracker,
                        },
                      }}>
                      <MissionLink key={data.missionID + 'm'} data={data} />
                    </Link>
                  </TableCell>
                  <TableCell align='center'>
                    <PrintDate data={data} />
                  </TableCell>
                  <TableCell align='center'>
                    {data.fullMissionDataObject.map((leg, index) => {
                      if (index === 0) {
                        if (leg.RON === true) {
                          return leg.pod + ' - ' + leg.poa + '/RON';
                        } else {
                          return leg.pod + ' - ' + leg.poa;
                        }
                      } else {
                        if (leg.RON === true) {
                          return ' - ' + leg.poa + '/RON';
                        } else {
                          return ' - ' + leg.poa;
                        }
                      }
                    })}
                  </TableCell>
                  <TableCell align='center'>
                    <DaysTill data={data} />
                  </TableCell>
                  <TableCell align='center'>
                    {data.buno !== null ? `${data.buno}` : `None`}
                  </TableCell>
                  {dataFilter === 'current' ? (
                    <TableCell align='center'>
                      <ReleaseMission
                        released={data.released}
                        missionID={data.missionID}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell align='center'>
                    <StatusButton
                      key={data.missionID + 'd'}
                      data={data}
                      statusType='APACS'
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <StatusButton
                      key={data.missionID + 'c'}
                      data={data}
                      statusType='Contacts'
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <StatusButton
                      key={data.missionID + 'p'}
                      data={data}
                      statusType='PPR'
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <StatusButton
                      key={data.missionID + 'f'}
                      data={data}
                      statusType='FinalReview'
                    />
                  </TableCell>
                  {/*    {localStorage.getItem('typeUser') === 'admin' ||
                  localStorage.getItem('typeUser') === 'dh' ||
                  localStorage.getItem('typeUser') === 'fw' ? (
                    <DeleteButton data={data} />
                  ) : null} */}

                  {checkUser(
                    localStorage.getItem('typeUser'),
                    'schedule_DeleteButton'
                  ) ? (
                    <DeleteButton data={data} />
                  ) : null}

                  {/*           {(localStorage.getItem('typeUser') === 'admin' &&
                    data.enteredBy != null) ||
                  (localStorage.getItem('typeUser') === 'dh' &&
                    data.enteredBy != null) ? (
                    <TableCell align='center'>
                      {data.enteredBy.split(';')[0]}
                      {', '}
                      {DateTime.fromISO(data.enteredBy.split(';')[1]).toHTTP()}
                    </TableCell>
                  ) : null} */}

                  {checkUser(
                    localStorage.getItem('typeUser'),
                    'schedule_EnteredBy'
                  ) && data.enteredBy != null ? (
                    <TableCell align='center'>
                      {data.enteredBy.split(';')[0]}
                      {', '}
                      {DateTime.fromISO(data.enteredBy.split(';')[1]).toHTTP()}
                    </TableCell>
                  ) : null}
                </TableRow>
                {checkUser(
                  localStorage.getItem('typeUser'),
                  'schedule_OfficeNotes'
                ) ? (
                  <TableRow
                    key={index + 'notes'}
                    style={{
                      borderBottom: 'solid',
                      borderBottomColor: 'black',
                    }}>
                    <TableCell colSpan={12}>
                      Office Notes: {data.officeNotes}
                    </TableCell>
                  </TableRow>
                ) : null}
                {/*  {localStorage.getItem('typeUser') === 'admin' ||
                localStorage.getItem('typeUser') === 'dh' ? (
                  <TableRow
                    key={index + 'notes'}
                    style={{
                      borderBottom: 'solid',
                      borderBottomColor: 'black',
                    }}>
                    <TableCell colSpan={12}>
                      Office Notes: {data.officeNotes}
                    </TableCell>
                  </TableRow>
                ) : null} */}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}
