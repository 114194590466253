import React from 'react';
import useFeedback from '../Utils/Hooks/useFeedback';
import TableWrapper from '../Utils/TableWrapper';

export default function FeedbackTable() {
  const { data, isFetching } = useFeedback();
  const databaseName = 'feedback';
  const queryKey = ['feedback'];
  const columns = [
    {
      Header: `Airports`,
      columns: [
        {
          Header: 'ID',
          accessor: 'id',
          edit: false,
          minWidth: '3vw',
          maxWidth: '5vw',
        },
        {
          Header: 'Date',
          accessor: 'dateOfSubmission',
          edit: false,
          minWidth: '5vw',
          maxWidth: '7vw',
        },
        {
          Header: 'Comments',
          accessor: 'body',
          style: { 'white-space': 'unset' },
          edit: false,
          minWidth: '40vw',
          maxWidth: '50vw',
          Cell: ({ cell: { value } }) => {
            console.log(value);
            return <>10 {value}</>;
          },
        },

        {
          Header: 'Aircraft',
          accessor: 'typeAircraft',
          edit: false,
          minWidth: '4vw',
          maxWidth: '7vw',
        },
        {
          Header: 'ICAO',
          accessor: 'ICAO',
          edit: false,
          minWidth: '4vw',
          maxWidth: '7vw',
        },
        {
          Header: 'Name',
          accessor: 'nameSubmitter',
          edit: false,
        },
        {
          Header: 'CS',
          accessor: 'missionCS',
          edit: false,
        },
      ],
    },
  ];

  if (isFetching === true) {
    return <h3>Loading...</h3>;
  }

  const rowsNotToEdit = ['ID'];

  return (
    <TableWrapper
      data={data}
      numberOfRowsToDisplay={20}
      columnsArray={columns}
      queryKey={queryKey}
      pagination={true}
      databaseName={databaseName}
      rowsNotToEdit={rowsNotToEdit}
    />
  );
}
