import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@material-ui/core/Chip';
import React, { useContext } from 'react';
import { DownloadFoldersFiles } from '../../../store';

export default function PPRFileChip({ props }) {
  const [dirInfo, setDirInfo] = useContext(DownloadFoldersFiles);

  function openFileLink(e, link) {
    window.open(link, '_blank noopener noreferrer');
  }

  async function deleteFile(folder, file) {
    let deleteData = {};
    deleteData.fileDelete = file;
    deleteData.folderDelete = folder;
    try {
      const res = await fetch('/db/deletePPRFile.db.php', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('token'),
        },
        body: JSON.stringify(deleteData),
      });
      if (res.status === 200) {
        const updatePPRFolders = async () => {
          try {
            const res = await fetch('/db/pullPPRFolders.efp.php', {
              method: 'POST',
              withCredentials: true,
              credentials: 'include',
              headers: {
                Accept: 'application/json',
                Authorization: localStorage.getItem('token'),
              },
            });
            const response = await res.json();
            setDirInfo(response);
          } catch (err) {
            console.log(err);
            if (err.response.status === 500) {
              console.log('There was a problem with the server');
            } else if (err.response.status === 401) {
              console.log('Access denied');
            } else {
              console.log(err.response.data.msg);
            }
          }
        };
        updatePPRFolders();
      }
    } catch (err) {
      console.log(err);
    }
  }
  // debugger;
  const output = dirInfo.find((dir_obj) => {
    return dir_obj.folder === props;
  });
  if (output !== undefined && output.files[0] !== undefined) {
    return output.files.map((file, ind) => {
      let address_string = `https://europegouge.com/db/uploads/${output.folder}/${file}`;
      return (
        <React.Fragment key={ind + 'frag'}>
          <Chip
            key={ind + 'fileChip'}
            className='mb-1'
            icon={<FontAwesomeIcon icon={faFilePdf} />}
            label={file}
            onClick={(e) => {
              openFileLink(e, address_string);
            }}
            onDelete={(e) => {
              deleteFile(output.folder, file);
            }}
          />
          <br />
        </React.Fragment>
      );
    });
  } else {
    return 'No files uploaded';
  }
}
