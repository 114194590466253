import Grid from '@material-ui/core/Grid';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import React, { useContext } from 'react';
import '../App.css';
import GoogleMapLink from '../GoogleMapLink';
import { MissionInWork } from '../MissionGeneratorStore';

const { DateTime, Duration } = require('luxon');

export const LegCol = ({ props }) => {
  const [, setmissionInWork] = useContext(MissionInWork);
  return (
    <>
      <Grid item>Leg {Number(props.rowNumber) + 1} </Grid>
      <Grid
        item
        style={{
          textDecoration:
            DateTime.fromSQL(props.info.valdate) <
            DateTime.local().minus({ days: 31 })
              ? 'line-through'
              : null,
          color:
            DateTime.fromSQL(props.info.valdate) <
            DateTime.local().minus({ days: 31 })
              ? 'red'
              : null,
        }}>
        {props.info.id}
      </Grid>
      <Grid item>
        <GoogleMapLink props={props.info} />
      </Grid>
      <Grid item container alignItems='center' justify='center'>
        <Grid item>
          <DeleteForeverOutlinedIcon
            fontSize='large'
            onClick={(e) => {
              e.preventDefault();
              setmissionInWork((prevState) => {
                const newState = [...prevState];

                newState.splice(props.rowNumber, 1);
                for (let i = 0; i < newState.length; i++) {
                  newState[i].toTimeZulu = DateTime.fromISO(
                    newState[i].toTime,
                    {
                      zone: newState[i].podTimeZone,
                    }
                  )
                    .toUTC()
                    .toISO({ includeOffset: false });

                  newState[i].landTimeZulu = DateTime.fromISO(
                    newState[i].toTimeZulu,
                    { zone: 'UTC' }
                  )
                    .plus(
                      Duration.fromObject({
                        minutes: newState[i].roundedEnrouteTime,
                      })
                    )
                    .toUTC()
                    .toISO({ includeOffset: false });

                  newState[i].landTime = DateTime.fromISO(
                    newState[i].landTimeZulu,
                    {
                      zone: 'UTC',
                    }
                  )
                    .setZone(newState[i].poaTimeZone)
                    .toISO({ includeOffset: false });

                  if (i !== newState.length - 1) {
                    newState[i + 1].toTime = DateTime.fromISO(
                      newState[i].landTime,
                      { zone: newState[i].poaTimeZone }
                    )
                      .plus(
                        Duration.fromObject({
                          minutes: newState[i].turnTime.minutes,
                        })
                      )
                      .setZone(newState[i + 1].podTimeZone)
                      .toISO({ includeOffset: false });
                  }
                }

                return [...newState];
              });
            }}
          />
        </Grid>
        <Grid item> Delete</Grid>
      </Grid>
    </>
  );
};
