import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './App.css';
import LogoutButton from './login/LogoutButton';
import RouteEntry from './RouteEntry';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: '10px',
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

function GoBack({ history }) {
  const buttonStyle = {
    margin: '5px',
  };
  return (
    <Button
      style={buttonStyle}
      variant='contained'
      size='small'
      onClick={() => history.goBack()}
      alt='Go back'>
      Go back
    </Button>
  );
}

function NavTop(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  let role = '';
  localStorage.getItem('typeUser') === 'c40' ||
  localStorage.getItem('typeUser') === 'c130' ||
  localStorage.getItem('typeUser') === 'c26' ||
  localStorage.getItem('typeUser') === 'guest'
    ? (role = 'det')
    : (role = 'office');

  const list = () => (
    <div
      role='presentation'
      onClick={() => {
        toggleDrawer();
      }}
      onKeyDown={() => {
        toggleDrawer();
      }}>
      {role === 'det' &&
      localStorage.getItem('userName') &&
      localStorage.getItem('exp') > Date.now() / 1000 ? (
        <DetDropdown />
      ) : null}
      {role === 'office' &&
      localStorage.getItem('userName') &&
      localStorage.getItem('exp') > Date.now() / 1000 ? (
        <>
          <List>
            <Link
              to={{
                pathname: `/`,
              }}>
              <ListItem button>
                <ListItemText primary='Home' />
              </ListItem>
            </Link>
            <Link
              to={{
                pathname: `/schedule`,
              }}>
              <ListItem button>
                <ListItemText primary='Schedule' />
              </ListItem>
            </Link>
            <Link
              to={{
                pathname: `/generator/`,
              }}>
              <ListItem button>
                <ListItemText primary='Mission Generator' />
              </ListItem>
            </Link>
            <Link
              to={{
                pathname: `/apacs/`,
              }}>
              <ListItem button>
                <ListItemText primary='APACS' />
              </ListItem>
            </Link>
            <Link
              to={{
                pathname: `/airliftentry`,
              }}>
              <ListItem button>
                <ListItemText primary='Airlift Entry' />
              </ListItem>
            </Link>
            <Link
              to={{
                pathname: `/gougeroutes`,
              }}>
              <ListItem button>
                <ListItemText primary='Route Table' />
              </ListItem>
            </Link>
            <Link
              to={{
                pathname: `/airportsTable/`,
              }}>
              <ListItem button>
                <ListItemText primary='Airports Table' />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            <Link
              to={{
                pathname: `/warnings`,
              }}>
              <ListItem button>
                <ListItemText primary='Warning Table' />
              </ListItem>
            </Link>
            {localStorage.getItem('typeUser') === 'admin' ||
            localStorage.getItem('typeUser') === 'dh' ? (
              <DHMenuList />
            ) : (
              ''
            )}
            {localStorage.getItem('typeUser') === 'admin' ? (
              <AdminMenuList />
            ) : (
              ''
            )}
            <LogoutButton />
          </List>
        </>
      ) : null}
    </div>
  );

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          position='sticky'
          className={classes.toolbar}
          style={{ backgroundColor: 'rgb(8, 52, 185)', zIndex: '100' }}>
          <Toolbar variant='dense' className={classes.toolbar}>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
              onClick={() => {
                toggleDrawer();
              }}>
              <div style={{ float: 'left', marginLeft: '15px' }}>
                <Tooltip title='V1.5'>
                  <div
                    style={{
                      float: 'left',
                      fontWeight: 'bold',
                      fontSize: 'larger',
                      letterSpacing: '1vh',
                    }}>
                    E-F-P.NET
                  </div>
                </Tooltip>
              </div>
            </IconButton>
            {localStorage.getItem('userName') &&
            localStorage.getItem('exp') > Date.now() / 1000 ? (
              <>
                <Typography variant='h6' style={{ marginRight: '2vh' }}>
                  Welcome: {localStorage.getItem('userName')}
                </Typography>
                <GoBack history={props.history} />
                {role === 'office' ? <RouteEntry /> : null}
                <LogoutButton />
                <Drawer
                  anchor='top'
                  open={open}
                  onClose={() => {
                    toggleDrawer();
                  }}>
                  {list()}
                </Drawer>
              </>
            ) : null}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
}

function DHMenuList() {
  return (
    <>
      <ListItem button>
        <Link
          to={{
            pathname: `/aircraftLineup/`,
          }}>
          Aircraft Lineup Table (DH/Admin)
        </Link>
      </ListItem>
      <ListItem button>
        <Link
          to={{
            pathname: `/airliftTable/`,
          }}>
          Airlift Table (DH/Admin)
        </Link>
      </ListItem>
      <ListItem button>
        <Link
          to={{
            pathname: `/UICtable/`,
          }}>
          UIC Table (DH/Admin)
        </Link>
      </ListItem>
      <ListItem button>
        <Link
          to={{
            pathname: `/gougeTable/`,
          }}>
          Trip Report Table (DH/Admin)
        </Link>
      </ListItem>
      <ListItem button>
        <Link
          to={{
            pathname: `/emails/`,
          }}>
          Emails (DH/Admin)
        </Link>
      </ListItem>
    </>
  );
}

function AdminMenuList() {
  return (
    <>
      <ListItem button>
        <Link
          to={{
            pathname: `/logTable/`,
          }}>
          Log Table (Admin)
        </Link>
      </ListItem>
    </>
  );
}

const DetDropdown = () => {
  return (
    <>
      <List>
        <Link
          to={{
            pathname: `/`,
          }}>
          <ListItem button>
            <ListItemText primary='Home' />
          </ListItem>
        </Link>
        <Link
          to={{
            pathname: `/detschedule`,
          }}>
          <ListItem button>
            <ListItemText primary='Schedule' />
          </ListItem>
        </Link>
        <Link
          to={{
            pathname: `/gougeroutes`,
          }}>
          <ListItem button>
            <ListItemText primary='Routes' />
          </ListItem>
        </Link>
        <Link
          to={{
            pathname: `/feedback`,
          }}>
          <ListItem button>
            <ListItemText primary='Gouge' />
          </ListItem>
        </Link>
        <Link
          to={{
            pathname: `/feedbackform`,
          }}>
          <ListItem button>
            <ListItemText primary='Submit Gouge' />
          </ListItem>
        </Link>
        <LogoutButton />
      </List>
    </>
  );
};

export default withRouter(NavTop);
