import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { default as React, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import useAicraftLineup from '../Utils/Hooks/useAircraftLineup';
import useMissionDataPartial from '../Utils/Hooks/useMissionDataPartial';
import { aircraftBunoToType } from './aircraftBunoType';
import { ImportfromLR } from './Import/ImportfromLR';
//import ImportPPRonModUpdate from './ImportPPRonModUpdate';
import ITSbutton from './ITS/ITSbutton';
import { MissionInWork } from './MissionGeneratorStore';
import SaveMissionButton from './SaveMissionButton';

//TODO (12/29)
//Remove context and update with react query hooks

export default function MissionHeader({
  setaircraftType,
  aircraftType,
  fromLR,
  setsmartTiming,
  smartTiming,
}) {
  const queryClient = useQueryClient();
  const [, setcurrentExistingMission] = useState('');
  const [bunos, setbunos] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [headerValues, setheaderValues] = useState({
    type: 'C-130',
    callsign: '',
    mod: 0,
    updateNumber: 0,
    cnv: '',
    buno: '',
    stage: 'ITS',
  });
  const {
    data: missionSet,
    status: missionSetStatus,
  } = useMissionDataPartial();
  const [, setmissionInWork] = useContext(MissionInWork);
  const {
    data: aircraftLineup,
    isFetching: aircraftLineupFetching,
  } = useAicraftLineup();
  const [, setOpen] = React.useState(false);

  useEffect(() => {
    if (!aircraftLineupFetching) {
      let bunos = aircraftLineup.filter(
        (aircraft) => aircraft.aircraftType === headerValues.type
      );
      setbunos(bunos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aircraftLineupFetching, headerValues.type]);

  useEffect(() => {
    if (fromLR !== null && fromLR !== undefined) {
      let matchMission = missionSet.find(
        (mission) => mission.missionID === fromLR.missionID
      );
      if (matchMission !== undefined && matchMission !== null) {
        if (
          matchMission.modification === null ||
          matchMission.modification === undefined ||
          matchMission.modification === ''
        ) {
          //Not in mod
          setcurrentExistingMission(matchMission);
          let bunoDecode = aircraftBunoToType.find(
            (elem) => elem.buno === Number(matchMission.buno)
          );
          setheaderValues({
            mod: null,
            updateNumber: null,
            callsign: matchMission.cnv?.split('-')[0],
            cnv: matchMission.cnv,
            buno: bunoDecode?.buno,
            type: bunoDecode?.type,
          });
          setmissionInWork(matchMission.fullMissionDataObject);
        } else {
          //in mod
          setcurrentExistingMission(matchMission);
          let bunoDecode = aircraftBunoToType.find(
            (elem) => elem.buno === Number(matchMission.modification.buno)
          );
          setheaderValues({
            mod: null,
            updateNumber: null,
            callsign: matchMission.cnv?.split('-')[0],
            cnv: matchMission.cnv,
            buno: bunoDecode?.buno,
            type: bunoDecode?.type,
          });
          setmissionInWork(matchMission.fullMissionDataObject);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromLR]);

  if (hidden === true) {
    return (
      <Grid
        container
        direction='row'
        justify='flex-start'
        alignItems='center'
        spacing={1}
        style={{
          backgroundColor: 'lightblue',
          margin: '0px',
          width: 'auto',
        }}>
        <Grid item xs={1}>
          <KeyboardArrowDownIcon
            style={{ backgroundColor: 'white', borderRadius: '.6vw' }}
            onClick={(e) => {
              e.preventDefault();
              setHidden(false);
            }}
          />
        </Grid>
        <Grid item xs={11}></Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'
      spacing={3}
      style={{ backgroundColor: 'lightblue', margin: '0px', width: 'auto' }}>
      <Grid item>
        <KeyboardArrowUpIcon
          style={{ backgroundColor: 'white', borderRadius: '.6vw' }}
          onClick={(e) => {
            e.preventDefault();
            setHidden(true);
          }}
        />
      </Grid>
      <Grid item>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Type</FormLabel>
          <RadioGroup
            aria-label='gender'
            name='gender1'
            value={headerValues.stage === null ? 'ITS' : headerValues.stage}
            onChange={(e) => {
              e.preventDefault();
              setheaderValues({ ...headerValues, stage: e.target.value });
            }}>
            <FormControlLabel value='ITS' control={<Radio />} label='ITS' />
            <FormControlLabel value='JALIS' control={<Radio />} label='JALIS' />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={smartTiming}
              onChange={(e) => {
                e.preventDefault();
                setsmartTiming(!smartTiming);
              }}
              name='smartTiming'
              color='primary'
            />
          }
          label='Smart Timing'
        />
      </Grid>
      <Grid item>
        <InputLabel id='select-actype'>AC Type</InputLabel>
        <Select
          labelId='select-actype'
          id='aircraftType'
          value={headerValues.type}
          onChange={(e) => {
            e.preventDefault();
            setmissionInWork([]);
            setheaderValues({ ...headerValues, type: e.target.value });
            let aircraft_verbose = '';
            switch (e.target.value) {
              case 'C-40':
                aircraft_verbose = 'c40routes';
                break;
              case 'C-130':
                aircraft_verbose = 'c130routes';
                break;
              case 'C-26':
                aircraft_verbose = 'c26routes';
                break;
              default:
                aircraft_verbose = 'c130routes';
                break;
            }
            setaircraftType(aircraft_verbose);
          }}>
          <MenuItem value={'C-40'}>C-40</MenuItem>
          <MenuItem value={'C-130'}>C-130</MenuItem>
          <MenuItem value={'C-26'}>C-26</MenuItem>
        </Select>
      </Grid>
      <Grid item>
        <InputLabel id='select-buno'>BUNO</InputLabel>
        <Select
          labelId='select-buno'
          id='aircraftType'
          value={headerValues.buno}
          onChange={(e) => {
            setheaderValues({ ...headerValues, buno: e.target.value });
          }}>
          {bunos.map((side) => {
            return (
              <MenuItem value={side.buno} key={'side' + side.buno}>
                {side.buno}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item>
        <TextField
          id='outlined-basic'
          label='Callsign'
          variant='outlined'
          value={headerValues.callsign}
          onChange={(e) => {
            e.preventDefault();
            setheaderValues({ ...headerValues, callsign: e.target.value });
          }}
        />
      </Grid>
      <Grid item>
        {headerValues.stage === 'JALIS' ? (
          <>
            <InputLabel id='mod'>Modification</InputLabel>
            <Select
              labelId='mod'
              id='mod'
              value={headerValues.mod}
              onChange={(e) => {
                setheaderValues({ ...headerValues, mod: e.target.value });
              }}>
              <MenuItem value={''}></MenuItem>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7 - max</MenuItem>
            </Select>
          </>
        ) : (
          <>
            <InputLabel id='mod'>Update (ITS)</InputLabel>
            <Select
              labelId='update'
              id='update'
              value={headerValues.updateNumber}
              onChange={(e) => {
                setheaderValues({
                  ...headerValues,
                  updateNumber: e.target.value,
                });
              }}>
              <MenuItem value={''}></MenuItem>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7 - max</MenuItem>
            </Select>
          </>
        )}
      </Grid>
      {missionSetStatus === 'loading' ? (
        <span>Loading missions</span>
      ) : missionSetStatus === 'error' ? (
        <span>Error loading missions</span>
      ) : (
        <Grid item>
          <Autocomplete
            id='Import-mission'
            options={missionSet}
            getOptionLabel={(option) =>
              `${option.cnv}-${option.updateNumber}` || ''
            }
            getOptionSelected={(option, value) => option.cnv === value.cnv}
            style={{ width: 175 }}
            //value={currentExistingMission}
            freeSolo={false}
            onChange={(e, f) => {
              if (f?.fullMissionDataObject === false) {
                alert(
                  'Trying to import a route created on the old system.  Will not work.  Contact Administrator.'
                );
                setcurrentExistingMission({});
                setheaderValues({
                  ...headerValues,
                  mod: 0,
                  updateNumber: 0,
                  callsign: f.cnv?.split('-')[0],
                  cnv: '-',
                });
              } else if (f !== null) {
                setaircraftType(f.typeAircraft);
                setcurrentExistingMission(f);
                setheaderValues({
                  callsign: f.cnv?.split('-')[0],
                  cnv: f.cnv,
                  buno: f.buno,
                  mod: f.modNumber,
                  updateNumber: f.updateNumber === null ? 0 : f.updateNumber,
                  modOld: f.modNumber,
                  updateNumberOld: f.updateNumber === null ? 0 : f.updateNumber,
                  eventID: f.eventID,
                  history: f?.history,
                  stage: f.status === null ? 'ITS' : f.status,
                  type:
                    f.typeAircraft === 'c40routes'
                      ? 'C-40'
                      : f.typeAircraft === 'c130routes'
                      ? 'C-130'
                      : 'C-26',
                });
                setmissionInWork(f.fullMissionDataObject);
              } else {
                setcurrentExistingMission({});
                setheaderValues({
                  ...headerValues,
                  mod: 0,
                  updateNumber: 0,
                  callsign: '',
                  history: '',
                  buno: '',
                  stage: 'ITS',
                  cnv: '-',
                });
                setmissionInWork([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Select a mission'
                variant='outlined'
                helperText='CNV-month-mod-update'
              />
            )}
          />
        </Grid>
      )}
      <Grid item>
        <ITSbutton headerValues={headerValues} />
      </Grid>
      <Grid item>
        <SaveMissionButton
          fromLR={fromLR}
          headerValues={headerValues}
          setcurrentExistingMission={setcurrentExistingMission}
          setheaderValues={setheaderValues}
          setOpen={setOpen}
        />
      </Grid>
      <Grid item>
        <Button
          color='primary'
          variant='contained'
          size='small'
          onClick={(e) => {
            e.preventDefault();
            //refreshAirlifts();
            queryClient.invalidateQueries(['airliftSet']);
            queryClient.invalidateQueries(['routes', aircraftType]);
            queryClient.invalidateQueries(['missionDataPartial']);
          }}>
          Refresh Routes/Lifts
        </Button>
      </Grid>
      <Grid item>
        <Button
          color='primary'
          variant='contained'
          size='small'
          onClick={(e) => {
            e.preventDefault();
            setmissionInWork([]);
            setheaderValues({
              ...headerValues,
              mod: '0',
              callsign: '',
              cnv: '',
              buno: '',
            });
          }}>
          Clear
        </Button>
      </Grid>
      <Grid>
        <ImportfromLR
          setheaderValues={setheaderValues}
          headerValues={headerValues}
        />
      </Grid>
    </Grid>
  );
}
