import { Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { aircraftBunoToType } from './aircraftBunoType';

const { DateTime } = require('luxon');

export default function ImportFromLRcollapse({ props, showCollapse }) {
  //props can be mission
  //Can enter from mission mod or schedule

  let ACtype = '';
  switch (props?.typeAircraft) {
    case 'c130routes':
      ACtype = 'C-130';
      break;
    case 'c40routes':
      ACtype = 'C-40';
      break;
    case 'c26routes':
      ACtype = 'C-26';
      break;
    default:
      break;
  }

  if (props.itinerary) {
    return (
      <Collapse in={showCollapse}>
        <Paper
          elevation={4}
          style={{
            outline: 'auto',
            width: '100vw',
            backgroundColor: 'cornflowerblue',
            textAlign: 'center',
          }}>
          {props ? `Type AC: ${ACtype}` : null}{' '}
          {props ? `Buno: ${props.buno}` : null}{' '}
          {props ? `Start Date: ${props.startDate}` : null}{' '}
          {props ? `End Date: ${props.endDate}` : null}
          <ol style={{ listStylePosition: 'inside' }}>
            {props.itinerary.map((leg, index) => {
              return (
                <li key={index + 'li'}>
                  {leg.pod}-{leg.poa} {leg.RON === true ? `RON: Yes, ` : null}
                  {leg.CC === true ? `CC: Yes, ` : null}
                  {leg.fuel === true ? `Fuel: Yes, ` : null}
                  {leg.airlifts.length !== 0 ? `Airlifts: ` : ``}
                  {leg.airlifts.map((lift, ind) => {
                    return `${lift.airliftName}  ${
                      ind !== leg.airlifts.length - 1 ? ' // ' : ''
                    } `;
                  })}
                </li>
              );
            })}
          </ol>
          {props ? `Event Notes: ${props.eventNotes}` : null}{' '}
        </Paper>
      </Collapse>
    );
  }

  if (props.modification) {
    return (
      <Collapse in={showCollapse}>
        <Paper
          elevation={4}
          style={{
            outline: 'auto',
            width: '100vw',
            backgroundColor: 'cornflowerblue',
            textAlign: 'center',
          }}>
          <Typography variant='h6'>
            {props ? `CNV: ${props?.cnv.split('-')[0]}` : ''}, Current Mod:{' '}
            {props?.modNumber},
            {props
              ? ` Type AC: ${
                  aircraftBunoToType.find(
                    (elem) => elem.buno === Number(props.modification.buno)
                  )?.type
                }`
              : null}{' '}
            {props ? `Buno: ${props.modification.buno}` : null}{' '}
            {props ? `Start Date: ${props.modification.start}` : null}{' '}
            {props ? `End Date: ${props.modification.end}` : null}
          </Typography>
          <Typography variant='subtitle1'>
            <ol style={{ listStylePosition: 'inside' }}>
              {props.modification.fullMissionDataObject
                ? props.modification.fullMissionDataObject.map((elem, ind) => {
                    return (
                      <li key={ind + 'li'}>
                        {elem.pod}-{elem.poa}
                        {elem?.RON === true ? ` // RON: Yes ` : null}
                        {elem?.CC === true ? ` // CC: Yes ` : null}
                        {elem?.fuel === true ? ` // Fuel: Yes ` : null}
                        {elem.airlifts && elem.airlifts.length !== 0
                          ? ` // Airlifts: `
                          : ``}
                        {elem.airlifts
                          ? elem.airlifts.map((lift, ind) => {
                              return `${lift.airliftName}  ${
                                ind !== elem.airlifts.length - 1 ? ' // ' : ''
                              } `;
                            })
                          : null}
                      </li>
                    );
                  })
                : null}
            </ol>
          </Typography>
          <Typography variant='h6'>
            {props
              ? `Mission Notes: ${
                  props.missionNotes === null ? 'None' : props.missionNotes
                }`
              : null}{' '}
            <br />
            {props ? `Mod Notes: ${props.modification.modNotes}` : null}{' '}
          </Typography>
        </Paper>
      </Collapse>
    );
  }

  if (props.fullMissionDataObject) {
    console.log('import from collapse, full mission object entered');
    return (
      <Collapse in={showCollapse}>
        <Paper
          elevation={4}
          style={{
            outline: 'auto',
            width: '100vw',
            backgroundColor: 'cornflowerblue',
            textAlign: 'center',
          }}>
          <Typography variant='h6'>
            {props ? `CNV: ${props?.cnv} ` : null}
            {props ? `Type AC: ${ACtype}` : null}{' '}
            {props ? `Buno: ${props.buno}` : null}{' '}
            {props
              ? `Start Date: ${DateTime.fromISO(
                  props.firstTOlocal
                ).toISODate()}`
              : null}{' '}
            {props
              ? `End Date: ${DateTime.fromISO(
                  props.landTimes.split(' ').reverse()[0]
                ).toISODate()}`
              : null}
          </Typography>
          <Typography variant='subtitle1'>
            <ol style={{ listStylePosition: 'inside' }}>
              {props.fullMissionDataObject.map((leg, index) => {
                return (
                  <li key={index + 'li'}>
                    {leg.pod}-{leg.poa} {leg.RON === true ? `RON: Yes, ` : null}
                    {leg.CC === true ? `CC: Yes, ` : null}
                    {leg.fuel === true ? `Fuel: Yes, ` : null}
                    {leg.airlifts.length !== 0 ? `Airlifts: ` : ``}
                    {leg.airlifts.map((lift, ind) => {
                      return `${lift.airliftName}  ${
                        ind !== leg.airlifts.length - 1 ? ' // ' : ''
                      } `;
                    })}
                  </li>
                );
              })}
            </ol>
          </Typography>
          <Typography variant='h6'>
            {props
              ? `Mission Notes: ${
                  props.missionNotes === null ? 'None' : props.missionNotes
                }`
              : null}
            <br />
            {props ? `Mod Notes: ${props.modNotes}` : null}{' '}
          </Typography>
        </Paper>
      </Collapse>
    );
  }

  return null;
}
