import Grid from '@material-ui/core/Grid';
import React, { useContext } from 'react';
import '../App.css';
import { MissionInWork } from '../MissionGeneratorStore';
import { AirliftTileOnRow } from './AirliftTileOnRow';

export default function AirliftDrop({ props }) {
  const [missionInWork] = useContext(MissionInWork);

  return (
    <>
      <Grid item>
        <h4>Drop Airlifts Here</h4>
      </Grid>
      {missionInWork[props.rowNumber]?.airlifts?.map((lift, index) => {
        return (
          <Grid item key={index}>
            <AirliftTileOnRow props={lift} data={props} key={index} />
          </Grid>
        );
      })}
    </>
  );
}
