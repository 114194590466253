import Button from '@material-ui/core/Button';
import React from 'react';
import { SimpleMap } from './googlemaps';
import ModalWrapper from './ModalWrapper';

export default function MapModal({ routeData, buttonTitle }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const title = `${routeData.pod}-${routeData.poa}, Route ID: ${routeData.id}, Validation
        Date: ${routeData.valdate}`;

  return (
    <div>
      <Button variant='contained' color='primary' href='' onClick={handleOpen}>
        {buttonTitle}
      </Button>
      <ModalWrapper title={title} open={open} setOpen={setOpen}>
        <SimpleMap data={routeData} />
      </ModalWrapper>
    </div>
  );
}
