//Left off here.  Working new Itinerary Class.  new States are not working correctly Line ~322

//Crew Day counter set here (540 mins)
import { Chip, Tooltip, Typography } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import '../App.css';
const { DateTime } = require('luxon');

export const JALISRow = ({
  index,
  JALIS,
  JALIS_output,
  route_in_work,
  mission,
  podTimeZone,
  poaTimeZone,
  legTime,
}) => {
  const month = JALIS_output?.startDate.toFormat('MMM');
  const dayTO = JALIS_output.itinerary[index]?.toTimeLocal.substr(0, 2);
  const dayLand = JALIS_output.itinerary[index]?.landTimeLocal.substr(0, 2);

  function changeTime(timeInput, zoneInput) {
    const timeZulu = DateTime.fromISO(timeInput, { zone: 'UTC' });
    const timeLocal = timeZulu.setZone(zoneInput);
    return timeLocal;
  }

  return (
    <TableRow
      key={index + 'JRrow'}
      style={{ backgroundColor: 'lightgoldenrodyellow' }}>
      <TableCell></TableCell>
      {JALIS === true
        ? JALIS_output.startDate && (
            <TableCell
              style={{
                backgroundColor:
                  dayTO + ' ' + month !==
                  DateTime.fromISO(
                    mission.takeoffTimes.split(' ')[index].slice(0, 23)
                  ).toFormat('dd MMM')
                    ? 'orangered'
                    : '',
              }}>
              {dayTO} {month}
            </TableCell>
          )
        : null}
      {JALIS === true
        ? JALIS_output.itinerary[index] && (
            <TableCell
              style={{
                backgroundColor:
                  JALIS_output.itinerary[index]?.pod !== route_in_work.pod
                    ? 'orangered'
                    : '',
              }}>
              {JALIS_output.itinerary[index]?.pod}
            </TableCell>
          )
        : null}
      {JALIS === true
        ? JALIS_output.itinerary[index] && (
            <TableCell
              style={{
                backgroundColor:
                  JALIS_output.itinerary[index]?.toTimeLocal.substr(2, 4) !==
                  changeTime(
                    mission.takeoffTimes.split(' ')[index].slice(0, 23),
                    podTimeZone.databaseTimezone
                  ).toFormat('HHmm')
                    ? 'orangered'
                    : '',
              }}>
              {JALIS_output.itinerary[index]?.toTimeLocal.substr(2, 4)}
            </TableCell>
          )
        : null}
      {JALIS === true
        ? JALIS_output.itinerary[index] && (
            <TableCell
              style={{
                backgroundColor:
                  JALIS_output.itinerary[index]?.toTimeZulu.substr(2, 4) !==
                  DateTime.fromISO(
                    mission.takeoffTimes.split(' ')[index].slice(0, 23)
                  ).toFormat('HHmm')
                    ? 'orangered'
                    : '',
              }}>
              {JALIS_output.itinerary[index]?.toTimeZulu.substr(2, 4)}
            </TableCell>
          )
        : null}
      {JALIS === true
        ? JALIS_output.itinerary[index] && (
            <TableCell
              style={{
                backgroundColor:
                  JALIS_output.itinerary[index]?.poa !== route_in_work.poa
                    ? 'orangered'
                    : '',
              }}>
              {JALIS_output.itinerary[index]?.poa}
            </TableCell>
          )
        : null}
      <Hidden lgDown>
        {JALIS === true
          ? JALIS_output.startDate && (
              <TableCell
                style={{
                  backgroundColor:
                    dayLand + ' ' + month !==
                    DateTime.fromISO(
                      mission.takeoffTimes.split(' ')[index].slice(0, 23)
                    ).toFormat('dd MMM')
                      ? 'orangered'
                      : '',
                }}>
                {dayLand} {month}
              </TableCell>
            )
          : null}
      </Hidden>
      {JALIS === true
        ? JALIS_output.itinerary[index] && (
            <TableCell
              style={{
                backgroundColor:
                  JALIS_output.itinerary[index]?.landTimeLocal.substr(2, 4) !==
                  changeTime(
                    mission.landTimes.split(' ')[index].slice(0, 23),
                    poaTimeZone.databaseTimezone
                  ).toFormat('HHmm')
                    ? 'orangered'
                    : '',
              }}>
              {JALIS_output.itinerary[index]?.landTimeLocal.substr(2, 4)}
            </TableCell>
          )
        : null}
      {JALIS === true
        ? JALIS_output.itinerary[index] && (
            <TableCell
              style={{
                backgroundColor:
                  JALIS_output.itinerary[index]?.landTimeZulu.substr(2, 4) !==
                  DateTime.fromISO(
                    mission.landTimes.split(' ')[index].slice(0, 23)
                  ).toFormat('HHmm')
                    ? 'orangered'
                    : '',
              }}>
              {JALIS_output.itinerary[index]?.landTimeZulu.substr(2, 4)}
            </TableCell>
          )
        : null}
      <TableCell
        align='center'
        style={{
          backgroundColor:
            DateTime.fromISO(
              `${JALIS_output?.startDate.toISODate()}T${JALIS_output.itinerary[
                index
              ]?.landTimeZulu.substr(2, 4)}`
            )
              .diff(
                DateTime.fromISO(
                  `${JALIS_output?.startDate.toISODate()}T${JALIS_output.itinerary[
                    index
                  ]?.toTimeZulu.substr(2, 4)}`
                ),
                'minutes'
              )
              .toFormat('hh+mm') !== legTime.toFormat('hh+mm')
              ? 'orangered'
              : '',
        }}>
        {DateTime.fromISO(
          `${JALIS_output?.startDate.toISODate()}T${JALIS_output.itinerary[
            index
          ]?.landTimeZulu.substr(2, 4)}`
        )
          .diff(
            DateTime.fromISO(
              `${JALIS_output?.startDate.toISODate()}T${JALIS_output.itinerary[
                index
              ]?.toTimeZulu.substr(2, 4)}`
            ),
            'minutes'
          )
          .toFormat('hh+mm')}
      </TableCell>
      <Hidden lgDown>
        <TableCell align='center'>RON</TableCell>
      </Hidden>
      <TableCell align='center' key={'Crew Day'}></TableCell>
      <TableCell align='center' key={'On deck time'}></TableCell>
      <TableCell align='center' key={'Route ID'}></TableCell>
      <TableCell key={'Skyvector'}></TableCell>
      <TableCell align='center' key={'googleMaps'}></TableCell>
      {JALIS === true
        ? JALIS_output.itinerary[index] && (
            <AirliftCell JALIS_output={JALIS_output} index={index} />
          )
        : null}
      <Hidden lgDown>
        <TableCell align='center'></TableCell>
      </Hidden>
      <Hidden mdDown>
        <TableCell align='center'></TableCell>
      </Hidden>
      <TableCell align='center'></TableCell>
      <Hidden mdDown>
        <TableCell align='center'></TableCell>
      </Hidden>
    </TableRow>
  );
};

const AirliftCell = ({ JALIS_output, index }) => {
  if (JALIS_output.itinerary[index].airlifts === '') {
    return null;
  }

  const airliftsByLetter_array = JALIS_output.itinerary[index].airlifts
    .trim()
    .split('');

  return airliftsByLetter_array.map((lift, ind) => {
    const info = JALIS_output.airlifts.find(
      (liftByCode) => liftByCode.code === lift
    );

    if (!info) {
      return (
        <React.Fragment key={ind + 'liftJALIS'}>
          <TableCell align='center'>
            <LightTooltip
              title={
                <React.Fragment>
                  <Typography
                    variant='h5'
                    color='initial'
                    style={{ display: 'inline-block' }}>
                    No info found
                  </Typography>
                </React.Fragment>
              }>
              <Chip color='primary' style={{ margin: '2px' }} label={lift} />
            </LightTooltip>
          </TableCell>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment key={ind + 'liftJALIS'}>
        <TableCell align='center'>
          <LightTooltip
            title={
              <React.Fragment>
                <Typography
                  variant='h5'
                  color='initial'
                  style={{ display: 'inline-block' }}>
                  {info?.pod}-{info?.poa}
                  {<br />}
                  {info?.pax} / {info?.cargo}
                  {<br />}
                  {info?.PUJC}
                </Typography>
              </React.Fragment>
            }>
            <Chip
              color={info?.PUJC.substr(5, 1) === '1' ? 'red' : 'primary'}
              style={{ margin: '2px' }}
              label={lift}
            />
          </LightTooltip>
        </TableCell>
      </React.Fragment>
    );
  });

  /*     return (<TableCell align='center'>
      <LightTooltip
        title={
          <React.Fragment>
            <Typography
              variant='h5'
              color='initial'
              style={{ display: 'inline-block' }}>
              {JALIS_output.itinerary[index]?.airlifts?.pod}-
              {JALIS_output.itinerary[index]?.airlifts?.poa}
              {<br />}
              {JALIS_output.itinerary[index]?.airlifts?.pax} /{' '}
              {JALIS_output.itinerary[index]?.airlifts?.cargo}
              {<br />}
              {JALIS_output.itinerary[index]?.airlifts?.PUJC}
            </Typography>
          </React.Fragment>
        }>
        <Chip
          color='primary'
          style={{ margin: '2px' }}
          label={JALIS_output.itinerary[index]?.airlifts}
        />
      </LightTooltip>
    </TableCell>
  ); */
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);
