import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import he from 'he';
import React from 'react';
import './App.css';

export default function GougeReports({ reports }) {
  const paperStyle = {
    padding: '15px',
    backgroundColor: '#f9fbf0',
  };
  const divStyle = {
    padding: '30px',
  };

  /*   function decodeHTML(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  } */
  return (
    <>
      <h2 style={{ textAlign: 'center', marginTop: '20px' }}>
        Airfield Notes / Gouge{' '}
      </h2>
      {reports.length === 0 ? (
        <div key={0 + 'ee'} style={{ textAlign: 'center' }}>
          <h3>No gouge reports submitted for airfields on this itinerary</h3>
        </div>
      ) : (
        reports.map((element, index) => {
          return (
            <div style={divStyle} key={element.id + 'div'}>
              <Paper style={paperStyle} key={element.id + 'paper'}>
                <Typography variant='h5' component='h3'>
                  {element.ICAO}
                </Typography>
                <Typography component='p'>
                  {he.decode(he.decode(element.body))}
                </Typography>
                <Typography component='p'>
                  Date of submission {element.dateOfSubmission}
                </Typography>
                <Typography component='p'>
                  Type of Aircraft {element.acType}
                </Typography>
              </Paper>
            </div>
          );
        })
      )}
    </>
  );
}
