export function addAirlifts(i, newMissionArray, missionDataToImport) {
  let airliftData = [];

  if (missionDataToImport.legs[i].airlifts) {
    missionDataToImport.legs[i].airlifts.forEach((airlift) => {
      airliftData.push(airlift);
    });
  }

  return airliftData;
}
