import { Button } from '@material-ui/core';
import React, { useContext } from 'react';
import { MissionContext } from '../store';

const { DateTime } = require('luxon');

export function MissionLink(prop) {
  const { setMissionObj } = useContext(MissionContext);
  //Button for mission callsign and link to mission detail
  const fullname = prop.data.cnv.split('-');
  return (
    <Button
      variant='contained'
      color='primary'
      onClick={() => {
        setMissionObj({ ...prop.data });
      }}>
      CNV {fullname[0]}, Mod {prop.data.modNumber}
    </Button>
  );
}

export function PrintDate(prop) {
  //const missionDate = prop.data.firstTOlocal;
  if (prop.data.firstTOlocal !== '' && prop.data.firstTOlocal !== null) {
    let firstTry = DateTime.fromISO(prop.data.firstTOlocal).toFormat(
      'dd LLL yyyy / hhmm'
    );
    //firstTry = firstTry.toLocaleString();
    return firstTry;
  } else {
    return 'no date';
  }
}

export function DaysTill(prop) {
  const startDate = DateTime.fromISO(
    DateTime.fromISO(prop.data.firstTOlocal).toISODate()
  );
  const todayDate = DateTime.fromISO(DateTime.local().toISODate());

  return startDate.diff(todayDate, 'days').toFormat('dd');
}
