import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import he from 'he';
import React from 'react';

export const OffLoadEquipment = ({
  mission,
  changed,
  handleChange,
  pprInfo,
}) => {
  //offloadType

  if (mission.typeAircraft === 'c26routes') {
    return null;
  }
  if (mission.typeAircraft === 'c40routes') {
    return (
      <>
        <FormControl>
          <InputLabel htmlFor='offload'>Offload Equip</InputLabel>
          <Select
            style={
              changed === true
                ? { backgroundColor: 'red' }
                : { backgroundColor: '' }
            }
            value={he.decode(pprInfo.offloadType || '')}
            onChange={handleChange('offloadType')}
            inputProps={{
              name: 'offload',
              id: 'offload',
            }}>
            <MenuItem value='none'>
              <em>None</em>
            </MenuItem>
            <MenuItem value='nr'>Not required</MenuItem>
            <MenuItem value='kloader'>K-Loader/High Loader</MenuItem>
            <MenuItem value='forklift'>Forklift</MenuItem>
          </Select>
          <FormHelperText>Choose type of offload equip</FormHelperText>
        </FormControl>
      </>
    );
  }
  if (mission.typeAircraft === 'c130routes') {
    return (
      <>
        <FormControl>
          <InputLabel htmlFor='offload'>Offload Equip</InputLabel>
          <Select
            style={
              changed === true
                ? { backgroundColor: 'red' }
                : { backgroundColor: '' }
            }
            value={he.decode(pprInfo.offloadType || '')}
            onChange={handleChange('offloadType')}
            inputProps={{
              name: 'offload',
              id: 'offload',
            }}>
            <MenuItem value='none'>
              <em>None</em>
            </MenuItem>
            <MenuItem value='nr'>Not required</MenuItem>
            <MenuItem value='kloader'>K-Loader/High Loader</MenuItem>
            <MenuItem value='forklift'>Forklift</MenuItem>
          </Select>
          <FormHelperText>Choose type of offload equip</FormHelperText>
        </FormControl>
      </>
    );
  } else {
    return null;
  }
};
