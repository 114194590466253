import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useEffect, useState } from 'react';
import '../App.css';
import { ItineraryTableRow } from './ItineraryTableRow';
const { DateTime, Duration } = require('luxon');

export const ItineraryTableData = ({
  mission,
  routes,
  airports,
  JALIS_output,
}) => {
  const mission_legs = mission.routeID.split(' ');
  var crewDay = Duration.fromObject({ minutes: 120 });

  function changeTime(timeInput, zoneInput) {
    const timeZulu = DateTime.fromISO(timeInput, { zone: 'UTC' });
    const timeLocal = timeZulu.setZone(zoneInput);
    return timeLocal;
  }

  const [airliftsWithHover, setairliftsWithHover] = useState([]);

  useEffect(() => {
    if (mission && mission.fullMissionDataObject) {
      let alWithHover = [];
      //debugger;
      mission.fullMissionDataObject.forEach((leg, ind) => {
        if (leg.airlifts) {
          alWithHover.push([...leg.airlifts]);
        } else {
          alWithHover.push([]);
        }
      });
      setairliftsWithHover(alWithHover);
    }
  }, [mission.fullMissionDataObject, mission]);

  return (
    <>
      {mission_legs.map((mission_leg_id, index) => {
        const route_in_work = routes.find((elem) => {
          return elem.id === mission_leg_id;
        });
        //TODO
        if (route_in_work) {
          const podTimeZone = airports.find((elem) => {
            return elem.ICAO === route_in_work.pod;
          });
          const poaTimeZone = airports.find((elem) => {
            return elem.ICAO === route_in_work.poa;
          });
          let timePODlocal = changeTime(
            mission.takeoffTimes.split(' ')[index],
            podTimeZone.databaseTimezone
          );
          let timePOAlocal = changeTime(
            mission.landTimes.split(' ')[index],
            poaTimeZone.databaseTimezone
          );
          const legTime = timePOAlocal.diff(timePODlocal, ['hours', 'minutes']);

          if (mission.fullMissionDataObject) {
            if (
              mission?.fullMissionDataObject[index - 1]?.RON ||
              mission?.fullMissionDataObject[index - 1]?.crewChange
            ) {
              crewDay = Duration.fromObject({ minutes: 120 });
              crewDay = crewDay.plus(timePOAlocal.diff(timePODlocal));
            } else if (index !== 0) {
              crewDay = crewDay.plus(timePOAlocal.diff(timePODlocal)).plus(
                Duration.fromObject({
                  minutes:
                    mission.fullMissionDataObject[index - 1].turnTime.minutes,
                })
              );
            } else {
              crewDay = crewDay.plus(timePOAlocal.diff(timePODlocal));
            }

            var HAZonLeg = false;
            if (mission.fullMissionDataObject[index].airlifts) {
              mission.fullMissionDataObject[index].airlifts.forEach(
                (lift, ind) => {
                  if (lift.HAZ === '1' || lift.HAZ === 1) {
                    HAZonLeg = true;
                  }
                }
              );
            }
          } else {
            crewDay = Duration.fromObject({ minutes: 0 });
          }

          return (
            <ItineraryTableRow
              key={'itineraryTableRow' + index}
              crewDay={crewDay}
              HAZonLeg={HAZonLeg}
              index={index}
              JALIS_output={JALIS_output}
              legTime={legTime}
              mission_legs={mission_legs}
              mission={mission}
              poaTimeZone={poaTimeZone}
              podTimeZone={podTimeZone}
              route_in_work={route_in_work}
              airliftsWithHover={airliftsWithHover}
              setairliftsWithHover={setairliftsWithHover}
            />
          );
        } else {
          alert(
            'Route not found while building itinerary.  This is likely due to the route no longer existing in the database.  Check the listing of routes to confirm that all routes used on this mission still exist.'
          );

          return (
            <TableRow key={index + 'row'} style={{ backgroundColor: 'red' }}>
              <TableCell align='center' colSpan={16}>
                Problem Route ID:{mission_leg_id}
              </TableCell>
            </TableRow>
          );
        }
      })}
    </>
  );
};
