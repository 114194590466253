import { Grid } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React, { useContext } from 'react';
import '../App.css';
import { MissionInWork } from '../MissionGeneratorStore';

const { DateTime, Duration } = require('luxon');

export const TimeUp = ({ props }) => {
  const [, setmissionInWork] = useContext(MissionInWork);

  const increaseTimes = (e) => {
    setmissionInWork((prevState) => {
      const newState = [...prevState];

      newState[props.rowNumber].toTimeZulu = DateTime.fromISO(
        prevState[props.rowNumber].toTimeZulu,
        {
          zone: 'UTC',
        }
      )
        .plus(
          Duration.fromObject({
            hours: 1,
          })
        )
        .toUTC()
        .toISO({ includeOffset: false });

      newState[props.rowNumber].toTime = DateTime.fromISO(
        newState[props.rowNumber].toTimeZulu,
        { zone: 'UTC' }
      )
        .setZone(newState[props.rowNumber].podTimeZone)
        .toISO({ includeOffset: false });

      newState[props.rowNumber].landTimeZulu = DateTime.fromISO(
        prevState[props.rowNumber].landTimeZulu,
        {
          zone: 'UTC',
        }
      )
        .plus(
          Duration.fromObject({
            hours: 1,
          })
        )
        .toUTC()
        .toISO({ includeOffset: false });

      //Set zulu land time of this row
      newState[props.rowNumber].landTime = DateTime.fromISO(
        newState[props.rowNumber].landTimeZulu,
        { zone: 'UTC' }
      )
        .setZone(newState[props.rowNumber].poaTimeZone)
        .toISO({ includeOffset: false });

      //Adjust times in the rows before
      for (let i = props.rowNumber - 1; i >= 0; i--) {
        //Set zulu land time row before
        newState[i].landTimeZulu = DateTime.fromISO(
          newState[i + 1].toTimeZulu,
          {
            zone: 'UTC',
          }
        )
          .minus(
            Duration.fromObject({
              minutes: newState[i].turnTime.minutes,
            })
          )
          .toUTC()
          .toISO({ includeOffset: false });

        //Set zulu takeoff time row before
        newState[i].toTimeZulu = DateTime.fromISO(newState[i].landTimeZulu, {
          zone: 'UTC',
        })
          .minus(
            Duration.fromObject({
              minutes: newState[i].roundedEnrouteTime,
            })
          )
          .toUTC()
          .toISO({ includeOffset: false });

        //Set local landtime in row before
        newState[i].landTime = DateTime.fromISO(newState[i].landTimeZulu, {
          zone: 'UTC',
        })
          .setZone(newState[i].poaTimeZone)
          .toISO({ includeOffset: false });

        //Set local takeoff time row before
        newState[i].toTime = DateTime.fromISO(newState[i].toTimeZulu, {
          zone: 'UTC',
        })
          .setZone(newState[i].podTimeZone)
          .toISO({ includeOffset: false });
      }

      for (let i = Number(props.rowNumber) + 1; i < newState.length; i++) {
        //Set new zulu takeoff time row after
        newState[i].toTimeZulu = DateTime.fromISO(
          newState[i - 1].landTimeZulu,
          {
            zone: 'UTC',
          }
        )
          .plus(
            Duration.fromObject({
              minutes: newState[i - 1].turnTime.minutes,
            })
          )
          .toUTC()
          .toISO({ includeOffset: false });

        //Set zulu land time row after
        newState[i].landTimeZulu = DateTime.fromISO(newState[i].toTimeZulu, {
          zone: 'UTC',
        })
          .plus(
            Duration.fromObject({
              minutes: newState[i].roundedEnrouteTime,
            })
          )
          .toUTC()
          .toISO({ includeOffset: false });

        //Set new local takeoff time row after
        newState[i].toTime = DateTime.fromISO(newState[i].toTimeZulu, {
          zone: 'UTC',
        })
          .setZone(newState[i].podTimeZone)
          .toISO({ includeOffset: false });

        //Set local land time row after
        newState[i].landTime = DateTime.fromISO(newState[i].landTimeZulu, {
          zone: 'UTC',
        })
          .setZone(newState[i].poaTimeZone)
          .toISO({ includeOffset: false });
      }

      return [...newState];
    });
  };

  return (
    <Grid container direction='row' justify='center' alignItems='center'>
      <Grid item>
        <ArrowUpwardIcon
          onClick={(e) => {
            e.preventDefault();
            increaseTimes(e);
          }}
        />
      </Grid>
    </Grid>
  );
};
