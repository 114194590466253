//Left off here.  Working new Itinerary Class.  new States are not working correctly Line ~322

//Crew Day counter set here (540 mins)
import React, { useState } from 'react';
import ErrorBoundary from 'react-error-boundary';
import '../App.css';
import MyFallbackComponent from '../MyFallbackComponent';
import { CrewRest } from './CrewRest';
import { ItineraryTable } from './ItineraryTable';
import { JALISCheck } from './JALIS/JALISCheck';

export default function Itinerary(props) {
  const [JALIS_output, setJALIS_output] = useState({});

  return (
    <>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <CrewRest {...props} />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <ItineraryTable JALIS_output={JALIS_output} {...props} />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={MyFallbackComponent}>
        <JALISCheck
          {...props}
          JALIS_output={JALIS_output}
          setJALIS_output={setJALIS_output}
        />
      </ErrorBoundary>
    </>
  );
}
