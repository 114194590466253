import { useQuery } from 'react-query';

const getPPRData = async () => {
  const data = await fetch('/db/chunkPR.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
  });
  return data.json();
};

export default function usePPRData() {
  return useQuery(['PPRdata'], getPPRData, {
    staleTime: 5 * 60 * 1000,
    placeholderData: [],
  });
}
