import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@material-ui/core/Chip';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

export default function PPRFileChip({ props }) {
  const [output, setoutput] = useState(undefined);
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(['filesPPR']);

  function openFileLink(e, link) {
    window.open(link, '_blank noopener noreferrer');
  }

  useEffect(() => {
    if (data !== undefined) {
      let out = data.find((dir_obj) => {
        return dir_obj.folder === props;
      });
      setoutput(out);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (output !== undefined && output.files[0] !== undefined) {
    return output.files.map((file, i) => {
      let address_string = `https://europegouge.com/db/uploads/${output.folder}/${file}`;
      return (
        <React.Fragment key={i + 'ChipDiv'}>
          <Chip
            key={i + 'file'}
            className='mb-1'
            icon={<FontAwesomeIcon icon={faFilePdf} />}
            label={file}
            onClick={(e) => {
              // console.log(address_string);
              openFileLink(e, address_string);
            }}
          />
          <br />
        </React.Fragment>
      );
    });
  } else {
    return 'No files uploaded';
  }
}
