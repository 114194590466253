import { useQuery } from 'react-query';

const getMissionDetail = async (routeIDs, ACtype) => {
  const data = await fetch('/db/chunkMDA.php', {
    method: 'POST',
    withCredentials: true,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: localStorage.getItem('token'),
    },
    body: JSON.stringify({ routeIDs: routeIDs, type: ACtype }),
  });

  if (!data.ok) {
    throw Error(data.statusText);
  } else {
    return data.json();
  }
};

export default function useMissionDetail(routeIDs, ACtype) {
  return useQuery(
    ['missionDetail', routeIDs, ACtype],
    () => getMissionDetail(routeIDs, ACtype),
    {
      staleTime: 3 * 60 * 1000,
    }
  );
}
