import { Hidden, Typography } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import he from 'he';
import React, { useEffect, useState } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import '../App.css';
import usePPRData from '../Utils/Hooks/usePPRdata';
import { ExportPPR } from './PPR/Components/Export/ExportPPR';
import PPRFileChip from './PPR/Components/PPRFileChip';
import { PPRHeader } from './PPR/Components/PPRHeader';
import { PPRInfoRow } from './PPR/Components/PPRInfoRow';
import { PPRNotes } from './PPR/Components/PPRNotes';
import { PPRStatus } from './PPR/Components/PPRStatus';
import './PPRTable.css';

export default function PPREntry({ mission, data, airliftArr, buno }) {
  const [valid, setValid] = useState(false);
  const [changed, setChanged] = useState(false);

  //const { data: airliftInformation } = useAirlifts();
  const { data: pprInfoAll } = usePPRData();

  //Phone test is only required in transition to new telephone format system
  const [phoneTest, setphoneTest] = useState('');

  const [pprInfo, setpprInfo] = useState({
    pprID: data.pprID,
    pprNumber: '',
    pprServices: '',
    pprAgent: '',
    pprContact: '',
    parkingLocation: '',
    offloadType: 'none',
    fuel: 'yes',
    notes: '',
    pprChangedBy: '',
    pprHistory: [],
    pprICAO: data.airfieldICAO,
    pprStatus: '',
    pprRequired: data.last === true ? false : true,
  });

  useEffect(() => {
    //Check to see if there is existing PPR Entry in database, if so, copy into pprInfo
    if (pprInfoAll) {
      let filterPPR = pprInfoAll.find((element) => {
        return element.pprID === data.pprID;
      });

      //The following checks for old style PPR (without) ITS update code, so CNV 6512-01-1-ETAR-1 vice CNV 6512-01-1-2-ETAR-1
      if (filterPPR === undefined) {
        filterPPR = pprInfoAll.find((element) => {
          return element.pprID === data.pprIDold;
        });
      }

      if (filterPPR !== undefined) {
        setphoneTest(filterPPR.pprContact);
        setpprInfo((pprInfo) => {
          return {
            ...pprInfo,
            pprNumber: filterPPR.pprNumber,
            pprServices: filterPPR.pprServices,
            pprAgent: filterPPR.pprAgent,
            pprContact: filterPPR.pprContact,
            parkingLocation: filterPPR.parkingLocation,
            offloadType: filterPPR.offloadType,
            notes: filterPPR.notes,
            pprStatus: filterPPR.pprStatus,
            //pprHistory: history_array,
            pprICAO: filterPPR.pprICAO,
            pprChangedBy: filterPPR.pprChangedBy,
            pprRequired:
              filterPPR.pprRequired === 'true' || filterPPR.pprRequired === true
                ? true
                : false,
          };
        });

        if (
          filterPPR.pprNumber &&
          filterPPR.pprServices &&
          filterPPR.pprAgent &&
          filterPPR.pprContact &&
          filterPPR.offloadType !== 'none' &&
          mission.typeAircraft === 'c40routes'
        ) {
          setValid(true);
        } else if (
          filterPPR.pprNumber &&
          filterPPR.pprServices &&
          filterPPR.pprAgent &&
          filterPPR.pprContact &&
          filterPPR.offloadType !== 'none' &&
          mission.typeAircraft === 'c130routes'
        ) {
          setValid(true);
        } else if (
          filterPPR.pprNumber &&
          filterPPR.pprServices &&
          filterPPR.pprAgent &&
          filterPPR.pprContact &&
          mission.typeAircraft === 'c26routes'
        ) {
          setValid(true);
        } else {
          setValid(false);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pprInfoAll]);

  /*  async function sendValues(e, inputs) {
    //This only updates the ppr info, not status or history
    const dataUpdate = {
      ...inputs,
    };
    await fetch('/db/updatePPRinfo.schedule.db.php', {
      method: 'POST',
      withCredentials: true,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('token'),
      },
      body: JSON.stringify(dataUpdate),
    })
      .then(handleErrors)
      .then((response) => {
        if (response.status === 200) {
          setChanged(false);
        } else {
          alert('Whoops, problem with saving. ');
        }
        return response.json();
      })
      .then((data) => {
        if (
          pprInfo.pprNumber &&
          pprInfo.pprServices &&
          pprInfo.pprAgent &&
          pprInfo.pprContact &&
          pprInfo.offloadType !== 'none' &&
          mission.typeAircraft === 'c40routes'
        ) {
          setValid(true);
        } else if (
          pprInfo.pprNumber &&
          pprInfo.pprServices &&
          pprInfo.pprAgent &&
          pprInfo.pprContact &&
          pprInfo.offloadType !== 'none' &&
          mission.typeAircraft === 'c130routes'
        ) {
          setValid(true);
        } else if (
          pprInfo.pprNumber &&
          pprInfo.pprServices &&
          pprInfo.pprAgent &&
          pprInfo.pprContact &&
          mission.typeAircraft === 'c26routes'
        ) {
          setValid(true);
        } else {
          setValid(false);
        }
      });
  } */

  const handleChange = (name) => (event) => {
    setpprInfo({
      ...pprInfo,
      [name]: he.encode(event.target.value ?? ''),
    });
    setChanged(true);
  };

  const handleChangeTelephone = (name) => (event) => {
    setpprInfo({
      ...pprInfo,
      [name]: he.encode(formatPhoneNumberIntl(event) ?? ''),
    });
    setChanged(true);
  };

  return (
    <>
      <TableRow
        style={{
          backgroundColor:
            pprInfo.pprRequired === false
              ? 'darkgray'
              : pprInfo.pprStatus === 'approved'
              ? 'lightgreen'
              : pprInfo.pprStatus === 'pendingApproval'
              ? '#ffc107'
              : 'lightgrey',
        }}>
        <PPRHeader
          data={data}
          pprInfo={pprInfo}
          setChanged={setChanged}
          setpprInfo={setpprInfo}
          airliftArr={airliftArr}
          buno={buno}
        />
        <Hidden lgDown>
          <TableCell colSpan={2}>
            <PPRStatus
              data={data}
              pprInfo={pprInfo}
              setpprInfo={setpprInfo}
              valid={valid}
            />
          </TableCell>
        </Hidden>
      </TableRow>
      <TableRow
        style={{
          backgroundColor:
            pprInfo.pprRequired === false
              ? 'darkgray'
              : pprInfo.pprStatus === 'approved'
              ? 'lightgreen'
              : pprInfo.pprStatus === 'pendingApproval'
              ? '#ffc107'
              : 'lightgrey',
        }}>
        <PPRInfoRow
          changed={changed}
          handleChange={handleChange}
          handleChangeTelephone={handleChangeTelephone}
          mission={mission}
          pprInfo={pprInfo}
          phoneVerification={phoneTest}
          setChanged={setChanged}
          setpprInfo={setpprInfo}
          setValid={setValid}
        />
        <Hidden lgDown>
          <TableCell>
            <PPRNotes
              changed={changed}
              handleChange={handleChange}
              pprInfo={pprInfo}
            />
          </TableCell>
        </Hidden>
        <Hidden lgDown>
          <TableCell>
            <h4>Linked Files</h4>
            <PPRFileChip props={data.pprID} />
          </TableCell>
        </Hidden>
      </TableRow>
      <Hidden xlUp>
        <TableRow
          style={{
            backgroundColor:
              pprInfo.pprRequired === false
                ? 'darkgray'
                : pprInfo.pprStatus === 'approved'
                ? 'lightgreen'
                : pprInfo.pprStatus === 'pendingApproval'
                ? '#ffc107'
                : 'lightgrey',
          }}>
          <TableCell colSpan={2}>
            <PPRStatus
              data={data}
              pprInfo={pprInfo}
              setpprInfo={setpprInfo}
              valid={valid}
            />
          </TableCell>
          <TableCell colSpan={2}>
            <PPRNotes
              changed={changed}
              handleChange={handleChange}
              pprInfo={pprInfo}
            />
          </TableCell>
          <TableCell colSpan={1}>
            <Typography variant='h6' color='initial'>
              Linked Files
            </Typography>
            <PPRFileChip props={data.pprID} />
          </TableCell>
          <TableCell colSpan={1}>
            {data.airfieldICAO === 'LGSA' ? (
              <ExportPPR data={data} airliftArr={airliftArr} buno={buno} />
            ) : null}
          </TableCell>
        </TableRow>
      </Hidden>
    </>
  );
}
