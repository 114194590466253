import { Hidden, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import he from 'he';
import React from 'react';
import '../App.css';

export default function ContactEntry({ airliftData }) {
  const small = useMediaQuery('(max-width:800px)');
  const extraSmall = useMediaQuery('(max-width:500px)');

  if (extraSmall === true) {
    return <ContactTableVertical airliftData={airliftData} />;
  }

  return <ContactTableHoriz airliftData={airliftData} small={small} />;
}

const ContactTableHoriz = ({ airliftData, small }) => {
  const tableStyleSmall = {
    marginTop: '2px',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'lightyellow',
    outlineStyle: 'auto',
  };

  const tableStyleLarge = {
    marginTop: '10px',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'lightyellow',
    outlineStyle: 'auto',
  };
  return (
    <Table
      style={small === true ? tableStyleSmall : tableStyleLarge}
      size={small === true ? 'small' : 'medium'}>
      <TableHead>
        <TableRow>
          <TableCell align='center'></TableCell>
          <Hidden smDown>
            <TableCell align='center'>Airlift Name</TableCell>
          </Hidden>
          <TableCell align='center'>POD</TableCell>
          <TableCell align='center'>POA</TableCell>
          <TableCell align='center'>Contact Name</TableCell>
          <Hidden smDown>
            <TableCell align='center'>Contact Phone</TableCell>
          </Hidden>
          <TableCell align='center'>PAX</TableCell>
          <TableCell align='center'>Cargo</TableCell>
          <TableCell align='center'>Notes</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align='center'>Original Request</TableCell>
          <Hidden smDown>
            <TableCell align='center'>{airliftData.airliftName}</TableCell>
          </Hidden>
          <TableCell align='center'>{airliftData.POD}</TableCell>
          <TableCell align='center'>{airliftData.POA}</TableCell>
          <TableCell align='center'>
            {he.unescape(he.decode(airliftData.liftRequestor || ''))}
          </TableCell>
          <Hidden smDown>
            <TableCell align='center'>
              {he.unescape(he.decode(airliftData.requestorPhone || ''))}
            </TableCell>
          </Hidden>
          <TableCell align='center'>
            {he.unescape(he.decode(airliftData.PAX || ''))}
          </TableCell>
          <TableCell align='center'>
            {he.unescape(he.decode(airliftData.cargoWeight || ''))}
          </TableCell>
          <TableCell align='center'>
            {he.unescape(he.decode(airliftData.notes || ''))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={1} align='center'>
            Verified Data
          </TableCell>
          <Hidden smDown>
            <TableCell colSpan={1} align='center'></TableCell>
          </Hidden>
          <TableCell align='center'>{airliftData.POD}</TableCell>
          <TableCell align='center'>{airliftData.POA}</TableCell>
          <TableCell align='center'>
            {airliftData.vPOC === null || airliftData.vPOC === ''
              ? he.unescape(he.decode(airliftData.liftRequestor || ''))
              : he.unescape(he.decode(airliftData.vPOC || ''))}
          </TableCell>
          <Hidden smDown>
            <TableCell align='center'>
              {he.unescape(he.decode(airliftData.vPhone || ''))}
            </TableCell>
          </Hidden>
          <TableCell align='center'>
            {he.unescape(he.decode(airliftData.vPAX || ''))}
          </TableCell>
          <TableCell align='center'>
            {he.unescape(he.decode(airliftData.vCargo || ''))}
          </TableCell>
          <TableCell align='center'>
            {airliftData.vNotes === null || airliftData.vNotes === ''
              ? 'None'
              : he.unescape(he.decode(airliftData.vNotes || ''))}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ContactTableVertical = ({ airliftData }) => {
  const tableStyleSmall = {
    marginTop: '2px',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'lightyellow',
    outlineStyle: 'auto',
  };

  const headStyle = {
    fontWeight: 'bold',
  };
  return (
    <Table style={tableStyleSmall} size='small'>
      <TableBody>
        <TableRow>
          <TableCell align='center'></TableCell>
          <TableCell align='center' style={headStyle}>
            Verified Data
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            POD
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>{airliftData.POD}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            POA
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>{airliftData.POA}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            Contact Name
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {airliftData.vPOC === null || airliftData.vPOC === ''
                ? he.unescape(he.decode(airliftData.liftRequestor || ''))
                : he.unescape(he.decode(airliftData.vPOC || ''))}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            Contact Phone
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {he.unescape(he.decode(airliftData.vPhone || ''))}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            PAX
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {he.unescape(he.decode(airliftData.vPAX || ''))}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            Cargo
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {he.unescape(he.decode(airliftData.vCargo || ''))}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align='center' style={headStyle}>
            Notes
          </TableCell>
          <TableCell align='center'>
            <Typography variant='body1'>
              {airliftData.vNotes === null || airliftData.vNotes === ''
                ? 'None'
                : he.unescape(he.decode(airliftData.vNotes || ''))}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
