import React from 'react';
import StandardLoadingFallback from '../../Loading/StandardLoadingFallback';
import useUICs from '../../Utils/Hooks/useUICs';
import TableWrapper from '../TableWrapper';

export default function UICTable() {
  const { data, isFetching } = useUICs();
  const databaseName = 'uic';
  const queryKey = ['UICsset'];
  const columns = [
    {
      Header: `UICs`,
      columns: [
        {
          Header: 'ID',
          accessor: 'id',
          edit: false,
          minWidth: '10vw',
          maxWidth: '11vw',
        },
        {
          Header: 'UIC',
          accessor: 'uic',
          edit: false,
          minWidth: '11vw',
          maxWidth: '12vw',
        },
        {
          Header: 'Command',
          accessor: 'command',
          minWidth: '60vw',
          maxWidth: '65vw',
        },
        {
          Header: 'Notes',
          accessor: 'notes',
        },
      ],
    },
  ];

  if (isFetching === true) {
    return <StandardLoadingFallback />;
  }

  const rowsNotToEdit = ['ID'];

  return (
    <TableWrapper
      data={data}
      numberOfRowsToDisplay={20}
      columnsArray={columns}
      queryKey={queryKey}
      pagination={true}
      databaseName={databaseName}
      rowsNotToEdit={rowsNotToEdit}
    />
  );
}
