import React from 'react';

//MissionContext used for mission database entry
export const MissionGeneratorHeader = React.createContext();
export const ValidatedRoutes = React.createContext();
export const ValidatedRouteSelected = React.createContext();
export const Airlifts = React.createContext();
export const MissionInWork = React.createContext();

//Boolean to store if all routes are current (within 30 days) in order to save mission to db
export const CurrentRoutes = React.createContext();

export const AirliftSelected = React.createContext();
