import Chip from '@material-ui/core/Chip';
import React from 'react';
import { useDrag } from 'react-dnd';
import AirliftWrapper from '../Utils/AirliftWrapper';
import './App.css';

export const AirliftChip = ({ props, setairliftSelected }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { props, type: 'airlift' },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <AirliftWrapper airlift={props}>
      <Chip
        className='mt-2'
        style={{
          opacity: isDragging ? 0.5 : 1,
          color: 'black',
          backgroundColor: 'beige',
          fontSize: '1rem',
          margin: '1px',
        }}
        ref={drag}
        color='primary'
        clickable
        data={props}
        onClick={(e) => {
          setairliftSelected(props);
        }}
        label={props.airliftName}
      />
    </AirliftWrapper>
  );
};
