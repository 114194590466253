import { Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

export default function RouteTooltip({ route, children }) {
  function pad2(number) {
    return (number < 10 ? '0' : '') + number;
  }
  return (
    <React.Fragment key={'LTT'}>
      <LightTooltip
        key={'lightTooltip'}
        title={
          <React.Fragment>
            <Typography
              variant='h5'
              color='initial'
              style={{ display: 'inline-block' }}>
              Time:{' '}
              {route.routeTime === undefined
                ? 'Time undefined'
                : Math.floor(route?.routeTime / 60) +
                  '+' +
                  pad2(route?.routeTime % 60)}
              {<br />}
              Val date: {route?.valdate?.split(' ')[0]}
              {<br />}
              FIRS: {route?.firs}
              {<br />}
              POD TZ: {route?.podTimeZone}
              {<br />}
              POA TZ: {route?.poaTimeZone}
              {<br />}
              Entered By: {route?.enteredBy}
            </Typography>
          </React.Fragment>
        }>
        {children}
      </LightTooltip>
    </React.Fragment>
  );
}
