import Chip from '@material-ui/core/Chip';
import React, { useContext, useEffect, useState } from 'react';
import { AirliftFilter } from '../../store';
import AirliftWrapper from '../../Utils/AirliftWrapper';
import '../App.css';
import { MissionInWork } from '../MissionGeneratorStore';

export const AirliftTileOnRow = ({ props, data }) => {
  const [missionInWork, setmissionInWork] = useContext(MissionInWork);
  const [, setairliftTextFilter] = useContext(AirliftFilter);
  const [specialColor, setspecialColor] = useState('lightBlue');

  useEffect(() => {
    if (props?.DV === '1') {
      setspecialColor('yellow');
    }
    if (props?.HAZ === '1') {
      setspecialColor('red');
    }
  }, [props.DV, props.HAZ]);

  return (
    <AirliftWrapper airlift={props}>
      <Chip
        className='mt-2'
        style={{ margin: '2px', backgroundColor: specialColor }}
        clickable
        data={props}
        onClick={(e) => {
          e.preventDefault();
          setairliftTextFilter(props.airliftName);
        }}
        onDelete={(e) => {
          e.preventDefault();
          const newMsn = [...missionInWork];
          let newArray = newMsn[data.rowNumber]?.airlifts.filter((lift) => {
            return lift.id !== props.id;
          });
          newMsn[data.rowNumber]['airlifts'] = newArray;
          setmissionInWork(newMsn);
        }}
        label={props.airliftName}
      />
    </AirliftWrapper>
  );
};
